import React from "react";
import { NavLink } from "react-router-dom";

export default function EmptyEnrollScreen() {
    return (
        <section>
            <div id="NoEnrolls">
                <div>
                    <div className="d-grid place-items-center"><img className="no-enrolls-img" src="assets/login-img-2.png" alt="No Videos" /></div>
                    <p>It looks like you haven’t enrolled in any courses yet. Explore our library to find courses that interest you.</p>
                    <div className="d-grid place-items-center">
                        <button>
                            <NavLink to={"/" + "videos"}>Browse Videos & Courses</NavLink>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}