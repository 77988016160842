import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {
  IconButton,
  List,
  ListItem,
  Tooltip,
} from "@mui/material";
import API from "../utils/API";
import { default as UserAPI } from "../../../utils/API";

import store from "../../../store/configureStore";
import { useSelector } from "react-redux";
import GenerateProfilePic from "../../../components/Common/GenerateProfilePictureIntitals";
import { useTranslation } from "react-i18next";
import { courseContentType, videoContentType } from "../../../components/Common/Constants";
import Spinner from "../../../components/Common/Spinner";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { NavLink } from "react-router-dom";



export default function AdminShareUser(props) {
  const selectSection = 'select'
  const selectedSection = 'selected'
  const [open, setOpen] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState([]);
  const [allUsers, setAllUsers] = React.useState([]);
  const [mockSelectedUser, setMockSelectedUser] = React.useState([]);
  const [searchVal, setSearchVal] = React.useState('');
  const [localLoader, setLocalLoader] = React.useState(false);
  const [localLoaderSuccess, setLocalLoaderSuccess] = React.useState(false);
  const [currentPath, setCurrentPath] = React.useState('');
  const [enabled, setEnabled] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    props.close()
  };

  const [copySuccess, setCopySuccess] = useState(false)

  const { t } = useTranslation()
  const userId = useSelector(() => store.getState().profileData.id);
  const settings = useSelector(() => store.getState().tenant.settings);

  const Share_Label = t("Share_Label")
  const Selected_Users = t("Selected_Users")
  const Individual_Tab = t("Individual_Tab")
  const No_Users_Selected = t("No_Users_Selected")
  const Cancel = t("Cancel")
  const Search_PlaceHolder_Label = t("Search_PlaceHolder_Label")
  const Copy_Shareable_Link = t("Copy_Shareable_Link")


  //   const handleShareButton = () => {
  //     handleOpen();
  //   };

  const handleSuccess = () => {
    handleSuccess(true)
  }


  function copyToClip() {
    navigator.clipboard.writeText(window.location.href).
      then(res => navigator.clipboard.readText().
        then(text => text.length > 0 && setCopySuccess(true)));
  }

  React.useEffect(() => {
    setCurrentPath(window.location.href)
  }, [])

  React.useEffect(() => {
    props.props.users.Users.length > 0 ?
      setAllUsers(
        props.props.users.Users.map(d => {
          return {
            Id: d.Id,
            Name: d.Name,
            Email: d.Email,
            ProfileImageUrl: d.ProfileImageUrl === '' ? GenerateProfilePic(d.Name) : d.ProfileImageUrl,
          }
        })
      )
      :
      setAllUsers([])
  }, [props.props.users.Users])

  React.useEffect(() => {
    const SocialSharingConfig = settings.find(
        item => item.TenantConfigurationTitle === "SocialSharing"
    );
    if(SocialSharingConfig){
        setEnabled(SocialSharingConfig.IsEnabled)
    }
  }, [settings]);


  React.useEffect(() => {
    const timer = setTimeout(() => {
      searchVal.length > 0 &&
        (setLocalLoader(true),
          UserAPI.fetchUsers({
            FilterField: "Name",
            FilterText: searchVal.trim()
          }).then(res => setAllUsers(
            res.data.Users.map(d => {
              return {
                Id: d.Id,
                Name: d.Name,
                Email: d.Email,
                ProfileImageUrl: d.ProfileImageUrl === '' ? GenerateProfilePic(d.Name) : d.ProfileImageUrl,
              }
            })
          )).finally(res => setLocalLoader(false)))
    }, 500);
    searchVal.length === 0 && setAllUsers(
      props.props.users.Users.map(d => {
        return {
          Id: d.Id,
          Name: d.Name,
          Email: d.Email,
          ProfileImageUrl: d.ProfileImageUrl === '' ? GenerateProfilePic(d.Name) : d.ProfileImageUrl,
        }
      }))
    return () => clearTimeout(timer)
  }, [searchVal.length])


  const handleSelectUser = (id) => {
    setSelectedUser([
      ...selectedUser,
      ...allUsers.filter((a) => a.Id == id).slice(0),
    ]);
    setMockSelectedUser([
      ...mockSelectedUser,
      ...allUsers.filter((a) => a.Id == id).slice(0),
    ]);
  };

  const handleSelectedUserFilter = (val) => {
    val.length > 0 ?
      setSelectedUser(selectedUser.filter(a => a.Name.toLowerCase().includes(val.toLowerCase())))
      :
      setSelectedUser(mockSelectedUser)
  };

  const handleRemoveUser = (val) => {
    console.log(selectedUser.indexOf(val))
    selectedUser.splice(selectedUser.indexOf(val), 1);
    mockSelectedUser.splice(mockSelectedUser.indexOf(val), 1);
    setSelectedUser([...selectedUser]);
    setMockSelectedUser([...mockSelectedUser]);
  };

  const handleShare = () => {
    setLocalLoaderSuccess(true)
    window.location.pathname.split('/').pop() === 'videocollection'
      ? API.shareVideo({
        sharedBy: userId,
        sharedWith: selectedUser.map((i) => i.Id),
        videoId: props.props.selection.map((i) => i.Id),
      }).then((res) => (
        setLocalLoaderSuccess(false),
        handleClose()
      )).finally(res => {
        props.alert(true)
      })
      : props.props.selection.map((i) => {
        selectedUser.map((user) => {
          API.shareCourses({
            playlistId: i.Id,
            sharedBy: userId,
            sharedWith: user.Id,
          }).then((res) => (
            setLocalLoaderSuccess(false),
            handleClose()
          )).finally(res => {
            props.alert(true)
          })
        });
      });
    console.log(props);
  };

  const handleSearch = (searchVal) => {
    setSearchVal(searchVal)
  }

  function SearchUsersComponent({ type }) {
    return (
      <>
        <div>
          <List sx={{ width: "100%", bgcolor: "background.paper" }} className="admin-share-list">
            {type === "select" ? (
              !localLoader ? (
                allUsers.filter((j) => j.Id !== userId && !selectedUser.includes(j)).length > 0 ? (
                  allUsers
                    .filter((j) => j.Id !== userId && !selectedUser.includes(j))
                    .map((i) => (
                      <ListItem
                        key={i.Id}
                        disableGutters
                        secondaryAction={
                          <IconButton aria-label="comment">
                            <button
                              className="share-btn-collection"
                              onClick={() => handleSelectUser(i.Id)}
                            >
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM15.0967 10.5999L10.436 10.6102V15.2813C10.4463 15.5201 10.2491 15.7173 10 15.7173C9.87544 15.7173 9.76126 15.6654 9.6886 15.5927C9.61593 15.5201 9.56403 15.4059 9.56403 15.2813V10.6102H4.89297C4.76841 10.6102 4.65423 10.5583 4.58157 10.4857C4.5089 10.413 4.457 10.2988 4.457 10.1743C4.44662 9.93554 4.64385 9.73832 4.89297 9.73832L9.55365 9.72794L9.56403 5.06725C9.55365 4.82851 9.75088 4.63129 10 4.63129C10.2387 4.62091 10.436 4.81813 10.436 5.06725V9.73832L15.0967 9.72794C15.3354 9.71756 15.5326 9.91478 15.5326 10.1639C15.543 10.4026 15.3458 10.5999 15.0967 10.5999Z"
                                  fill="#379E53"
                                />
                              </svg>
                            </button>
                          </IconButton>
                        }
                      >
                        <div className="user-inf lms-flex">
                          <div className="col-2 share-user">
                            {i.ProfileImageUrl === '' ? (
                              <img src={GenerateProfilePic(i.Name)} className="profile-avatar" />
                            ) : (
                              <img src={i.ProfileImageUrl} className="profile-avatar" />
                            )}
                          </div>
                          <div className="share-user-inf">
                            <h3>{i.Name}</h3>
                            <p className="email">{i.Email}</p>
                          </div>
                        </div>
                      </ListItem>
                    ))
                ) : (
                  <div className="search-no-user-con">
                    <div className="d-flex align-items-center gap-1">
                      <div className="i-icon">i</div>
                      <h4>User not found</h4>
                    </div>
                    <p className="text-center my-4">The user you're looking for hasn't been added yet. please fill below details and share the course</p>
                    <NavLink to={'/userManagement' + '/users'}>
                      <button
                        className="invite-user"
                      >
                        + Add User
                      </button>
                    </NavLink>
                  </div>
                )
              ) : (
                <Spinner />
              )
            ) : selectedUser.length > 0 ? (
              selectedUser
                .filter((j) => j.Id !== userId)
                .map((i) => (
                  <ListItem
                    key={i.Id}
                    disableGutters
                    secondaryAction={
                      <IconButton aria-label="comment">
                        <button
                          className="share-btn-collection"
                          onClick={() => handleRemoveUser(i)}
                        >
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
                              fill="#C93329"
                            />
                            <path
                              d="M4.5 10C4.5 9.72386 4.72386 9.5 5 9.5H15C15.2761 9.5 15.5 9.72386 15.5 10C15.5 10.2761 15.2761 10.5 15 10.5H5C4.72386 10.5 4.5 10.2761 4.5 10Z"
                              fill="white"
                            />
                          </svg>
                        </button>
                      </IconButton>
                    }
                  >
                    <div className="user-inf lms-flex">
                      <div className="col-2 share-user">
                        {i.ProfileImageUrl ? (
                          <img src={i.ProfileImageUrl} className="profile-avatar" />
                        ) : (
                          <img src={GenerateProfilePic(i.Name)} className="profile-avatar" />
                        )}
                      </div>
                      <div className="share-user-inf">
                        <h3>{i.Name}</h3>
                        <p className="email">{i.Email}</p>
                      </div>
                    </div>
                  </ListItem>
                ))
            ) : (
              <div className="search-no-user-con no-selected-users">
                <p>Start Adding users to Assign the course or Videos</p>
              </div>
            )}
          </List>
        </div>



      </>
    );
  }

  return (
    <Tabs>
      <TabList>
        <Tab>{Individual_Tab}</Tab>
        {/* <Tab>Group</Tab>
      <Tab>Create Group</Tab> */}
      </TabList>

      <TabPanel>
        <div class="managment-container lms-flex">
          {
            !localLoaderSuccess ?
              <>
                <div className="admin-share-user-left">
                  <div className="admin-share-con-user lms-flex">
                    <input type="text" placeholder={Search_PlaceHolder_Label} disabled={localLoader} onChange={(e) => handleSearch(e.target.value)} />
                  </div>
                  <SearchUsersComponent type={selectSection} />
                </div>
                <div class="admin-share-user-right">
                <div className={`share-through-con ${!enabled ? 'disabled' : ''}`}>
                    <Tooltip title={!enabled ? "Sharing is disabled by the admin":""}>
                    <div className="share-title m-0 p-0 pb-3">Share Through</div>
                    <div className="share-flex-con mt-3">
                      <div className="d-flex align-items-center">
                        <FacebookShareButton url={currentPath} disabled={!enabled}>
                          <FacebookIcon size={36} borderRadius={10} />
                        </FacebookShareButton>
                        <WhatsappShareButton url={currentPath} disabled={!enabled}>
                          <WhatsappIcon size={36} borderRadius={10} />
                        </WhatsappShareButton>
                        <LinkedinShareButton url={currentPath} disabled={!enabled}>
                          <LinkedinIcon size={36} borderRadius={10} />
                        </LinkedinShareButton>
                        <TwitterShareButton url={currentPath} disabled={!enabled}>
                          <TwitterIcon size={36} borderRadius={10} />
                        </TwitterShareButton>
                      </div>
                      <div className="sharebtn-con">
                        <button className={`copy-link-btn ${copySuccess && "copy-success-btn" }`} onClick={copyToClip} disabled={!enabled}>
                          {copySuccess ?
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M16.6668 5L7.50016 14.1667L3.3335 10" stroke="#278A42" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            :
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_13027_5887)">
                                <path d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z" stroke="#5681EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.16699 12.5H3.33366C2.89163 12.5 2.46771 12.3244 2.15515 12.0118C1.84259 11.6993 1.66699 11.2754 1.66699 10.8333V3.33334C1.66699 2.89131 1.84259 2.46739 2.15515 2.15483C2.46771 1.84227 2.89163 1.66667 3.33366 1.66667H10.8337C11.2757 1.66667 11.6996 1.84227 12.0122 2.15483C12.3247 2.46739 12.5003 2.89131 12.5003 3.33334V4.16667" stroke="#5681EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                              </g>
                              <defs>
                                <clipPath id="clip0_13027_5887">
                                  <rect width="20" height="20" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>}
                          {copySuccess ?  "Copied successfully" : "Copy Link"}
                        </button>
                      </div>
                    </div>
                    </Tooltip>
                  </div>
                  <hr />
                  <div className="share-title m-0 p-0 pt-4">Assign to User</div>
                  {/* <div className="admin-share-con-user lms-flex">
                    <input type="text" placeholder={Search_PlaceHolder_Label} onChange={(e) => handleSelectedUserFilter(e.target.value)} />
                  </div> */}
                  <SearchUsersComponent type={selectedSection} />
                  <div className="lms-flex share-buttons ">
                    <div className="col-5 d-flex"><button
                      className="primary-button ter-btn"
                      onClick={props.close}
                    >
                      {Cancel}
                    </button></div>
                    <button
                      className="primary-button sec-btn ad-share-btn"
                      disabled={selectedUser.length > 0 ? false : true}
                      onClick={handleShare}
                    >
                      {Share_Label}
                    </button>
                  </div>
                </div>
              </>
              :
              <Spinner />
          }

        </div>
      </TabPanel>
      <TabPanel>
        <div class="contai lms-flex">
          <div className="admin-share-user-left">
            <div className="admin-share-con-user lms-flex">
              <input type="text" placeholder="search" />
            </div>

          </div>
          <div class="admin-share-user-right">
            <div className="share-title ">UX Team</div>

            <div className="lms-flex share-buttons ">
              <div className="col-5 d-flex">
                <button
                  className="primary-button ter-btn"
                  onClick={handleClose}
                >
                  {Cancel}
                </button>
              </div>
              <button
                className="primary-button sec-btn ad-share-btn"
                disabled={selectedUser.length > 0 ? false : true}
                onClick={handleShare}
              >
                {Share_Label}
              </button>
            </div>

          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div class="contai lms-flex">
          <div className="admin-share-user-left">
            <div className="admin-share-con-user lms-flex">
              <input type="text" placeholder="search" />
            </div>

          </div>
          <div class="admin-share-user-right">
            <div className="share-title ">Details</div>

            <div className="lms-flex share-buttons ">
              <div className="col-5 d-flex">
                <button
                  className="primary-button ter-btn"
                  onClick={handleClose}
                >
                  {Cancel}
                </button>
              </div>
              <button
                className="primary-button sec-btn ad-share-btn"
                disabled={selectedUser.length > 0 ? false : true}
                onClick={handleShare}
              >
                {Share_Label}
              </button>
            </div>

          </div>
        </div>
      </TabPanel>
    </Tabs>
  );
}