import { call, put } from "redux-saga/effects";
import actions from "../actions/actionTypes";
import API from "../../utils/API";

export function* getprofileDataSaga(action) {
    try {
        console.log("in sagas", action);
        const response = yield call(API.profileData, action.payload);
        const profileData = yield response.data;
        console.log("profile data response ======================>", response.data);

        yield put({
            type: actions.GET_PROFILE_DATA_SUCCEEDED,
            profileData: profileData
        });
    } catch(error) {
        console.log(error)
        yield put({ type: actions.GET_PROFILE_DATA_FAILED, message: error.message });
    }
}