import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { bindActionCreators } from 'redux';
import { fetchAdminSettings } from '../store/actions/admin';
import API from '../utils/API';
import { configurationType, tenantConfig, tenantCourseConfig, videoSetting } from './Constants';
import { Alert, Backdrop } from '@mui/material';
import Spinner from '../../../components/Common/Spinner';
import '../../../css/SettingsCss.css'

export const Settings = (props) => {
    const [settings, setSettings] = useState([])
    const [selectedVideoSettings, setselectedVideoSettings] = useState({
        id: null,
        state: null
    })
    const [backdropOpen, setBackdropOpen] = useState(false)
    const [inputValue, setInputValue] = useState(null);  
    const [alertData, setAlertData] = useState({
        severity: '',
        message: ''
    })
    const [isLoader, setLoader] = useState(false)

    const handleBackDropClose = () => {
        setBackdropOpen(false);
        setAlertData({
            severity: '',
            message: ''
        })
    };

    useEffect(() => {
        const initialValues = {};
        settings.filter(i => i.configurationtype === 1).forEach(setting => {
          initialValues[setting.id] = setting.configurationvalue || "";
        });
        setInputValue(initialValues)
      }, [settings]);
    
    const handleInputChange = (setting, e) => {
        const value = e.target.value;
        setInputValue((prevValues) => ({
          ...prevValues,
          [setting.id]: value,  
        }));
      };

    const handleVideoSetting = (settings, configurationvalue, data) => {
        setLoader(true)
        let boolValue = /^true$/i.test(data);
        console.log(settings ,data, boolValue)
        API.updateAdminSettings({
            TenantSettingsId: settings.id,
            ConfigurationValue: configurationvalue,
            IsEnabled: boolValue
        }).then(res => {
            if (res.status === 200) {
                props.fetchAdminSettingsAction()
                setLoader(false)
                setAlertData({
                    severity: 'success',
                    message: `${settings.name} settings changed successfully`
                })
                setBackdropOpen(true)
                setselectedVideoSettings({
                    id: settings.id,
                    data
                })
                
            }
            else
            {
                setBackdropOpen(true)
                setAlertData({
                    severity: 'error',
                    message: `${settings.name} settings changing failed`
                })
            }
        })
    }

    useEffect(() => {
        if (props.settingsList.length > 0) {
            var defaultChecked = props.settingsList.filter(a => a.TenantConfigurationTitle.includes(videoSetting.configuration))[0].IsEnabled
            setselectedVideoSettings(defaultChecked)
        }
    }, [props.settingsList])

    useEffect(()=>{
        props.fetchAdminSettingsAction(1)
    },[props.fetchAdminSettingsAction])

    useEffect(() => {
        setTimeout(() => {
            setBackdropOpen(false)
            setAlertData({
                severity: '',
                message: ''
            })
        }, 4000);
    }, [alertData])

    useEffect(() => {
      setSettings(props.settingsList.filter(a => Object.keys(tenantCourseConfig).includes(a.TenantConfigurationTitle)).map(set => {
        return{
            type: set.TenantConfigurationTitle,
            id: set.Id,
            state: set.IsEnabled,
            name: tenantCourseConfig[set.TenantConfigurationTitle],
            configurationvalue: set.ConfigurationValue,
            configurationtype: set.ConfigurationType
        }
      }))
    }, [props.settingsList])


    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={handleBackDropClose}
            >
                <Alert severity={alertData.severity} style={{ position: "absolute", top: 20 }}>
                    {alertData.message}
                </Alert>
            </Backdrop>
            <div className='tenent-setting-icon'>
                {isLoader? <Spinner /> :
                <div>
            {
                settings && settings.filter(a => Object.keys(tenantCourseConfig).includes(a.type)).map((setting, idx) => {
                    const isSocialSharing = setting.type === 'SocialSharing';
                    return (
                        <div className="setting-icon-tab" key={idx}>
                        <div className="d-flex items-con">
                            <h3>{setting.name} Upload Defaults</h3>
                            <svg className="setting_tab_i" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 0C6.98041 0 4.56411 1.00089 2.78253 2.78253C1.00089 4.56404 0 6.98048 0 9.5C0 12.0195 1.00089 14.4359 2.78253 16.2175C4.56404 17.9991 6.98048 19 9.5 19C12.0195 19 14.4359 17.9991 16.2175 16.2175C17.9991 14.436 19 12.0195 19 9.5C19 6.98048 17.9991 4.56411 16.2175 2.78253C14.436 1.00089 12.0195 0 9.5 0ZM9.61782 14.3507C9.70092 14.3952 9.80072 14.3952 9.88381 14.3507C10.0479 14.2314 10.1973 14.093 10.3283 13.9384C10.5184 13.7123 10.6855 13.4672 10.8736 13.2126L11.1377 13.3722C10.7577 14.0067 10.3664 14.5957 9.78495 15.0365C9.4796 15.2797 9.11249 15.4331 8.72483 15.4792C7.81857 15.5818 7.23901 14.9643 7.45571 14.0751C7.64576 13.2942 7.88697 12.5248 8.10737 11.7496C8.32777 10.9744 8.55003 10.1687 8.77426 9.38217C8.79229 9.30451 8.80554 9.22591 8.81415 9.14666C8.84901 9.01241 8.82211 8.86953 8.74086 8.75716C8.65975 8.64477 8.53266 8.5744 8.39415 8.56526C8.25645 8.55306 8.11822 8.54803 7.97999 8.55002C7.98237 8.50442 7.9894 8.45923 8.00093 8.4151L8.02757 8.22504L11.3031 7.70061L11.0752 8.49474L11.0239 8.68479C10.5487 10.3442 10.0719 12.0092 9.59304 13.6801C9.54426 13.8109 9.51299 13.9476 9.5 14.0867C9.49987 14.1875 9.54267 14.2836 9.61782 14.3508V14.3507ZM10.4044 5.88996C10.0903 5.88784 9.79001 5.76087 9.56961 5.53703C9.34934 5.31319 9.22714 5.01074 9.23019 4.69677C9.23019 4.27517 9.4551 3.88568 9.82024 3.67495C10.1852 3.46409 10.6351 3.46409 11 3.67495C11.3652 3.88567 11.5901 4.27517 11.5901 4.69677C11.5895 5.01166 11.4647 5.31372 11.2427 5.53717C11.0207 5.76062 10.7195 5.88745 10.4045 5.88996H10.4044Z" fill="#043677" />
                            </svg>
                        </div>
                            <div className='radio-button-con'>
                                <FormControl className='w-100'>
                                    {setting.configurationtype == configurationType.input ? (
                                        <div className='tenant-input'>
                                            <input id='course-expiry-inp'
                                                label="Expiry (Days)"
                                                variant="outlined"
                                                value={inputValue[setting.id] || ""}
                                                onChange={(e) => handleInputChange(setting, e)}
                                                placeholder='Add Expiry date'
                                                type="number"
                                            />
                                            <button
                                                className='primary-button sec-btn'
                                                onClick={() => handleVideoSetting(setting, inputValue[setting.id], true)}
                                                disabled={inputValue[setting.id] === '' || isNaN(inputValue[setting.id]) || inputValue[setting.id] == null}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    ) : (
                                <RadioGroup
                                    onChange={(e) => handleVideoSetting(setting, setting.ConfigurationValue, e.target.value)}
                                    key={idx}
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={setting.state}
                                >
                                    <div className="d-flex align-items-center">
                                        <FormControlLabel value={setting.type === "SharingAllowed" ? false : true } control={<Radio />} label={ isSocialSharing ? "Enable" : "Private"}/>
                                        {setting.type === "SocialSharing" ?
                                        <p>Users can share on social media or via a shareable link.</p> :
                                        <p>(All {setting.name}s you upload will be visible only to the admin)</p>
                                         }
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <FormControlLabel value={setting.type === "SharingAllowed" ? true : false } control={<Radio />} label={isSocialSharing ? "Disable" : "Public"} />
                                        {setting.type === "SocialSharing" ?
                                        <p>Content is shared internally, with social sharing disabled.</p> :
                                        <p>(All {setting.name}s you upload will be visible to everyone.)</p>
                                        }
                                    </div>
                                </RadioGroup>
                                )}
                            </FormControl>
                        </div>
                    </div>
                    )
                })
            }
                </div>}
            </div>
          
            {/* {
                props.settingsList.map((item, idx) => {
                    return (
                     
                    )
                })
            } */}
        </>
    )
}

const mapStateToProps = (state) => ({
    settingsList: state.tenant.settings,
})

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        fetchAdminSettingsAction: fetchAdminSettings
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)