import * as React from 'react';
import {
    Checkbox,
    List,
    ListItem,
    ListItemText,
    Modal,
    Tooltip,
    Breadcrumbs,
    Link,
    Tabs,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Collapse,
    ListItemButton,
    FormControlLabel,
    Divider,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    MenuItem,
    Chip,
    TextField,
    InputAdornment,
    Input,
    Backdrop,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormHelperText,


} from '@mui/material';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import moment from "moment";
import { useLocation } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import store from '../../../store/configureStore';
import GenerateProfilePic from '../../../components/Common/GenerateProfilePictureIntitals';
import { Box, fontWeight } from '@mui/system';
import API from '../utils/API';
import { default as UserAPI } from "../../../utils/API";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Selectcategory from "../Video/Selectcategory";
import DraganddropCourse from "../Course/DraganddropCourse";
import Uploadwithsas from './Uploadwithsas';
import { async } from 'q';
import { useTranslation } from 'react-i18next';
import { LocalTimeFormat, captureThumbnail, courseContentTypes, liveCourseProviders, quizContentType, sessionPayload, sessionStatus, sizeConvertor, videoContentType, webinarJoinLinks } from '../../../components/Common/Constants';
import { CourseType, convertTo24Hour, courseCollectionPath, documentPath, extension, createDateTime, videoCollectionPath, dialogType, tempVideoPath, validationLimits, currenCustomtDate, currentTime, thumbnailPath, webinarDocumentPath } from './Constants';
import DocumentCheck from './DocumentCheck';
import Dropzone from 'react-dropzone';
import Spinner from '../../../components/Common/Spinner';
import { bindActionCreators } from 'redux';
import { fetchAdminCourse, fetchAdminVideo, fetchQuestionLevel, fetchQuestions, fetchQuiz, postTutorialDocument } from '../store/actions/admin';
import ErrorTab from '../../../components/Common/ErrorTab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { debounce } from 'lodash';
import { sessionCreateLogicAppRequest } from '../../../components/SessionCreateUtil/Util';
import getSessionPayloadBody from '../../../components/SessionCreateUtil/sessionPayload';
import NoCourseAndVideos from './NoCourseAndVideos';
import { useNavigate } from 'react-router-dom';
import '../../../css/CollectionTableCss.css';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import download from '../../../components/Common/DownloadFile';

import { v4 as uuidv4 } from 'uuid';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box className='centered-panel' sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function Tag(props) {
    const { label, onDelete, ...other } = props;
    return (
        <div {...other}>
            <span>{label}</span>
            <CloseIcon onClick={onDelete} />
        </div>
    );
}

Tag.propTypes = {
    label: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
};

const CustomListbox = (props) => {
    return (
        <List {...props} style={{ fontSize: '0.83vw' }}> {/* Change the font size here */}
            {props.children}
        </List>
    );
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function CollectionTableLayout(props) {
    const thumbnailFile = React.useRef(null)
    const courseNameRef = React.useRef(null)
    const descriptionRef = React.useRef(null)
    const categoryRef = React.useRef(null)
    const documentRef = React.useRef(null)
    const sessionVideoUploadRef = React.useRef(null)
    const sessionThumbnailRef = React.useRef(null)
    const [selectedCollection, setSelectedCollection] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [editOpen, setEditOpen] = React.useState(false);
    const [videoEditOpen, setVideoEditOpen] = React.useState(false)
    const [isEditQuizOpen, setEditQuizOpen] = React.useState(false)
    const [dropdown, setDropdown] = React.useState(null);
    const [openDropdown, setDropdownOpen] = React.useState(false);
    const [videoUpdateLoader, setVideoUpdateLoader] = React.useState(false);
    const [videoList, setVideoList] = React.useState([])
    const [videoData, setVideoData] = React.useState({})
    const [editQuizData, setEditQuizData] = React.useState({})
    const [videoTitleChange, setVideoTitleChange] = React.useState('')
    const [quizTitle, setQuizTitle] = React.useState('')
    const [videoDescriptionChange, setVideoDescriptionChange] = React.useState('')
    const [videoTranscriptChange, setVideoTranscriptChange] = React.useState('')
    const [videoPointsChange, setVideoPointsChange] = React.useState('0')
    const [videoTagsChange, setVideoTagsChange] = React.useState('')
    const [videoCategoryChange, setVideoCategoryChange] = React.useState({
        Id: null,
        Category: ""
    })
    const [videoDocumnetChange, setVideoDocumnetChange] = React.useState([])
    const [videoDocumentsResponce, setVideoDocumentsResponce] = React.useState([])
    const [updatedDocumentData,setUpdatedDocumentData] = React.useState([])
    const [selectedPlaylistId, setSelectedPlaylistId] = React.useState('')
    const [selectedQuiz, setSelectedQuiz] = React.useState({})
    const [videoListMock, setVideoListMock] = React.useState([])
    const [localLoading, setLocalLoading] = React.useState(false)
    const [quizSearch, setQuizSearch] = React.useState('')
    const [videoSearch, setvideoSearch] = React.useState('')
    const [suggestions, setSuggestions] = React.useState([]);
    const [suggestionIndex, setSuggestionIndex] = React.useState(null);
    const [suggestionsActive, setSuggestionsActive] = React.useState(false);
    const [document, setDocument] = React.useState({});
    const [documentError, setDocumentError] = React.useState('');
    const [documentLength,setDocumentLength] = React.useState(false)
    const [videoId, setVideoId] = React.useState(null);
    const [activeVideo, setActiveVideo] = React.useState([])
    const [desctiption, setDescriptionChange] = React.useState('');
    const [desctiptionMock, setDescriptionChangeMock] = React.useState('');

    const [title, setTitleChange] = React.useState('');
    const [titleMock, setTitleChangeMock] = React.useState('');

    const [category, setCategoryChange] = React.useState('');
    const [categoryMock, setcategoryMock] = React.useState('');
    const [initcategory, setCategory] = React.useState('');
    const [playlistTitle, setPlaylistTitle] = React.useState('');
    const [playlistTitleChange, setPlaylistTitleChange] = React.useState('');
    const [extisingQuiz, setExistingQuiz] = React.useState([]);
    const [IsBreadcrumbsActive, setBreadcrumbsActive] = React.useState(false);
    const [value, setValue] = React.useState(0);
    const [isAddSessions, setAddSession] = React.useState(false);
    const [isEditSession, setEditSession] = React.useState(false)
    const [openIndex, setOpenIndex] = React.useState(null);
    const [isSesionsListActive, setSetionsList] = React.useState(false);
    const [editSessionOnList, setEditSessionOnList] = React.useState(false);
    const [courseDetails, setCourseDetails] = React.useState({
        Course: {},
        Sessions: [],
        Videos: [],
        RelatedCourse: [],
        Quizes: []
    });

    ///Live Course
    const [courseId, setCourseId] = React.useState(null)
    const [courseName, setCourseName] = React.useState('')
    const [thumbnail, setThumbnail] = React.useState(null)
    const [courseDescription, setCourseDescription] = React.useState('')
    const [courseTranscript, setCourseTranscript] = React.useState('')
    const [coursePoints, setCoursePoints] = React.useState(0)
    const [courseTags, setCourseTags] = React.useState('')
    const [selectedEditQuiz, setSelectedEditQuiz] = React.useState([])
    const [selectedCategory, setSelectedCategory] = React.useState({
        Id: null,
        Category: ""
    });
    const [documentsList, setDocumentsList] = React.useState(null)
    const [videoSelectedCatId, setVideoSelectedCatId] = React.useState({
        Id: null,
        Category: ""
    })
    const [thumbnailPreview, setThumbnailPreview] = React.useState('');
    const [sessionDocumnetError, setSessionDocumnetError] = React.useState(false);

    ///timelines
    const [timelineSection1, setTimelineSection1] = React.useState(false)
    const [timelineSection2, setTimelineSection2] = React.useState(false)

    ///errors
    const [courseNameError, setCourseNameError] = React.useState(false)
    const [courseDescriptionError, setCourseDescriptionError] = React.useState(false)
    const [courseCategoryError, setCourseCateogryError] = React.useState(false)
    const [thumbnailError, setThumbnailError] = React.useState(false)

    const [sessionNameError, setSessionNameError] = React.useState(false)
    const [sessionDescriptionError, setSessionDescriptionError] = React.useState(false)
    const [sessionThumnbnailError, setSessionThumbnailError] = React.useState(false)
    const [sessionDateError, setSessionDateError] = React.useState(false)
    const [sessionTimeError, setSessionTimeError] = React.useState(false)


    ///Loader
    const [modalLoader, setModalLoader] = React.useState(false)
    const [localLoader, setLocalLoader] = React.useState(false)


    ///Sessions
    const [sessionIndex, setSessionIndex] = React.useState(null)
    const [sessionId, setSessionId] = React.useState(null)
    const [sessionName, setSessionName] = React.useState('')
    const [sessionDate, setSessionDate] = React.useState('')
    const [sessionStartTime, setSessionStartTime] = React.useState('')
    const [sessionEndTime, setSessionEndTime] = React.useState('')
    const [sessionDocumnet, setSessionDocumnet] = React.useState([])
    const [sessionDescription, setSessionDescription] = React.useState('')
    const [sessions, setSessions] = React.useState([])
    const [sessionThumbnailPreview, setSessionThumbnailPreview] = React.useState('');
    const [sessionThumbnail, setSessionThumbnail] = React.useState([]);

    ///edit session 
    const [sessionDocumnetId, setSessionDocumentId] = React.useState(null)
    const [editSessionId, setEditSessionId] = React.useState(null)
    const [editSessionName, setEditSessionName] = React.useState('')
    const [editSessionType, setEditSessionType] = React.useState('')
    const [editSessionDate, setEditSessionDate] = React.useState('')
    const [editSessionStartTime, setEditSessionStartTime] = React.useState('')
    const [editSessionEndTime, setEditSessionEndTime] = React.useState('')
    const [editSessionDocumnet, setEditSessionDocumnet] = React.useState([])
    const [editSessionDocumnetUpload, setEditSessionDocumnetUpload] = React.useState([])
    const [editSessionDescription, setEditSessionDescription] = React.useState('')
    const [editSessionThumbnailPreview, setEditSessionThumbnailPreview] = React.useState('');
    const [editSessionThumbnail, setEditSessionThumbnail] = React.useState([]);
    const [sessionVideoUpload, setSessionVideoUpload] = React.useState([]);
    const [courseType, setCourseType] = React.useState(null);
    const [sessionVideoUploadData, setSessionVideoUploadData] = React.useState({
        Thumbnail: '',
        Name: '',
        Size: ''
    });
    const [videoQuizMockList, setVideoQuizMockList] = React.useState([])
    const [getAllQuiz, setAllQuiz] = React.useState([]);
    const [isQuizLoader, setQuizLoader] = React.useState(false)
    const [selectedQuizList, setSelectedQuizList] = React.useState([]);

    const CourseTypes = ["Course", "Live Course"]
    const navigate = useNavigate();


    ///Alert
    const [alert, setAlert] = React.useState({
        active: false,
        severity: '',
        message: ''
    })
    const [dialog, setDialog] = React.useState({
        Open: false,
        Header: '',
        Body: '',
        ButtoneName: '',
        modelType: ''
    })

    // Quiz Edit 

    const [quizTitleError, setQuizTitleError] = React.useState(false);
    const [retake, setRetake] = React.useState(0);
    const [questionNumber, setQuestionNumber] = React.useState({});
    const [levelPercentage, setLevelPercentage] = React.useState({});
    const [questionPoint, setQuestionPoint] = React.useState({});
    const [quizLevelEmptyError, setQuizLevelEmptyError] = React.useState(false);
    const [quizLevelConfigError, setQuizLevelConfigError] = React.useState(false);
    const [levelPercentError, setLevelPercentError] = React.useState(false);
    const [totalQuestions, setTotalQuestions] = React.useState(0);
    const [totalScores, setTotalScores] = React.useState(0);
    const [passingPercentage, setPassingPercentage] = React.useState(0);
    const [quizTotalQuestionError, setQuizTotalQuestionError] = React.useState(false);
    const [quizPointError, setQuizPointError] = React.useState('');
    const [isRetakeAllowed, setIsRetakeAllowed] = React.useState(false);


    const { t } = useTranslation();
    const No_Data_available = t("No_Data_available")
    const Or = t("Or")
    const Update_Button = t("Update_Button")
    const Chose_File = t("Chose_File")
    const Drag_And_Drop = t("Drag_And_Drop")
    const Documents_Label = t("Documents_Label")
    const Description_Label = t("Description_Label")
    const Video_Edit = t("Video_Edit")
    const Course_Edit = t("Course_Edit")
    const Add_Description = t("Add_Description")
    const Link_Quiz = t("Link_Quiz")


    const userId = useSelector(() => store.getState().profileData.id)
    const userMail = useSelector(() => store.getState().profileData.Email)
    const userName = useSelector(() => store.getState().profileData.Name)

    const location = useLocation();

    React.useEffect(() => {
        if (open || editOpen) {
            props.modalOpen(true)
        }

        if (!open && !editOpen) {
            props.modalOpen(false)
        }
    }, [open, editOpen])



    const fetchQuizzes = async ({ PageNumber = 1, PageSize = 20, combinedVideos = [] }) => {
        try {
            const res = await API.getAllQuizes({ PageNumber, PageSize });
            if (res.status === 200) {
                if (Array.isArray(res.data.Quiz)) {
                    let quizzes = res.data.Quiz.map(q => ({
                        ...q,
                        isDisabled: q.QuizLevels.every(level => level.AssignedQuestions === 0),
                    }));
                    const newQuizzes = quizzes.filter(
                        q => !combinedVideos.some(mockQuiz => mockQuiz.Id === q.Id)
                    );

                    return newQuizzes;
                } else {
                    console.error('Quiz data is not an array:', res.data.Quiz);
                    return [];
                }
            } else {
                console.error('Failed to fetch quizzes:', res.status, res.data);
                return [];
            }
        } catch (error) {
            console.error('Error fetching quizzes:', error);
            return [];
        }
    };

    const getAllDataFromApis = async (id) => {
        try {
            const res = await UserAPI.fetchCoursesDetail(id);
            if (res.status === 200) {
                const combinedVideos = [...res.data.Videos, ...res.data.Quiz];
                setVideoList(combinedVideos);
                setVideoListMock(combinedVideos);
                const course = res.data.Course;
                setCourseType(courseType);
                setCourseName(course.CourseName);
                setCourseDescription(course.Description);
                setSelectedCategory({
                    Id: course.PlaylistCategoryId,
                    Category: course.CourseCategory
                });
                setVideoList([...res.data.Videos, ...res.data.Quiz]);
                setVideoListMock([...res.data.Videos, ...res.data.Quiz]);
                setThumbnailPreview(course.ThumbnailUrl);
                setSelectedEditQuiz({});
                setQuizSearch('');
                setvideoSearch('');

                const newQuizzes = await fetchQuizzes({ PageNumber: 1, PageSize: 20, combinedVideos });
                setAllQuiz(newQuizzes);
            } else {
                console.error('Failed to fetch course details:', res.status);
            }
        } catch (error) {
            console.error('Error fetching course details:', error);
        } finally {
            setLocalLoader(false);
            setModalLoader(false);
            setQuizLoader(false)
        }
    };

    const handleOpen = async (id) => {
        setSelectedPlaylistId(id);
        setOpen(true);
        setModalLoader(true);
        setQuizLoader(true);

        await getAllDataFromApis(id);
    };




    const resetDialog = () => {
        setDialog({
            Open: false,
            Header: '',
            Body: '',
            ButtoneName: '',
            modelType: ''
        })
    }

    React.useEffect(() => {
        props.selection([])
    }, [location.pathname]);

    const handleClose = () => {
        resetDialog()
        setSelectedPlaylistId('')
        setOpen(false)
        setVideoEditOpen(false)
        setEditQuizOpen(false)
        setVideoList([])
        setSuggestionsActive(false)
        setSelectedQuiz({})
        clearSearch()
        setEditOpen(false);
        setTitleChange('')
        setTitleChangeMock('')
        setDescriptionChange('')
        setDescriptionChangeMock('')
        setVideoTitleChange('')
        setVideoCategoryChange('')
        setVideoDescriptionChange('')
        setVideoDocumnetChange([])
        setDropdownOpen(false);
        // setVideoListMock([])
        setAddSession(false);
        setEditSession(false);
        setSetionsList(false);

        setSessions([])

        setCourseName('')
        setCourseDescription('')
        setSelectedCategory({
            Id: null,
            Category: ""
        })
        setVideoSelectedCatId({
            Id: null,
            Category: ""
        })
        setThumbnailPreview('')
        setThumbnail([])
        setModalLoader(false)
        setEditSessionOnList(false)
        setCourseDescriptionError(false)
        setCourseCateogryError(false)
        setCourseNameError(false)
        setThumbnailError(false)

        setSessionName('')
        setSessionDescription('')
        setSessionDate('')
        setSessionStartTime('')
        setSessionEndTime('')
        setSessionDocumnet([])
        setSessionThumbnail([])
        setSessionThumbnailPreview('')
        setSessions([])

        setSessionNameError(false)
        setSessionDateError(false)
        setSessionDescriptionError(false)
        setSessionThumbnailError(false)
        setSessionTimeError(false)
        setCourseType(null)

        setCourseName('')
        setCourseDescription('')
        setSelectedCategory({
            Id: null,
            Category: ''
        })
        setVideoList([])
        setVideoListMock([])
        setThumbnailPreview('')
        props.selection([])
        setCourseTags('')
        setCoursePoints(0)
        setVideoPointsChange('0')
        setAllQuiz([])
        setSelectedQuizList([])
        setQuizTitleError(false)
        setRetake(0)
        setQuestionNumber({})
        setLevelPercentage({})
        setQuestionPoint({})
        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setLevelPercentError(false)
        setTotalQuestions(0)
        setTotalScores(0)
        setPassingPercentage(0)
        setQuizTotalQuestionError(0)
        setQuizPointError('')
        setIsRetakeAllowed(false)
        setUpdatedDocumentData([])
        setVideoDocumentsResponce([])
        setDocumentLength(false);
        setEditSessionDocumnet([]);
        setEditSessionDocumnetUpload([])
        setSessionVideoUpload([])
    }

    React.useEffect(() => {
        props.registerEditClickHandler(handleEdit);
    }, [props.registerEditClickHandler]);

    React.useEffect(() => {
        props.registerLinkQuizClickHandler(handleOpen);
    }, [props.registerLinkQuizClickHandler]);

    const handleEdit = (Id, courseType) => {
        setVideoId(Id)
        if (window.location.pathname.split('/').pop() === videoCollectionPath) {
            setVideoEditOpen(true)
            setModalLoader(true)
            setEditOpen(true);
            setLocalLoading(true);
            API.getAdminVideoDetails(Id).then(res => {
                if (res.status === 200) {
                    setVideoData(res.data);
                    setVideoTranscriptChange(res.data.Transcript);
                    setVideoTagsChange(res.data.Tags);
                    setVideoPointsChange(res.data.Points)
                    setVideoCategoryChange({
                        Id: props.playlistCategories
                            .filter(i => i.PlaylistCategoryName === res.data.CategoryName)
                            .map(i => i.Id)[0],
                        Category: res.data.CategoryName
                    });

                    const validDocuments = res.data.Documents.filter(doc => doc.Title && doc.Title.trim() !== "");
                    setThumbnailPreview(res.data.ThumbnailUrl);

                    setVideoCategoryChange({
                        Id: props.playlistCategories
                            .filter(i => i.PlaylistCategoryName === res.data.CategoryName)
                            .map(i => i.Id)[0],
                        Category: res.data.CategoryName
                    });
                    setVideoDocumnetChange(validDocuments);
                }
            }).finally(() => {
                setLocalLoading(false);
            });

        }

        if (window.location.pathname.split('/').pop() === courseCollectionPath) {
            setCourseId(Id)
            setModalLoader(true)
            setEditOpen(true);
            UserAPI.fetchCoursesDetail(Id)
                .then(res => {
                    if (res.status === 200) {
                        var course = res.data.Course
                        setCourseType(courseType)
                        setCourseName(course.CourseName)
                        setCourseDescription(course.Description)
                        setCourseTags(course.Tags)
                        setSelectedCategory({
                            Id: course.CategoryId,
                            Category: course.CourseCategory
                        })
                        setVideoList([...res.data.Videos, ...res.data.Quiz])
                        setVideoListMock([...res.data.Videos, ...res.data.Quiz])
                        setThumbnailPreview(course.ThumbnailUrl)
                    }
                })
                .finally(() => {
                    setModalLoader(false);
                })
        }
    }


    const handleVideoEdit = (video) => {
        setActiveVideo(video)
        setVideoId(video.Id);
        setVideoEditOpen(true);
        setLocalLoading(true);

        if (video.Type === 3) {
            setVideoEditOpen(true);
            API.getAdminVideoDetails(video.Id)
                .then(res => {
                    if (res.status === 200) {
                        setVideoData(res.data);
                        setVideoTranscriptChange(res.data.Transcript);
                        setVideoTagsChange(res.data.Tags);
                        setVideoPointsChange(res.data.Points)
                        setVideoCategoryChange({
                            Id: props.playlistCategories
                                .filter(i => i.PlaylistCategoryName === res.data.CategoryName)
                                .map(i => i.Id)[0],
                            Category: res.data.CategoryName
                        });


                        const validDocuments = res.data.Documents.filter(doc => doc.Title && doc.Title.trim() !== "");
                        setVideoDocumnetChange(validDocuments);
                        setThumbnailPreview(res.data.ThumbnailUrl);
                        getPlaylistDetails(courseId)
                    }
                })
                .catch(err => {
                    console.error("Error fetching tutorial details:", err);
                })
                .finally(() => {
                    setLocalLoading(false);
                });
        } else if (video.Type === 2) {
            setEditQuizOpen(true);
            const selectedQuiz = videoQuizMockList.find((i) => i.Id === video.Id);
            setQuizTitle(selectedQuiz.Title)
            setRetake(parseInt(selectedQuiz.NumberOfRetake))
            setIsRetakeAllowed(selectedQuiz.NumberOfRetake > 0)
            setTotalQuestions(selectedQuiz.TotalQuestions)
            setTotalScores(selectedQuiz.TotalScore)
            setPassingPercentage(selectedQuiz.PassScoreInPertcentage)
            setQuestionNumber(selectedQuiz.QuizLevels.reduce((acc, cur) => ({ ...acc, [cur.LevelId]: cur.NumberOfQuestions }), {}))
            setLevelPercentage(selectedQuiz.QuizLevels.reduce((acc, cur) => ({ ...acc, [cur.LevelId]: cur.PassScoreInPertcentage }), {}))
            setQuestionPoint(selectedQuiz.QuizLevels.reduce((acc, cur) => ({ ...acc, [cur.LevelId]: cur.PointsPerQuestion }), {}))
            setEditQuizOpen(true);
            setSelectedEditQuiz(selectedQuiz || []);
            setLocalLoading(false);
        }
    };


    const handleCollapse = (event, e) => {
        event.preventDefault();

        setDescriptionChangeMock(e.data.Description)
        setTitleChangeMock(e.data.Title)
        setcategoryMock(e.data.PlalistCategory)


        setVideoId(e.data.Id)
        setDropdown(e.index);
        setDropdownOpen((prev) => !prev);
    }

    const handleDelete = (event, e) => {
        event.preventDefault();
        setLocalLoading(true)
        if (e.data.Type === courseContentTypes.Video) {
            API.removeVideoFromPlaylist({
                videoId: e.data.Id,
                playlistId: courseId
            }).then(res => res.status === 204 && handleEdit(courseId)).finally(res =>
                setLocalLoading(false)
            )
        }
        else {
            API.removeQuizFromPlaylist({
                quizId: e.data.Id,
                playlistId: courseId
            }).then(res => res.status === 204 && API.reArrangeCourse(courseId).then(res => handleEdit(courseId))).finally(res => setLocalLoading(false))
        }
    }

    const onDrop = (e) => {
        if (videoDocumnetChange.length + e.length > 5) {
            setDocumentLength(true);
            return;
        }
        else{
            setDocumentLength(false);
        }

        setDocumentLength(false);

        const newFiles = e.filter(
            file => !videoDocumnetChange.some(doc => doc.Title === file.name)
        );

        if (newFiles.length === 0) {
            setDocumentError("Duplicate files are not allowed");
            return;
        }
        else{
            setDocumentError("");
        }

        setVideoDocumnetChange(prevChanges => [
            ...prevChanges,
            ...newFiles.map(file => ({
                Id: uuidv4(),
                Title: file.name,
                CreatedDate: file.lastModifiedDate,
                Extension: file.name?.split('.').pop(),
                newDoc: true
            }))
        ])

        const documentArray = newFiles.map(file => ({ FileName: file.name }));

        API.postTutorialDocument({
            VideoId: videoId,
            Documents: documentArray
        })
            .then(res => {
                if (res.status === 200) {
                    setVideoDocumentsResponce(prevResponse => [
                        ...prevResponse,
                        ...res.data
                    ]);
                }
            })
            .catch(error => {
                console.error("Error posting documents:", error);
            });
    };

    React.useEffect(() => {
        const updatedData = videoDocumentsResponce.map((response, index) => {
            const docs = videoDocumnetChange.filter(d => d.newDoc);
            // const documentData = videoDocumnetChange[index];
            const documentData = docs[index];
            return documentData
                ? {
                    VideoId: videoId,
                    FileId: response.FileId,
                    DocumentGeneratedFileName: response.FileId,
                    FileName: documentData.Title,
                    Id: response.DocumentId,
                    Title: documentData.Title,
                    Extension: documentData.Title.split('.').pop()
                }
                : null;
        }).filter(item => item !== null);
        console.log("Updated document", updatedData);
        setUpdatedDocumentData(updatedData);
    }, [videoDocumentsResponce]);

    const handleDocument = async (e) => {
        setDocument(e)
        const res = await UserAPI.getDocument({
            userId: userId,
            videoId: videoId
        })
        const payload = {
            VideoId: videoId,
            FileId: "",
            FileName: e.name
        }
        const VideoData = {
            VideoId: videoId,
            FileId: ""
        }
        const DocumentData = {
            DocumentId: res.data[0].Id,
            FileId: ""
        }
        const DocumentExtension = e.name.split('.').pop()
        const DocumentName = e.name
        API.updateDocument({ VideoData, DocumentExtension, DocumentData, DocumentName }).then(res => res.status === 200 &&
            Uploadwithsas(JSON.parse(localStorage.getItem('docs')), res.data.FileId, res.data.DocumentSasToken, 'documents').then(res =>
                localStorage.removeItem('docs')
            )
        )

    }

    const handleDocumentError = (e) => {
        setDocumentError(e)
    }

    const handleDescriptionChange = (e) => {
        e.preventDefault();
        setDescriptionChangeMock(e.target.value)
        setDescriptionChange(e.target.value)
    }

    const handleTitleChange = (e) => {
        e.preventDefault();
        setTitleChangeMock(e.target.value)
        setTitleChange(e.target.value)
    }

    const handleCategoryChange = (e) => {
        e.preventDefault();
        setCategoryChange(e.target.value)
        setcategoryMock(e.target.value)
    }

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (desctiption.length > 0) {
                API.updateVideoData({
                    Id: videoId,
                    Description: desctiption
                })
            }
        }, 500);
        return () => clearTimeout(timer)
    }, [desctiption])

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (title.length > 0) {
                API.updateVideoData({
                    Id: videoId,
                    Title: title
                })
            }
        }, 500);
        return () => clearTimeout(timer)
    }, [title])


    // Style for Quiz box

    const quizStyle = {
        position: "absolute",
        top: "20vh",
        right: "0%",
        width: "38.95vw",
        height: "80vh",
        bgcolor: "#FFFFFF",
        border: "0",
        boxShadow: 24,
        overflow: "scroll",
        padding: "0 0 5vw 0"
    }

    const EditModelStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        height: "80vh",
        bgcolor: "#FFFFFF",
        border: "0",
        boxShadow: 24,
        overflow: "scroll",
        padding: "0"
    };


    const handleQuizUnlink = (e) => {
        setModalLoader(true)
        API.removeQuizFromPlaylist({
            quizId: e.data.Id,
            playlistId: selectedPlaylistId
        }).then(res => res.status === 200 && API.reArrangeCourse(selectedPlaylistId).then(res => {
            getAllDataFromApis(selectedPlaylistId)
            setModalLoader(false)
        }
        ))
    }
    const NewQuizLinkItem = (e) => {
        return (
            <ListItem disablePadding className="newQuizLinkItem justify-content-between">
                {
                    e.data.Type === courseContentTypes.Video
                        ?
                        <>
                            <div className="lms-flex-align-center ">
                                <div className="new-quiz-ver-bars"><img src="../assets/vertical-bars.png" alt="lms-logo" /></div>
                                <div className="new-quiz-thumb"><img src={e.data.ThumbnailUrl} /></div>
                                <div className="name-the-course"><h2>{e.data.Title}</h2></div>
                            </div>
                            <div className="lms-flex-align-center">
                                {/* <div className="new-quiz-edit-btn">
                                    <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                            <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg" />
                                        </svg>
                                </div> */}
                                {/* <div>
                                    <svg className='quiz-del-svg' width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17 4.76043C14.04 4.4671 11.0622 4.31599 8.09333 4.31599C6.33333 4.31599 4.57333 4.40488 2.81333 4.58266L1 4.76043M5.88889 3.86266L6.08444 2.69821C6.22667 1.85377 6.33333 1.22266 7.83556 1.22266H10.1644C11.6667 1.22266 11.7822 1.88932 11.9156 2.7071L12.1111 3.86266M15.0889 7.56932L14.5111 16.5204C14.4133 17.916 14.3333 19.0004 11.8533 19.0004H6.14667C3.66667 19.0004 3.58667 17.916 3.48889 16.5204L2.91111 7.56932M7.51556 14.1115H10.4756M6.77778 10.556H11.2222" className="del-stroke" />
                                    </svg>
                                </div> */}
                            </div>
                        </>
                        :
                        <>
                            <>
                                <div className="lms-flex-align-center ">
                                    <div className="new-quiz-ver-bars"><img src="../assets/vertical-bars.png" alt="lms-logo" /></div>
                                    <div className="document-icon-con">
                                        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 -4.3869e-05H20V25H0V-4.3869e-05ZM2.08777 5.72881H14.0596V6.24965H2.08777V5.72881ZM2.08777 12.2395H14.0596V12.7604H2.08777V12.2395ZM2.08777 18.7503H14.0596V19.2711H2.08777V18.7503ZM15.7846 4.66495H17.9123C18.0561 4.66495 18.1727 4.78156 18.1727 4.92537V7.05303C18.1727 7.19684 18.0561 7.31345 17.9123 7.31345H15.7846C15.6408 7.31345 15.5242 7.19684 15.5242 7.05303V4.92537C15.5242 4.78156 15.6408 4.66495 15.7846 4.66495ZM15.7846 11.1757H17.9123C18.0561 11.1757 18.1727 11.2923 18.1727 11.4361V13.5638C18.1727 13.7076 18.0561 13.8242 17.9123 13.8242H15.7846C15.6408 13.8242 15.5242 13.7076 15.5242 13.5638V11.4361C15.5242 11.2923 15.6408 11.1757 15.7846 11.1757ZM15.7846 17.6864H17.9123C18.0561 17.6864 18.1727 17.803 18.1727 17.9468V20.0745C18.1727 20.2183 18.0561 20.3349 17.9123 20.3349H15.7846C15.6408 20.3349 15.5242 20.2183 15.5242 20.0745V17.9468C15.5242 17.803 15.6408 17.6864 15.7846 17.6864Z" fill="#819ABB" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4324 5.6311L16 6.06348L16.4442 6.50767L16.6603 6.72388L16.8765 6.50767L17.6169 5.76733L17.1846 5.33496L16.6604 5.85913L16.4324 5.6311Z" fill="#819ABB" />
                                        </svg>
                                    </div>
                                    <div className="name-the-course"><h2>{e.data.Title}</h2></div>
                                </div>

                                <div className="lms-flex-align-center">
                                    {/* <div className="new-quiz-edit-btn">
                                        <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                            <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg" />
                                        </svg>
                                    </div> */}
                                    <div onClick={() => handleQuizUnlink(e)}>
                                        <svg className='quiz-del-svg' width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 4.76043C14.04 4.4671 11.0622 4.31599 8.09333 4.31599C6.33333 4.31599 4.57333 4.40488 2.81333 4.58266L1 4.76043M5.88889 3.86266L6.08444 2.69821C6.22667 1.85377 6.33333 1.22266 7.83556 1.22266H10.1644C11.6667 1.22266 11.7822 1.88932 11.9156 2.7071L12.1111 3.86266M15.0889 7.56932L14.5111 16.5204C14.4133 17.916 14.3333 19.0004 11.8533 19.0004H6.14667C3.66667 19.0004 3.58667 17.916 3.48889 16.5204L2.91111 7.56932M7.51556 14.1115H10.4756M6.77778 10.556H11.2222" className="del-stroke" />
                                        </svg>
                                    </div>
                                </div>
                            </>
                        </>
                }

            </ListItem>
        )
    }

    const VideoListItem = (e) => {
        return (
            <ListItem disablePadding className="course-edit d-flex" key={e.index} >
                {
                    e.data.Type === courseContentTypes.Video ?
                        <>
                            <div style={{ width: "100%" }} className='d-flex align-items-center justify-content-between'>
                                <div className="lms-flex-align-center " style={{ width: "100%" }}>
                                    <div className="new-quiz-ver-bars"><img src="../assets/vertical-bars.png" alt="lms-logo" /></div>
                                    <div className="new-quiz-thumb"><img src={e.data.ThumbnailUrl} /></div>
                                    <div className="name-the-course" style={{ width: "100%" }}>
                                        {dropdown === e.index && openDropdown ?
                                            <input type="text" className={`course-edit-inp`} value={titleMock} onChange={(i) => handleTitleChange(i)} />
                                            :
                                            <input type="text" readOnly className={`course-edit-inp border-unActive`} value={e.data.Title} />
                                        }
                                    </div>
                                </div>
                                <div className="lms-flex-align-center">
                                    {/* <div className="new-quiz-edit-btn" onClick={(event) => handleCollapse(event, e)}>
                                        <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                            <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg" />
                                        </svg>
                                    </div> */}
                                    <div>
                                        <svg className='quiz-del-svg' width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(event) => handleDelete(event, e)}>
                                            <path d="M17 4.76043C14.04 4.4671 11.0622 4.31599 8.09333 4.31599C6.33333 4.31599 4.57333 4.40488 2.81333 4.58266L1 4.76043M5.88889 3.86266L6.08444 2.69821C6.22667 1.85377 6.33333 1.22266 7.83556 1.22266H10.1644C11.6667 1.22266 11.7822 1.88932 11.9156 2.7071L12.1111 3.86266M15.0889 7.56932L14.5111 16.5204C14.4133 17.916 14.3333 19.0004 11.8533 19.0004H6.14667C3.66667 19.0004 3.58667 17.916 3.48889 16.5204L2.91111 7.56932M7.51556 14.1115H10.4756M6.77778 10.556H11.2222" className="del-stroke" />
                                        </svg>
                                    </div>
                                </div></div>
                            <div>
                                <Collapse in={dropdown === e.index && openDropdown} className='collapsed-con' key={e.index} unmountOnExit>
                                    <div className='course-edit-text-area'>
                                        <h4>{Description_Label}</h4>
                                        <textarea
                                            key={e.index}
                                            id="video-descripation"
                                            rows="20"
                                            cols="50"
                                            form="usrform"
                                            placeholder={Add_Description}
                                            name="description"
                                            value={desctiptionMock}
                                            onChange={(i) => handleDescriptionChange(i)}
                                        ></textarea>
                                    </div>
                                    <div className='course-edit-drag'>
                                        <h4>{Documents_Label}</h4>
                                        <DraganddropCourse
                                            VideoId={e.data.VideoId}
                                            getfiles={handleDocument}
                                            error={handleDocumentError}
                                            document={handleDocument}
                                        />
                                        {documentError.length > 0 && <h6 style={{ color: "red" }}>{documentError}</h6>}
                                        {/* <DraganddropCourse /> */}
                                    </div>
                                </Collapse>
                            </div>
                        </>
                        :
                        <>
                            <div className='d-flex justify-content-between'>
                                <div className="lms-flex-align-center">
                                    <div className="new-quiz-ver-bars"><img src="../assets/vertical-bars.png" alt="lms-logo" /></div>
                                    <div className="document-icon-con">
                                        <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 -4.3869e-05H20V25H0V-4.3869e-05ZM2.08777 5.72881H14.0596V6.24965H2.08777V5.72881ZM2.08777 12.2395H14.0596V12.7604H2.08777V12.2395ZM2.08777 18.7503H14.0596V19.2711H2.08777V18.7503ZM15.7846 4.66495H17.9123C18.0561 4.66495 18.1727 4.78156 18.1727 4.92537V7.05303C18.1727 7.19684 18.0561 7.31345 17.9123 7.31345H15.7846C15.6408 7.31345 15.5242 7.19684 15.5242 7.05303V4.92537C15.5242 4.78156 15.6408 4.66495 15.7846 4.66495ZM15.7846 11.1757H17.9123C18.0561 11.1757 18.1727 11.2923 18.1727 11.4361V13.5638C18.1727 13.7076 18.0561 13.8242 17.9123 13.8242H15.7846C15.6408 13.8242 15.5242 13.7076 15.5242 13.5638V11.4361C15.5242 11.2923 15.6408 11.1757 15.7846 11.1757ZM15.7846 17.6864H17.9123C18.0561 17.6864 18.1727 17.803 18.1727 17.9468V20.0745C18.1727 20.2183 18.0561 20.3349 17.9123 20.3349H15.7846C15.6408 20.3349 15.5242 20.2183 15.5242 20.0745V17.9468C15.5242 17.803 15.6408 17.6864 15.7846 17.6864Z" fill="#819ABB" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4324 5.6311L16 6.06348L16.4442 6.50767L16.6603 6.72388L16.8765 6.50767L17.6169 5.76733L17.1846 5.33496L16.6604 5.85913L16.4324 5.6311Z" fill="#819ABB" />
                                        </svg>
                                    </div>
                                    <div className="name-the-course"><h2>{e.data.Title}</h2></div>
                                </div>

                                <div className="lms-flex-align-center">
                                    {/* <div className="new-quiz-edit-btn">
                                        <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                            <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg" />
                                        </svg>
                                    </div> */}
                                    <div>
                                        <svg className='quiz-del-svg' width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={(event) => handleDelete(event, e)}>
                                            <path d="M17 4.76043C14.04 4.4671 11.0622 4.31599 8.09333 4.31599C6.33333 4.31599 4.57333 4.40488 2.81333 4.58266L1 4.76043M5.88889 3.86266L6.08444 2.69821C6.22667 1.85377 6.33333 1.22266 7.83556 1.22266H10.1644C11.6667 1.22266 11.7822 1.88932 11.9156 2.7071L12.1111 3.86266M15.0889 7.56932L14.5111 16.5204C14.4133 17.916 14.3333 19.0004 11.8533 19.0004H6.14667C3.66667 19.0004 3.58667 17.916 3.48889 16.5204L2.91111 7.56932M7.51556 14.1115H10.4756M6.77778 10.556H11.2222" className="del-stroke" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </>

                }

            </ListItem>
        )
    }

    const handleVideoSearch = (e) => {
        setvideoSearch(e)
    }

    const handleClickSuggetion = (e, index, type) => {
        setSelectedQuiz(e)
        // type === courseContentTypes.Quiz && setQuizSearch(e.Title)
    }

    const fetchCourseDetails = (id) => {
        UserAPI.getCourseDetails(id).then(res => res.status === 200 && (setVideoList(res.data), setVideoListMock(res.data))).finally(() => setLocalLoading(false))
    }

    const addQuizToCourse = () => {
        if (selectedQuizList.length > 0) {
            setModalLoader(true);

            const promises = selectedQuizList.map((quiz, index) =>
                API.assignQuiztoPlaylist({
                    quizId: quiz.Id,
                    playlistId: parseInt(selectedPlaylistId),
                    indexSequence: videoList.length + index + 1
                })
            );

            Promise.all(promises)
                .then(async responses => {
                    const allSuccessful = responses.every(res => res.status === 200);
                    if (allSuccessful) {
                        await getAllDataFromApis(selectedPlaylistId);
                    } else {
                        throw new Error('Some quizzes could not be assigned.');
                    }
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    setModalLoader(false);
                    clearSearch();
                    setSelectedQuizList([])
                });
        }
    };

    const handleToggleQuiz = (quizItem) => {
        setSelectedQuizList((prev) => {
            if (prev.includes(quizItem)) {
                return prev.filter(item => item !== quizItem);
            } else {
                return [...prev, quizItem];
            }
        });
    };

    const handleAddVideoToPlaylist = (e) => {
        setModalLoader(true)
        API.addVideoToPlaylist({
            PlaylistId: courseId,
            VideoId: selectedQuiz.Id
        }).then(res => {
            if (res.status == 200 && res.status) {
                UserAPI.fetchCoursesDetail(courseId)
                    .then(res => {
                        if (res.status === 200) {
                            var course = res.data.Course
                            setCourseType(courseType)
                            setCourseName(course.CourseName)
                            setCourseDescription(course.Description)
                            setSelectedCategory({
                                Id: course.PlaylistCategoryId,
                                Category: course.CourseCategory
                            })
                            setVideoList([...res.data.Videos, ...res.data.Quiz])
                            setVideoListMock([...res.data.Videos, ...res.data.Quiz])
                            setThumbnailPreview(course.ThumbnailUrl)
                            setSelectedQuiz({})
                            setQuizSearch('')
                            setvideoSearch('')
                        }
                    })
            }
        }).finally(res => {
            setModalLoader(false)
            clearSearch()
        })
    }

    const clearSearch = () => {
        setSuggestionsActive(false)
        setSuggestions([])
        setQuizSearch('')
        setvideoSearch('')
    }

    const Suggestions = (type) => {
        return (
            <div className='collection-suggestions'>
                <ul className="suggestions_ul">
                    {
                        suggestions.length > 0 ? suggestions.map((suggestion, index) => {
                            let isQuizValid = true;
                            if (type === courseContentTypes.Quiz) {
                                isQuizValid = suggestion.QuizLevels.filter(a => a.AssignedQuestions < a.NumberOfQuestions).length > 0;
                            }
                            const isDisabled = suggestion.isDisabled;

                            return (
                                <Tooltip
                                    key={suggestion.Id}
                                    title={isDisabled ? "Not enough questions in quiz" : ""}
                                    arrow
                                    placement="top"
                                >
                                    <span>
                                        <ListItem
                                            disableGutters
                                            className={index === suggestionIndex ? "suggestions_active" : "suggestions"}
                                            onClick={() => {
                                                if (!isDisabled) {
                                                    handleClickSuggetion(suggestion, index, type)
                                                }
                                            }}
                                            style={{
                                                cursor: !isDisabled ? 'pointer' : 'not-allowed',
                                                opacity: !isDisabled ? 1 : 0.5
                                            }}
                                        >
                                            <div className={`user-inf lms-flex`}>
                                                <div className="share-user-inf">
                                                    <ListItemText className='list-text' primary={`${suggestion.Title}`} />
                                                </div>
                                            </div>
                                        </ListItem>
                                    </span>
                                </Tooltip>
                            );
                        })
                            :
                            <p>{No_Data_available}</p>
                    }
                </ul>
            </div>
        );
    };

    const handleVideoUpdate = async () => {
        const thumbnailUrl = videoData.ThumbnailUrl;
        const lastStringWithoutPng = thumbnailUrl?.substring(thumbnailUrl.lastIndexOf('/') + 1, thumbnailUrl?.lastIndexOf('.'));

        setLocalLoading(true);

        try {
            const res = await API.updateVideoData({   
                updateVideoData:[
                    {
                        Id: videoId,
                        Name: videoTitleChange?.trim(),
                        Title: videoTitleChange?.trim(),
                        CategoryId: parseInt(videoCategoryChange.Id),
                        Description: videoDescriptionChange?.trim(),
                        Transcript: videoTranscriptChange?.trim(),
                        Points: videoPointsChange,
                        Tags: videoTagsChange
                    }
                  ]
            });

            if (res.status === 201) {
                if (thumbnail instanceof File) {
                    await Uploadwithsas(thumbnail, lastStringWithoutPng, videoData.ThumbnailSasToken, thumbnailPath);
                }
                
                if (Object.keys(videoDocumentsResponce).length > 0) {
                    await Promise.all(videoDocumentsResponce.map((document, index) => {
                        console.log(updatedDocumentData[index].Title, updatedDocumentData[index].FileId, document.FileId);
                        return Uploadwithsas(updatedDocumentData[index], document.FileId, document.DocumentSasToken, documentPath)
                    }
                    ));
                    await API.updateDocument(updatedDocumentData);
                }
                await props.fetchAdminVideoAction({ pageNumber: 1 });
            }
            setAlert({ active: true, severity: 'success', message: 'Video updated successfully' })
        } catch (error) {
            setAlert({ active: true, severity: 'error', message: 'An error occurred while uploading the video.' })
            console.error("Error updating video:", error);
        } finally {
            setLocalLoading(false);
            handleClose();
        }
    };


    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (initcategory !== category && category > 0) {
                API.updateCourseData({
                    Id: videoId,
                    Title: playlistTitle,
                    CategoryId: category
                }).then(res => {
                    var pageNumber = 1 /** Page Number is 1  */
                    props.fetchAdminCourseAction(pageNumber)
                })
            }
        }, 500);
        return () => clearTimeout(timer)
    }, [category])

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (playlistTitleChange !== playlistTitle) {
                API.updateCourseData({
                    Id: videoId,
                    Title: playlistTitleChange,
                    CategoryId: category
                }).then(red => {
                    var pageNumber = 1 /** Page Number is 1  */
                    props.fetchAdminCourseAction(pageNumber)
                })
            }
        }, 500);
        return () => clearTimeout(timer)
    }, [playlistTitleChange])

    React.useEffect(() => {
        const fetchQuizzes = () => {
            setQuizLoader(true);
            API.getAllQuizes({
                FilterField: "Title",
                FilterText: quizSearch.trim()
            })
                .then(res => {
                    if (res.status === 200) {
                        let quizzes = res.data.Quiz;
                        if (quizSearch.length > 0) {
                            quizzes = quizzes.map(q => ({
                                ...q,
                                isDisabled: q.QuizLevels.every(level => level.AssignedQuestions === 0),
                            }));
                        }

                        const newQuizzes = quizzes.filter(
                            q => !videoListMock.some(mockQuiz => mockQuiz.Id === q.Id)
                        );

                        setAllQuiz(newQuizzes);
                    } else {
                        setAllQuiz([]);
                    }
                })
                .catch(() => setAllQuiz([]))
                .finally(() => {
                    setModalLoader(false);
                    setQuizLoader(false);
                });
        };

        const debouncedFetch = debounce(fetchQuizzes, 300);
        debouncedFetch();

        return () => debouncedFetch.cancel();
    }, [quizSearch]);

    React.useEffect(() => {
        props.fetctQuestionLevel()
    }, [props.fetctQuestionLevel])
    React.useEffect(() => {
        props.fetchAdminVideoAction({ pageNumber: 1 })
    }, [props.fetchAdminVideoAction])

    React.useEffect(() => {

        let existingVideos = videoListMock.filter(a => a.Type === courseContentTypes.Video).map(a => a.Id)
        const timer = setTimeout(() => {
            videoSearch.length > 0 ?
                UserAPI.fetchTutorialsFilter({
                    filterField: "Name",
                    filterText: videoSearch
                }).then(res => {
                    if (res.status === 200) {
                        var uniqueVideos = res.data.Videos.filter(a => !existingVideos.includes(a.Id))
                        setSuggestions(uniqueVideos)
                        setSuggestionsActive(true);
                    }
                })
                :
                setSuggestionsActive(false)
        }, 500);
        return () => clearTimeout(timer)
    }, [videoSearch])

    React.useEffect(() => {
        props.data.length > 0 ?
            setSelectedCollection(
                props.data.map(d => {
                    return {
                        select: false,
                        Id: d.Id,
                        Name: d.Name,
                        Title: d.Title,
                        ThumbnailUrl: d.ThumbnailUrl,
                        CdnVideoUrl: d.CdnVideoUrl,
                        CategoryName: d.CategoryName,
                        FileName: d.Name,
                        Description: d.Description,
                        Tags: d.Tags,
                        CreatedByName: d.CreatedByName,
                        ProfileImageUrl: d.CreatedByProfilePicture,
                        ContentCount: d.VideoSessionCount,
                        QuizCount: d.CourseType === CourseType.Online ? 'NA' : d.QuizCount,
                        CourseType: d.CourseType,
                        CreatedDate: d.CreatedDate
                    }
                })
            )
            :
            setSelectedCollection([])
    }, [props.data])

    React.useEffect(() => {
    }, [selectedCollection])

    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        var updatedList = [...videoQuizMockList];
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);

        updatedList.forEach((i, index) => {
            i.index = index + 1
            var reorderModel = {
                Id: i.Id,
                PlaylistId: courseId ? courseId : selectedPlaylistId,
                Type: i.Type,
                Index: i.index,
            }
            API.reOrderCourse(reorderModel)
        }
        )
        setVideoQuizMockList(updatedList)
    };


    function handleBreadcomClick(playlistId) {
        window.scrollTo({ top: 0, left: 0 });
        setLocalLoader(true)
        setCourseId(playlistId)
        setBreadcrumbsActive(true);
        getPlaylistDetails(playlistId).then(() => setLocalLoader(false))
    }

    React.useEffect(() => {
        props.isInDetailPage(IsBreadcrumbsActive)
    }, [IsBreadcrumbsActive])


    function getPlaylistDetails(playlistId) {
        return UserAPI.fetchCoursesDetail(playlistId).then(res => {
            if (res.status === 200) {
                const _sessions = (res.data.Sessions || []).sort((a, b) => new Date(a.StartTime) - new Date(b.StartTime));
                setCourseDetails({
                    Course: res.data.Course,
                    // Sessions: res.data.Sessions,
                    Sessions: _sessions,
                    Videos: res.data.Videos,
                    RelatedCourse: res.data.RelatedCourse,
                    Quizes: res.data.Quiz
                })
                setVideoQuizMockList([...res.data.Videos, ...res.data.Quiz])
                const allDocuments = res.data.Videos.length > 0
                    ? res.data.Videos.flatMap(item => item.Documents)
                    : res.data.Sessions.flatMap(item => item.Documents);
                setDocumentsList(allDocuments);
            }
        })
    }

    React.useEffect(() => {
        if ((isAddSessions && !editSessionOnList) || !isSesionsListActive) {
            setSessionDate(new Date().toLocaleDateString('en-CA'))
            setSessionStartTime(moment().add(15, 'minutes').format('HH:mm'))
            setSessionEndTime(moment().add(1, 'hour').add(15, 'minutes').format('HH:mm'))
        }
    }, [isAddSessions, isSesionsListActive])

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAddSession = () => {
        setAddSession(true)
    }

    const handleEditSession = (session) => {
        setEditSessionName(session.Title)
        setEditSessionDescription(session.Description)
        setEditSessionThumbnailPreview(session.ThumbnailUrl)
        setEditSessionType(session.Status)
        setEditSessionDate(session.Date)
        setEditSessionStartTime(session.StartTime)
        setEditSessionEndTime(session.EndTime)
        setEditSessionId(session.Id)
        setModalLoader(true)
        API.getSessionDocument({
            sessionId: session.Id
        }).then(res => {
            if (res.status === 200) {
                setEditSessionDocumnet(res.data.map(d => ({
                    Id: d.Id,
                    name: d.Title,
                    lastModifiedDate: d.CreatedDate
                })))
            }
        }).then(() =>
            setModalLoader(false)
        )
        setEditSession(true);
    }

    const handleCollapseClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleSaveAndNext = () => {
        setSetionsList(true)

    }


    const handleAddSessionToList = () => {
        setEditSessionOnList(false)
        var regExp = /(\d{1,2})\:(\d{1,2})/;
        var startDate = sessionStartTime
        var endDate = sessionEndTime
        if (sessionName.trim().length > 0 &&
            sessionName.trim().length <= validationLimits.sessionNameMax &&
            sessionDescription.trim().length <= validationLimits.sessionDescriptionMax &&
            sessionThumbnail.length > 0 &&
            sessionDate.length > 0 &&
            sessionStartTime.length > 0 &&
            sessionEndTime.length > 0 &&
            startDate > currentTime() &&
            endDate > currentTime() &&
            parseInt(endDate.replace(regExp, "$1$2")) > parseInt(startDate.replace(regExp, "$1$2"))) {
            setSetionsList(true)
            var model = {
                Title: sessionName.trim(),
                Description: sessionDescription.trim(),
                Date: new Date(sessionDate).toISOString(),
                StartTime: moment(new Date(sessionDate + " " + sessionStartTime.trim())).format(),
                EndTime: moment(new Date(sessionDate + "T" + sessionEndTime.trim())).format(),
                Document: sessionDocumnet,
                Thumbnail: sessionThumbnail,
                ThumbnailPreview: sessionThumbnailPreview,
            }
            setSessions(prev => [...prev, model])
        }
        else {
            if (sessionName.trim().length === 0 || sessionName.trim().length > validationLimits.sessionNameMax) {
                setSessionNameError(true)
            }
            if (sessionDescription.trim() > validationLimits.sessionDescriptionMax) {
                setSessionDescriptionError(true)
            }
            if (sessionThumbnail.length === 0) {
                setSessionThumbnailError(true)
            }
            if (startDate < currentTime() || endDate < currentTime()) {
                setSessionTimeError(true)
            }
            if (parseInt(endDate.replace(regExp, "$1$2")) < parseInt(startDate.replace(regExp, "$1$2"))) {
                setSessionDateError(true)
            }
        }

    }

    const handleEditSessionList = () => {
        setEditSessionOnList(false)
        var regExp = /(\d{1,2})\:(\d{1,2})/;
        var startDate = sessionStartTime
        var endDate = sessionEndTime
        if (sessionName.trim().length > 0 &&
            sessionDescription.trim().length <= validationLimits.courseDescriptionMax &&
            sessionThumbnail.length > 0 &&
            sessionDate.length > 0 &&
            sessionStartTime.length > 0 &&
            sessionEndTime.length > 0 &&
            startDate > currentTime() &&
            endDate > currentTime() &&
            parseInt(endDate.replace(regExp, "$1$2")) > parseInt(startDate.replace(regExp, "$1$2"))) {
            setSetionsList(true)
            var model = {
                Title: sessionName.trim(),
                Description: sessionDescription.trim(),
                Date: new Date(sessionDate).toISOString(),
                StartTime: moment(new Date(sessionDate + " " + sessionStartTime.trim())).format(),
                EndTime: moment(new Date(sessionDate + " " + sessionEndTime.trim())).format(),
                Document: sessionDocumnet,
                Thumbnail: sessionThumbnail,
                ThumbnailPreview: sessionThumbnailPreview,
            }
            sessions[sessionIndex] = model
        }
        else {
            if (sessionName.trim().length === 0 || sessionName.trim().length > validationLimits.sessionNameMax) {
                setSessionNameError(true)
            }
            if (sessionDescription.trim().length > validationLimits.sessionDescriptionMax) {
                setSessionDescriptionError(true)
            }
            if (startDate < currentTime() || endDate < currentTime()) {
                setSessionTimeError(true)
            }
            if (parseInt(endDate.replace(regExp, "$1$2")) < parseInt(startDate.replace(regExp, "$1$2"))) {
                setSessionDateError(true)
            }
        }
    }

    const handleRemoveOrCancelSession = () => {
        API.revomeSessions(sessionId).then(res => {
            if (res.status === 204) {
                handleBreadcomClick(courseId)
            }
        }).then(() => resetDialog())
    }

    const handleEditSessionOnList = (data, idx) => {
        setSessionIndex(idx)
        setSessionName(data.Title)
        setSessionDescription(data.Description)
        setSessionDate(data.Date.split(' ')[0])
        setSessionDocumnet(data.Document)
        setSessionThumbnail(data.Thumbnail)
        setSessionThumbnailPreview(data.ThumbnailPreview)
        setSessionStartTime(moment(data.StartTime).format('HH:mm'))
        setSessionEndTime(moment(data.EndTime).format('HH:mm'))
        setSetionsList(false)
        setEditSessionOnList(true)
    }

    const handleRemoveSessionOnList = (index) => {
        var updatedList = [...sessions];
        updatedList.splice(index, 1)
        setSessions(updatedList)
    }

    React.useEffect(() => {
        if (isSesionsListActive && sessions.length === 0) {
            setSetionsList(false)
            handleReset()
        }
    }, [sessions])


    // const tabClassName = `admin-analytics-tab assesment-tabs ${document.body.dir === "ltr" ? "assess-right-padd" : "assess-left-padd"}`;


    const tabClassName = "admin-analytics-tab assesment-tabs assess-right-padd";


    const handleDocumentUpload = (event) => {
        setSessionDocumnet((prevState) => {
            const newDocs = [...prevState, ...event];
            return newDocs.slice(0, 5);
        });
    }

    React.useEffect(() => {
        const hasLongTitle = sessionDocumnet.some(doc => doc.name.trim().length > 25);
        setSessionDocumnetError(hasLongTitle);
    }, [sessionDocumnet]);
    
    const longTitleDocs = sessionDocumnet
        .filter(doc => doc.name.trim().length > 25) 
        .map(doc => doc.name);
    const longDocsTitleDocs = editSessionDocumnet
        .filter(doc => doc.name.trim().length > 25) 
        .map(doc => doc.name);

    const handleEditDocumentUpload = (event) => {

        const fileArray = Array.from(event);

        const newFiles = fileArray.filter(
            (file) => !editSessionDocumnet.some((doc) => doc.name === file.name)
        );

        if (newFiles.length === 0) {
            console.warn("No new files to add.");
            return;
        }

        setEditSessionDocumnetUpload((prevState) => {
            const updatedDocs = [...prevState, ...newFiles];
            return updatedDocs.slice(0, 5);
        });

        setEditSessionDocumnet((prevState) => {
            const updatedDocs = [...prevState, ...newFiles];
            return updatedDocs.slice(0, 5);
        });
    };
        
        


    const handleDocumnetOpen = () => {
        documentRef.current?.click()
    }
    const handleDocumentDelete = (fileNameToRemove, id) => { 
    
        id && API.deleteWebnarDocument(id);
    
        setSessionDocumnet((prevState) =>
            prevState.filter((file) => file.name !== fileNameToRemove)
        );
        
        setEditSessionDocumnetUpload((prevState) =>
            prevState.filter((file) => file.name !== fileNameToRemove)
        );
    
        setEditSessionDocumnet((prevState) =>
            prevState.filter((file) => file.name !== fileNameToRemove)
        );
    };
    
    const handleDocumentRemove = (indexToRemove, id) => {
        if (videoDocumnetChange.length > 5) {
            setDocumentLength(true)
        }
        else{
            setDocumentLength(false)
        }
        setVideoDocumnetChange((prevState) =>
            prevState.filter((_, index) => index !== indexToRemove)
        );
        setUpdatedDocumentData((prevState) =>
            prevState.filter((_, index) => index !== indexToRemove)
        );

        id && API.deleteDocument(id)
    }

    const handleSessionThumbnail = (event) => {
        setSessionThumbnail(event)
        const objectUrl = URL.createObjectURL(event[0])
        setSessionThumbnailPreview(objectUrl)
        setSessionThumbnailError(false)
    }

    const handleEditSessionThumbnail = (event) => {
        setEditSessionThumbnail(event)
        const objectUrl = URL.createObjectURL(event[0])
        setEditSessionThumbnailPreview(objectUrl)
        setSessionThumbnailError(false)
    }

    const handleSessionThumbnailClick = () => {
        sessionThumbnailRef.current?.click()
    }

    const handleVideoUploadOpen = () => {
        sessionVideoUploadRef.current?.click()
    }

    const handleVideoUploadClick = (event) => {
        setSessionVideoUpload(event)
        const objectUrl = URL.createObjectURL(event[0])
        setSessionVideoUploadData({
            Thumbnail: captureThumbnail(objectUrl),
            Name: event[0].name,
            Size: sizeConvertor(event[0].size)
        })
    }

    const handleReset = () => {
        setSessionName('')
        setSessionDescription('')
        setSessionDate('')
        setSessionStartTime('')
        setSessionEndTime('')
        setSessionDocumnet([])
        setSessionThumbnail([])
        setSessionThumbnailPreview('')
        setSessionDate(new Date().toLocaleDateString('en-CA'))
        setSessionStartTime(moment().add(15, 'minutes').format('HH:mm'))
        setSessionEndTime(moment().add(1, 'hour').add(15, 'minutes').format('HH:mm'))
    }

    const handleCategorySelectChange = (event) => {
        setSelectedCategory({
            Id: event.target.value.Id,
            Category: event.target.value.PlaylistCategoryName
        });
        setCourseCateogryError(false)
    };

    const handleCourseName = (event) => {
        if (event.length <= validationLimits.courseNameMax) {
            setCourseName(event)
            setCourseNameError(false)
        }
        else {
            setCourseNameError(true)
        }
    }

    const handleCourseDescription = (event) => {
        if (event.length <= validationLimits.sessionDescriptionMax) {
            setCourseDescription(event)
            setCourseDescriptionError(false)
        }
        else {
            setCourseDescriptionError(true)
        }
    }

    const handleThumbnail = (event) => {
        setThumbnail(event[0])
        const objectUrl = URL.createObjectURL(event[0])
        setThumbnailPreview(objectUrl)
        setThumbnailError(false)
    }

    const handleThumbnailClick = () => {
        thumbnailFile.current?.click()
    }

    const handleEditLiveCourse = () => {
        if (courseName.trim().length > 0 && courseName.trim().length <= validationLimits.courseNameMax && courseDescription.trim().length <= validationLimits.sessionDescriptionMax && selectedCategory.Id !== null) {
            setModalLoader(true)
            const formData = new FormData();
            formData.append('Id', courseId);
            formData.append('Title', courseName.trim());
            formData.append('Description', courseDescription.trim());
            formData.append('CategoryId', selectedCategory.Id);
            formData.append('Tags', '');
            formData.append('filecontent', thumbnail && thumbnail);
            formData.append('CourseType', CourseType.Online);

            API.updateCourseData(formData)
                .then(async (res) => {
                    if (res.status === 201) {
                        var pageNumber = 1
                        await props.fetchAdminCourseAction(pageNumber)
                        await getPlaylistDetails(courseId)
                        setAlert({ active: true, severity: 'success', message: 'Course updated successfully' })
                    }
                })
                .then(() => {
                    setModalLoader(false)
                    handleClose()
                })
        }
        else {
            if (courseName.trim().length === 0 || courseName.trim().length > validationLimits.courseNameMax) {
                setCourseNameError(true)
            }
            if (courseDescription.trim().length > validationLimits.courseDescriptionMax) {
                setCourseDescriptionError(true)
            }
            if (selectedCategory.Id === null) {
                setCourseCateogryError(true)
            }
        }
    }

    const handleEditOfflineCourse = () => {
        if (courseName.trim().length > 0 && courseName.trim().length <= validationLimits.courseNameMax && courseDescription.trim().length <= validationLimits.courseDescriptionMax &&  selectedCategory.Id !== null) {
            setModalLoader(true)
            const formData = new FormData();
            formData.append('Id', courseId);
            formData.append('Title', courseName.trim());
            formData.append('Description', courseDescription.trim());
            formData.append('CategoryId', selectedCategory.Id);
            formData.append('CourseType', CourseType.Offline);
            formData.append('Tags', courseTags);
            formData.append('Points', coursePoints);
            thumbnail && thumbnail.length > 0 && formData.append('filecontent', thumbnail[0]);

            API.updateCourseData(formData)
                .then(async (res) => {
                    if (res.status === 201) {
                        var pageNumber = 1
                        await props.fetchAdminCourseAction(pageNumber)
                        await getPlaylistDetails(courseId)
                        setAlert({ active: true, severity: 'success', message: 'Course updated successfully' })
                    }
                })
                .then(() => {
                    setModalLoader(false)
                    handleClose()
                })
        }
        else {
            if (courseName.trim().length === 0 || courseName.trim().length > validationLimits.courseNameMax) {
                setCourseNameError(true)
            }
            if (courseDescription.trim().length > validationLimits.courseDescriptionMax) {
                setCourseDescriptionError(true)
            }
            if (selectedCategory.Id === null) {
                setCourseCateogryError(true)
            }
        }
    }

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            severity: '',
            message: ''
        })
    };

    React.useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);

    const handleCreateSessions = () => {
        setModalLoader(true)
        if (sessions.length > 0) {
            Promise.all(sessions.map(session => {
                const formData = new FormData();
                formData.append('Title', session.Title.trim());
                formData.append('Description', session.Description.trim());
                formData.append('Tags', '');
                formData.append('Date', session.Date);
                formData.append('StartTime', session.StartTime);
                formData.append('EndTime', session.EndTime);
                formData.append('PlaylistId', courseId);
                formData.append('Filecontent', session.Thumbnail[0]);
                return API.createSessions(formData)
                    .then(res => {
                        if (res.status === 200) {
                            let sessionId = res.data.SessionId
                            let duration = Math.round((new Date(session.EndTime) - new Date(session.StartTime)) / 60000)
                            let endTimeDuration = Math.round((new Date(session.EndTime) - new Date()) / 3600000)
                            let payload = sessionPayload(userMail, liveCourseProviders.VideoSdk, getSessionPayloadBody(liveCourseProviders.VideoSdk, duration, session.Title.replace(/\s/g, "_"), endTimeDuration))
                            return sessionCreateLogicAppRequest(payload).then((sessionRes) => {
                                if (sessionRes.status === 200) {
                                    let roomId = sessionRes.data.body.roomId
                                    let token = sessionRes.data.token
                                    var updateSessionForm = new FormData()
                                    updateSessionForm.append('SessionId', sessionId);
                                    updateSessionForm.append('Date', session.Date);
                                    updateSessionForm.append('StartTime', session.StartTime);
                                    updateSessionForm.append('EndTime', session.EndTime);
                                    updateSessionForm.append('WebinarAccessToken', token);
                                    updateSessionForm.append('HostLink', webinarJoinLinks.HostLink + roomId + "/" + userName.split(' ').join('_'));
                                    updateSessionForm.append('ParticipantLink', webinarJoinLinks.ParticipantLink + roomId + "/" + userName.split(' ').join('_'));
                                    API.updateSessions(updateSessionForm)
                                }
                            }).then(() => {
                                if (session.Document && session.Document.length > 0) {
                                    const documentPayload = session.Document.map((doc) => ({
                                        Title: doc.name
                                    }));
                                
                                    return API.createSessionDocument({
                                        SessionId: res.data.SessionId,
                                        Documents: documentPayload,
                                    })
                                        .then((docRes) => {
                                            if (docRes.status === 200) {
                                                const uploadPromises = session.Document.map((doc, index) =>
                                                    Uploadwithsas(
                                                        doc,
                                                        docRes.data.Documents[index].FileId,
                                                        docRes.data.Documents[index].DocumentSasToken,
                                                        webinarDocumentPath
                                                    ).then((sasRes) => {
                                                        if (sasRes.status === 201) {
                                                            documentPayload[index].FileId = docRes.data.Documents[index].FileId;
                                                        } else {
                                                            throw new Error(`Failed to upload document: ${doc.name}`);
                                                        }
                                                    })
                                                );
                                
                                                return Promise.all(uploadPromises).then(() => {
                                                    const updatePromises = documentPayload.map((doc, index) =>
                                                        API.updateSessionDocument({
                                                            Id: doc.FileId,
                                                            FileName: session.Document[index].name,
                                                            Extension: session.Document[index].name.split('.').pop(),
                                                            SessionId: res.data.SessionId,
                                                            Title: doc.Title,
                                                        })
                                                    );
                                
                                                    return Promise.all(updatePromises);
                                                });
                                            } else {
                                                throw new Error("Failed to create session documents.");
                                            }
                                        })
                                        .then(() => {
                                            console.log("All documents processed successfully.");
                                        })
                                        .catch((error) => {
                                            console.error("Error processing documents:", error);
                                        });
                                }
                                
                            })
                        }
                    }).then(async () => {
                        // let duration = Math.round((new Date(session.EndTime) - new Date(session.StartTime)) / 60000)
                        // let endTimeDuration = Math.round((new Date(session.EndTime) - new Date()) / 3600000)
                        // let payload = sessionPayload(userMail, liveCourseProviders.VideoSdk, getSessionPayloadBody(liveCourseProviders.VideoSdk, duration, session.Title.replace(/\s/g, "_"), endTimeDuration))
                        // sessionCreateLogicAppRequest(payload).then((res) => {
                        //     if (res.status === 200) {
                        //         let roomId = res.data.body.roomId
                        //         let token = res.data.token
                        //         var updateSessionForm = new FormData()
                        //         updateSessionForm.append('SessionId', sessionId);
                        //         updateSessionForm.append('Date', session.Date);
                        //         updateSessionForm.append('StartTime', session.StartTime);
                        //         updateSessionForm.append('EndTime', session.EndTime);
                        //         updateSessionForm.append('WebinarAccessToken', token);
                        //         updateSessionForm.append('HostLink', webinarJoinLinks.HostLink + roomId + userName.split(' ').join('_'));
                        //         updateSessionForm.append('ParticipantLink', webinarJoinLinks.ParticipantLink + roomId + userName.split(' ').join('_'));
                        //         API.updateSessions (updateSessionForm)                             
                        //     }
                        // })
                    })
            })).then(() => {
                setAlert({ active: true, severity: 'success', message: 'Session created successfully' })
                setModalLoader(false)
                handleClose()
                handleBreadcomClick(courseId)
            })
        }
        else {

        }
    }

    const handleEditUpcomingSession = () => {
        if (editSessionName.trim().length > 0 && editSessionName.trim().length <= validationLimits.sessionNameMax && editSessionDescription.trim().length <= validationLimits.sessionDescriptionMax && editSessionThumbnailPreview.length > 0) {
            setModalLoader(true)
            const formData = new FormData();
            formData.append('SessionId', editSessionId);
            formData.append('Title', editSessionName.trim());
            formData.append('Description', editSessionDescription.trim());
            formData.append('Tags', '');
            formData.append('Date', editSessionDate);
            formData.append('StartTime', editSessionStartTime);
            formData.append('EndTime', editSessionEndTime);
            formData.append('FileContent', editSessionThumbnail[0]);
            API.updateSessions(formData)
            .then((res) => {
                if (res?.status === 200) {
                    if (editSessionDocumnetUpload && editSessionDocumnetUpload.length > 0) {
                        const documentPayload = editSessionDocumnetUpload.map((doc) => ({
                            Title: doc.name,
                        }));
        
                        return API.createSessionDocument({
                            SessionId: editSessionId,
                            Documents: documentPayload,
                        })
                            .then((docRes) => {
                                if (docRes?.status === 200) {
                                    const uploadPromises = editSessionDocumnetUpload.map((doc, index) =>
                                        Uploadwithsas(
                                            doc,
                                            docRes.data[index]?.FileId,
                                            docRes.data[index]?.DocumentSasToken,
                                            webinarDocumentPath
                                        ).then((sasRes) => {
                                            if (sasRes?.status === 201) {
                                                documentPayload[index].FileId = docRes.data[index]?.FileId;
                                            } else {
                                                throw new Error(`Failed to upload document: ${doc.name}`);
                                            }
                                        })
                                    );
        
                                    return Promise.all(uploadPromises).then(() => {
                                        const updatePayload = documentPayload.map((doc, index) => ({
                                            Id: docRes.data[index]?.DocumentId,
                                            FileId: docRes.data[index]?.FileId,
                                            FileName: editSessionDocumnetUpload[index]?.name,
                                            Extension: editSessionDocumnetUpload[index]?.name.split('.').pop(),
                                            SessionId: editSessionId,
                                            Title: doc.Title,
                                        }));
        
                                        return API.updateSessionDocument(updatePayload);
                                    });
                                } else {
                                    throw new Error("Failed to create session documents.");
                                }
                            })
                            .then(() => {
                                console.log("Documents processed successfully.");
                                return res;
                            })
                            .catch((error) => {
                                console.error("Error processing documents:", error);
                                throw error;
                            });
                    }
                }
        
                return res;
            })
            .then((updRes) => {
                if (updRes?.status === 200) {
                    if (editSessionType === sessionStatus.Completed) {
                        setAlert({
                            active: true,
                            severity: 'info',
                            message: 'Session updated successfully. Video upload is in progress! It may take some time.',
                        });
        
                        Uploadwithsas(sessionVideoUpload[0], updRes.data?.FileId, updRes.data?.VideoSasToken, tempVideoPath)
                            .then((videoRes) => {
                                if (videoRes?.status === 201) {
                                    formData.append(
                                        'VideoGeneratedFileName',
                                        `${updRes.data?.FileId}.${sessionVideoUpload[0]?.name.split('.')[1]}`
                                    );
                                    return API.updateSessions(formData);
                                }
                            })
                            .catch((error) => {
                                console.error("Error uploading video:", error);
                            });
                    } else {
                        setAlert({
                            active: true,
                            severity: 'success',
                            message: 'Session updated successfully.',
                        });
                    }
                    setModalLoader(false);
                    handleClose();
                    handleBreadcomClick(courseId);
                }
            })
            .catch((error) => {
                console.error("Error in session update process:", error);
                setAlert({
                    active: true,
                    severity: 'error',
                    message: 'An error occurred while updating the session.',
                });
                setModalLoader(false);
            });
        }
        else {
            if (editSessionName.trim().length === 0 || editSessionName.trim().length > validationLimits.sessionNameMax) {
                setSessionNameError(true)
            }
            if (editSessionDescription.trim() > validationLimits.sessionDescriptionMax) {
                setSessionDescriptionError(true)
            }
            if (editSessionThumbnailPreview.length === 0) {
                setSessionThumbnailError(true)
            }
        }
    }

    const courseEditScreen = () => {
        if (courseType === CourseType.Online) {
            return (
                <>
                    <div className="quiz-creator-con">
                        <h3>Edit Live Course</h3>
                        <div><img src="../assets/close-black.png" alt="lms-logo" className="close-icon" onClick={handleClose} /></div>
                    </div>
                    <Divider className="mui-devider" />
                    <div className='edit-inp-con'>

                        <FormControl sx={{ width: "100% !important" }} className="vertical-form-controll mb-4">
                            {/* <input type="text" className='course-edit-inp' value={courseName || ''} style={{ fontWeight: "600" }} onChange={(i) => setPlaylistTitleChange(i.target.value)} />
                                                                     <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                         <path d="M2.77707 15.2732C2.26216 15.2732 1.78101 15.096 1.43492 14.7668C0.995984 14.3532 0.784955 13.7285 0.860925 13.0532L1.17325 10.3183C1.23234 9.80337 1.54466 9.11964 1.90763 8.74823L8.83782 1.41286C10.5683 -0.418868 12.3747 -0.469514 14.2064 1.26092C16.0381 2.99136 16.0888 4.79777 14.3583 6.6295L7.42815 13.9649C7.07362 14.3447 6.41521 14.6992 5.9003 14.7837L3.18224 15.2479C3.03874 15.2564 2.91213 15.2732 2.77707 15.2732ZM11.5474 1.25248C10.8975 1.25248 10.3319 1.65766 9.75791 2.26542L2.82771 9.60923C2.65889 9.78649 2.46475 10.2085 2.43098 10.4533L2.11866 13.1883C2.08489 13.4668 2.15242 13.6947 2.30436 13.8382C2.4563 13.9817 2.68422 14.0324 2.96277 13.9902L5.68083 13.5259C5.92562 13.4837 6.33079 13.2642 6.49962 13.087L13.4298 5.75162C14.4765 4.63739 14.8564 3.60756 13.3285 2.17257C12.6532 1.5226 12.0708 1.25248 11.5474 1.25248Z" fill="#999999" />
                                                                         <path d="M12.7377 8.03852H12.6786C11.3994 7.91305 10.1982 7.3655 9.26441 6.48215C8.33063 5.5988 7.7173 4.42986 7.52107 3.15953C7.47042 2.81344 7.70678 2.49268 8.05286 2.43359C8.39895 2.38294 8.71972 2.61929 8.7788 2.96538C8.93336 3.95861 9.41354 4.87233 10.1439 5.56296C10.8742 6.25358 11.8134 6.68196 12.8137 6.78078C13.1598 6.81455 13.413 7.12687 13.3792 7.47296C13.337 7.79372 13.0585 8.03852 12.7377 8.03852ZM15.8272 17.9999H0.633087C0.286999 17.9999 0 17.7129 0 17.3668C0 17.0208 0.286999 16.7338 0.633087 16.7338H15.8272C16.1733 16.7338 16.4603 17.0208 16.4603 17.3668C16.4603 17.7129 16.1733 17.9999 15.8272 17.9999Z" fill="#999999" />
                                                                     </svg> */}

                            <TextField
                                id="fullWidth"
                                label="Add Course heading"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end"
                                            sx={{ pointerEvents: "none", position: "absolute", right: 0 }}>
                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.77707 15.2732C2.26216 15.2732 1.78101 15.096 1.43492 14.7668C0.995984 14.3532 0.784955 13.7285 0.860925 13.0532L1.17325 10.3183C1.23234 9.80337 1.54466 9.11964 1.90763 8.74823L8.83782 1.41286C10.5683 -0.418868 12.3747 -0.469514 14.2064 1.26092C16.0381 2.99136 16.0888 4.79777 14.3583 6.6295L7.42815 13.9649C7.07362 14.3447 6.41521 14.6992 5.9003 14.7837L3.18224 15.2479C3.03874 15.2564 2.91213 15.2732 2.77707 15.2732ZM11.5474 1.25248C10.8975 1.25248 10.3319 1.65766 9.75791 2.26542L2.82771 9.60923C2.65889 9.78649 2.46475 10.2085 2.43098 10.4533L2.11866 13.1883C2.08489 13.4668 2.15242 13.6947 2.30436 13.8382C2.4563 13.9817 2.68422 14.0324 2.96277 13.9902L5.68083 13.5259C5.92562 13.4837 6.33079 13.2642 6.49962 13.087L13.4298 5.75162C14.4765 4.63739 14.8564 3.60756 13.3285 2.17257C12.6532 1.5226 12.0708 1.25248 11.5474 1.25248Z" fill="#999999" />
                                                <path d="M12.7377 8.03852H12.6786C11.3994 7.91305 10.1982 7.3655 9.26441 6.48215C8.33063 5.5988 7.7173 4.42986 7.52107 3.15953C7.47042 2.81344 7.70678 2.49268 8.05286 2.43359C8.39895 2.38294 8.71972 2.61929 8.7788 2.96538C8.93336 3.95861 9.41354 4.87233 10.1439 5.56296C10.8742 6.25358 11.8134 6.68196 12.8137 6.78078C13.1598 6.81455 13.413 7.12687 13.3792 7.47296C13.337 7.79372 13.0585 8.03852 12.7377 8.03852ZM15.8272 17.9999H0.633087C0.286999 17.9999 0 17.7129 0 17.3668C0 17.0208 0.286999 16.7338 0.633087 16.7338H15.8272C16.1733 16.7338 16.4603 17.0208 16.4603 17.3668C16.4603 17.7129 16.1733 17.9999 15.8272 17.9999Z" fill="#999999" />
                                            </svg>
                                        </InputAdornment>
                                    ),
                                }}
                                value={courseName}
                                variant="standard"
                                onChange={(e) => handleCourseName(e.target.value)}
                            />
                            {
                                courseNameError &&
                                <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                                    Course Heading should be between 1-50 characters
                                </FormHelperText>
                            }
                        </FormControl>
                    </div>
                    <input type='file' style={{ display: 'none' }}
                        accept="image/*"
                        ref={thumbnailFile}
                        onChange={(e) => handleThumbnail(e.target.files)} />
                    <div className="quiz-creator-con pt-3">
                        <div className="replace-image-con"><img src={thumbnailPreview} className="collection-thumb" /><button onClick={handleThumbnailClick}>Replace image</button></div>
                    </div>
                    <div className='catagory-con admin-course-select-option'>
                        <FormControl sx={{ width: "100% !important" }} className="vertical-form-controll mb-4">
                            <InputLabel id="multiple-checkbox-label" className="select-catagery">Select Category</InputLabel>
                            <Select
                                labelId="demo-multiple-checkbox-label"
                                id="demo-multiple-checkbox"
                                value={selectedCategory?.Category || "Select Category"}
                                onChange={handleCategorySelectChange}
                                input={<OutlinedInput label="Select Category" />}
                                renderValue={(selected) => selected?.PlaylistCategoryName || "Select Category"}
                                className="select-text select-option"
                                error={courseCategoryError}
                            >
                                {props.playlistCategories.map((category, index) => (
                                    <MenuItem key={index} value={category}>
                                        {category.PlaylistCategoryName}
                                    </MenuItem>
                                ))}
                            </Select>

                            {
                                courseCategoryError &&
                                <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                                    Please Course Category
                                </FormHelperText>
                            }
                        </FormControl>
                        <h4 className='mt-5'>About This Course</h4>
                        <FormControl sx={{ width: "100% !important" }} className="vertical-form-controll mb-4">
                            <textarea
                                ref={descriptionRef}
                                className={courseDescriptionError ? 'error' : 'normal'}
                                id="video-descripation"
                                rows="12"
                                cols="50"
                                form="usrform"
                                placeholder="Say About This Course"
                                name="description"
                                value={courseDescription}
                                onChange={(e) => handleCourseDescription(e.target.value)}
                            ></textarea>
                            {
                                courseDescriptionError &&
                                <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                                    Course Description should be between 1-1000 characters
                                </FormHelperText>
                            }
                        </FormControl>
                    </div>

                </>
            )
        }
        else {
            return (
                <>
                    <div className="quiz-creator-con">
                        <h3>{Course_Edit}</h3>
                        <div><img src="../assets/close-black.png" alt="lms-logo" className="close-icon" onClick={handleClose} /></div>
                    </div>
                    <div className='edit-inp-con'>
                        <input type="text" className='course-edit-inp' value={courseName || ''} onChange={(i) => setCourseName(i.target.value)} />
                        {/* <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.77707 15.2732C2.26216 15.2732 1.78101 15.096 1.43492 14.7668C0.995984 14.3532 0.784955 13.7285 0.860925 13.0532L1.17325 10.3183C1.23234 9.80337 1.54466 9.11964 1.90763 8.74823L8.83782 1.41286C10.5683 -0.418868 12.3747 -0.469514 14.2064 1.26092C16.0381 2.99136 16.0888 4.79777 14.3583 6.6295L7.42815 13.9649C7.07362 14.3447 6.41521 14.6992 5.9003 14.7837L3.18224 15.2479C3.03874 15.2564 2.91213 15.2732 2.77707 15.2732ZM11.5474 1.25248C10.8975 1.25248 10.3319 1.65766 9.75791 2.26542L2.82771 9.60923C2.65889 9.78649 2.46475 10.2085 2.43098 10.4533L2.11866 13.1883C2.08489 13.4668 2.15242 13.6947 2.30436 13.8382C2.4563 13.9817 2.68422 14.0324 2.96277 13.9902L5.68083 13.5259C5.92562 13.4837 6.33079 13.2642 6.49962 13.087L13.4298 5.75162C14.4765 4.63739 14.8564 3.60756 13.3285 2.17257C12.6532 1.5226 12.0708 1.25248 11.5474 1.25248Z" fill="#999999" />
                            <path d="M12.7377 8.03852H12.6786C11.3994 7.91305 10.1982 7.3655 9.26441 6.48215C8.33063 5.5988 7.7173 4.42986 7.52107 3.15953C7.47042 2.81344 7.70678 2.49268 8.05286 2.43359C8.39895 2.38294 8.71972 2.61929 8.7788 2.96538C8.93336 3.95861 9.41354 4.87233 10.1439 5.56296C10.8742 6.25358 11.8134 6.68196 12.8137 6.78078C13.1598 6.81455 13.413 7.12687 13.3792 7.47296C13.337 7.79372 13.0585 8.03852 12.7377 8.03852ZM15.8272 17.9999H0.633087C0.286999 17.9999 0 17.7129 0 17.3668C0 17.0208 0.286999 16.7338 0.633087 16.7338H15.8272C16.1733 16.7338 16.4603 17.0208 16.4603 17.3668C16.4603 17.7129 16.1733 17.9999 15.8272 17.9999Z" fill="#999999" />
                        </svg> */}
                    </div>

                    <div className='catagory-con'>
                        <div className="m-30 admin-vedio-select-option d-flex gap-2">
                            <FormControl className="vertical-form-controll">
                                <InputLabel id="multiple-checkbox-label" className="select-catagery">
                                    Select Category
                                </InputLabel>
                                <Select
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    value={selectedCategory.Category || "Select Category"}
                                    onChange={handleCategorySelectChange}
                                    input={<OutlinedInput label="Select Category" />}
                                    renderValue={(selected) => selected}
                                >
                                    {
                                        props.playlistCategories.map((category) => (
                                            <MenuItem value={category}>{category.PlaylistCategoryName}</MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                            <input type="text" placeholder="Points for the Video" className="select-cat-upload" />
                        </div>
                        {/* <Selectcategory Id={videoListMock.filter(a => a.Type === courseContentTypes.Course)[0]} selector='Course' onSeletecCategoryId={(i) => setCourseType(i)} /> */}
                    </div>
                    <div>
                        <DragDropContext onDragEnd={handleDrop}>
                            <List className="new-quiz-list">

                                <Droppable droppableId="list-container">
                                    {(provided) => (
                                        <div
                                            className="list-container"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {videoListMock.map((e, index) => (
                                                <Draggable key={index} draggableId={`draggable ${index}`} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            className="item-container"
                                                            ref={provided.innerRef}
                                                            {...provided.dragHandleProps}
                                                            {...provided.draggableProps}
                                                        >
                                                            <VideoListItem data={e} index={index} />

                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </List>
                        </DragDropContext>
                    </div>
                    {
                        window.location.pathname.split('/').pop() === courseCollectionPath &&
                        <div className="link-inp-con lms-flex-align-center">
                            <div>
                                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleAddVideoToPlaylist}>
                                    <path d="M9.8918 9.89181L17.3689 9.87516C17.7686 9.87516 18.085 9.55875 18.0684 9.17574C18.0684 8.77607 17.752 8.45966 17.3689 8.47631L9.8918 8.49297L9.8918 0.999173C9.8918 0.599504 9.5754 0.283099 9.19238 0.299752C8.79271 0.299752 8.47631 0.616156 8.49296 0.999173L8.47631 8.47631L0.999167 8.49297C0.599498 8.49297 0.283094 8.80937 0.299747 9.19239C0.299747 9.39222 0.383011 9.5754 0.499581 9.69197C0.616151 9.80854 0.799333 9.89181 0.999168 9.89181L8.49296 9.89181L8.49296 17.3856C8.49296 17.5854 8.57623 17.7686 8.6928 17.8852C8.80937 18.0018 8.99255 18.085 9.19238 18.085C9.59205 18.085 9.90846 17.7686 9.8918 17.3856L9.8918 9.89181Z" fill="#043677" />
                                </svg>
                            </div>
                            <div className="document-icon-con quiz-icon-con">
                                <svg width="20" height="25" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 -4.3869e-05H20V25H0V-4.3869e-05ZM2.08777 5.72881H14.0596V6.24965H2.08777V5.72881ZM2.08777 12.2395H14.0596V12.7604H2.08777V12.2395ZM2.08777 18.7503H14.0596V19.2711H2.08777V18.7503ZM15.7846 4.66495H17.9123C18.0561 4.66495 18.1727 4.78156 18.1727 4.92537V7.05303C18.1727 7.19684 18.0561 7.31345 17.9123 7.31345H15.7846C15.6408 7.31345 15.5242 7.19684 15.5242 7.05303V4.92537C15.5242 4.78156 15.6408 4.66495 15.7846 4.66495ZM15.7846 11.1757H17.9123C18.0561 11.1757 18.1727 11.2923 18.1727 11.4361V13.5638C18.1727 13.7076 18.0561 13.8242 17.9123 13.8242H15.7846C15.6408 13.8242 15.5242 13.7076 15.5242 13.5638V11.4361C15.5242 11.2923 15.6408 11.1757 15.7846 11.1757ZM15.7846 17.6864H17.9123C18.0561 17.6864 18.1727 17.803 18.1727 17.9468V20.0745C18.1727 20.2183 18.0561 20.3349 17.9123 20.3349H15.7846C15.6408 20.3349 15.5242 20.2183 15.5242 20.0745V17.9468C15.5242 17.803 15.6408 17.6864 15.7846 17.6864Z" fill="#819ABB" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.4324 5.6311L16 6.06348L16.4442 6.50767L16.6603 6.72388L16.8765 6.50767L17.6169 5.76733L17.1846 5.33496L16.6604 5.85913L16.4324 5.6311Z" fill="#819ABB" />
                                </svg>
                            </div>
                            <input type="text" placeholder="Link New Quiz" value={selectedEditQuiz.Title} onChange={(e) => handleVideoSearch(e.target.value)} />
                        </div>
                    }
                    {/* {suggestionsActive && <Suggestions type={courseContentTypes.Video} />} */}
                </>
            )
        }
    }

    const handleNumberOfQuestion = (event) => {
        const { name, value } = event.target
        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setLevelPercentError(false)
        setQuizTotalQuestionError(false)
        setQuestionNumber((prevInfo) => {
            if (name === "0") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
            if (name === "1") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
            if (name === "2") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
        })
    }

    const handlePercentage = (event) => {
        const { name, value } = event.target
        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setQuizTotalQuestionError(false)
        setLevelPercentError(false)
        setLevelPercentError(false)
        setLevelPercentage((prevInfo) => {
            if (name === "0") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
            if (name === "1") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
            if (name === "2") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
        })
    }

    const handleQuestionPoints = (event) => {
        const { name, value } = event.target
        setQuizLevelEmptyError(false)
        setQuizLevelConfigError(false)
        setLevelPercentError(false)
        setQuizTotalQuestionError(false)
        setQuestionPoint((prevInfo) => {
            if (name === "0") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
            if (name === "1") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
            if (name === "2") {
                return { ...prevInfo, [name]: parseInt(value.replace(/^0+/, "")) || undefined };
            }
        })
    }

    function difficultyRation(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return props.questionLevels && props.questionLevels.map(a =>
            (arr.find(v => v.LevelId == a.Id) || {}).NumberOfQuestions || 0
        );
    }

    const handleEditQuiz = () => {
        var levelObj = Object.values(questionNumber)
        var levels = Object.keys(questionNumber)
        // var totalPercentage = Object.values(levelPercentage)

        var percentages = levels.map(a => {
            return {
                points: questionPoint[a],
                percent: levelPercentage[a]
            }
        })

        var iserror = percentages.filter(a => isNaN(a.percent) || isNaN(a.points) || parseInt(a.points) === 0).length
        var isPercentageCorrect = percentages.filter(a => parseInt(a.percent) <= 0 || parseInt(a.percent) > 100).length

        if (quizTitle.length > 0 && totalQuestions >= 1 && levels.length > 0 && levelObj.filter(a => isNaN(a)).length < levelObj.length && iserror === 0 && isPercentageCorrect === 0) {
            setLocalLoader(true)
            var quizPayload = {
                Id: selectedEditQuiz.Id,
                Title: quizTitle.trim(),
                NumberOfRetake: isNaN(retake) ? 0 : retake,
                PassScoreInPertcentage: parseInt(passingPercentage),
                TotalQuestions: parseInt(totalQuestions),
                TotalScore: parseInt(totalScores),
                IsOptional: true,
                QuizLevels: Object.keys(questionNumber).map(item => {
                    return {
                        LevelId: parseInt(item),
                        NumberOfQuestions: parseInt(questionNumber[item]),
                        PassScoreInPertcentage: parseInt(levelPercentage[item]),
                        PointsPerQuestion: parseInt(questionPoint[item])
                    }
                })
            }
            API.updateQuiz(quizPayload)
                .then(res => res.status === 200 &&
                    UserAPI.fetchCoursesDetail(courseId))
                .then(() =>
                    setLocalLoader(false))
                .then(() => {
                    var payload = {
                        select: false,
                        Id: quizPayload.Id,
                        Title: quizPayload.Title,
                        TotalQuestions: quizPayload.TotalQuestions,
                        TotalMarks: quizPayload.TotalScore,
                        DifficultyRatio: difficultyRation(quizPayload.QuizLevels).join(' : '),
                        PassScore: quizPayload.PassScoreInPertcentage,
                        Retakes: quizPayload.NumberOfRetake,
                        CreatedOn: quizPayload.CreatedDate,
                        Levels: quizPayload.QuizLevels.map(a => {
                            return {
                                AssignedQuestions: selectedEditQuiz.QuizLevels.filter(j => j.LevelId == a.LevelId)[0].AssignedQuestions,
                                Index: null,
                                LevelId: a.LevelId,
                                LevelType: props.questionLevels.filter(j => j.Id == a.LevelId)[0].Name,
                                NumberOfQuestions: a.NumberOfQuestions,
                                PassScoreInPertcentage: a.PassScoreInPertcentage,
                                PointsPerQuestion: a.PointsPerQuestion,
                            }
                        })
                    }
                    setSelectedQuiz(payload)
                })
                .then(() =>
                    handleClose())
        }
        else {
            if (quizTitle.length <= 0) {
                setQuizTitleError(true)
            }
            if (passingPercentage <= 0) {
                setQuizPointError(true)
            }
            if (totalQuestions < 1) {
                setQuizTotalQuestionError(true)
            }
            if (levelObj.length <= 0) {
                setQuizLevelEmptyError(true)
            }
            if (levelObj.filter(a => isNaN(a)).length === levelObj.length) {
                setQuizLevelEmptyError(true)
            }
            if (isPercentageCorrect !== 0) {
                setLevelPercentError(true)
            }
            if (iserror !== 0) {
                setQuizLevelConfigError(true)
            }
        }
    }

    React.useEffect(() => {
        if (Object.keys(questionNumber).length > 0) {
            Object.keys(questionPoint).forEach(key => questionPoint[key] === undefined && delete questionPoint[key])
            const questmarkSync = Object.keys(questionPoint).reduce((acc, key) => {
                acc[key] = questionPoint[key] * questionNumber[key];
                return acc;
            }, {});
            var totalPoints = Object.values(questmarkSync).reduce((a, b) => a + b, 0)
            totalPoints = isNaN(totalPoints) ? 0 : totalPoints
            setTotalScores(totalPoints)
        }
    }, [questionPoint, questionNumber]);

    React.useEffect(() => {
        if (Object.values(levelPercentage).length > 0) {
            var totalPercentage = Object.values(levelPercentage)
            var result = Array.from(totalPercentage, v => v === undefined ? 0 : v);
            var calPer = result.filter(a => parseInt(a) !== 0).reduce((a, b) => { return parseInt(a) + parseInt(b) }, 0) / result.filter(a => parseInt(a) !== 0).length
            setPassingPercentage(parseInt(calPer))
        }
    }, [levelPercentage])

    React.useEffect(() => {
        Object.keys(questionNumber).forEach(key => questionNumber[key] === undefined && delete questionNumber[key])
        var totalQuestions = Object.values(questionNumber).reduce((a, b) => a + b, 0)
        totalQuestions = isNaN(totalQuestions) ? 0 : totalQuestions
        setTotalQuestions(totalQuestions)
    }, [questionNumber]);


    const handleRemoveFromPlaylist = (event, item) => {
        event.preventDefault();
        setLocalLoading(true);

        const removeItemFromState = () => {
            setVideoQuizMockList(prevList =>
                prevList.filter(listItem => listItem.Id !== item.Id)
            );
        };

        if (item.Type === courseContentTypes.Video) {
            API.removeVideoFromPlaylist({
                videoId: item.Id,
                playlistId: courseId
            })
                .then(res => {
                    if (res.status === 204) {
                        setAlert({ active: true, severity: 'success', message: 'Video removed from playlist successfully' });
                        removeItemFromState();
                    }
                })
                .finally(() => setLocalLoading(false));
        } else {
            API.removeQuizFromPlaylist({
                quizId: item.Id,
                playlistId: courseId
            })
                .then(res => {
                    if (res.status === 200) {
                        setAlert({ active: true, severity: 'success', message: 'Quiz removed from playlist successfully' });
                        removeItemFromState();
                    }
                })
                .finally(() => setLocalLoading(false));
        }
    };

    const videoEditScreen = () => {
        return (
            <>
                <Modal
                    open={videoEditOpen}
                    onClose={handleClose}
                    className={`${IsBreadcrumbsActive ? 'edit-modal-pop' : 'modal-pop'}`}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={EditModelStyle}>
                        {!localLoading ?
                            <>
                                <div className="quiz-creator-con">
                                    <h3>{Video_Edit}</h3>
                                    <div><img src="../assets/close-black.png" alt="lms-logo" className="close-icon" onClick={handleClose} /></div>
                                </div>
                                <Divider className="mui-devider" />
                                <div>
                                    <label className='video-tilte-table'>Add video heading</label>
                                </div>
                                <div className='edit-inp-con mt-0'>
                                    <input type="text" className='course-edit-inp' value={videoTitleChange || videoData.Title} onChange={(i) => setVideoTitleChange(i.target.value)} />
                                    {/* <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.77707 15.2732C2.26216 15.2732 1.78101 15.096 1.43492 14.7668C0.995984 14.3532 0.784955 13.7285 0.860925 13.0532L1.17325 10.3183C1.23234 9.80337 1.54466 9.11964 1.90763 8.74823L8.83782 1.41286C10.5683 -0.418868 12.3747 -0.469514 14.2064 1.26092C16.0381 2.99136 16.0888 4.79777 14.3583 6.6295L7.42815 13.9649C7.07362 14.3447 6.41521 14.6992 5.9003 14.7837L3.18224 15.2479C3.03874 15.2564 2.91213 15.2732 2.77707 15.2732ZM11.5474 1.25248C10.8975 1.25248 10.3319 1.65766 9.75791 2.26542L2.82771 9.60923C2.65889 9.78649 2.46475 10.2085 2.43098 10.4533L2.11866 13.1883C2.08489 13.4668 2.15242 13.6947 2.30436 13.8382C2.4563 13.9817 2.68422 14.0324 2.96277 13.9902L5.68083 13.5259C5.92562 13.4837 6.33079 13.2642 6.49962 13.087L13.4298 5.75162C14.4765 4.63739 14.8564 3.60756 13.3285 2.17257C12.6532 1.5226 12.0708 1.25248 11.5474 1.25248Z" fill="#999999" />
                                        <path d="M12.7377 8.03852H12.6786C11.3994 7.91305 10.1982 7.3655 9.26441 6.48215C8.33063 5.5988 7.7173 4.42986 7.52107 3.15953C7.47042 2.81344 7.70678 2.49268 8.05286 2.43359C8.39895 2.38294 8.71972 2.61929 8.7788 2.96538C8.93336 3.95861 9.41354 4.87233 10.1439 5.56296C10.8742 6.25358 11.8134 6.68196 12.8137 6.78078C13.1598 6.81455 13.413 7.12687 13.3792 7.47296C13.337 7.79372 13.0585 8.03852 12.7377 8.03852ZM15.8272 17.9999H0.633087C0.286999 17.9999 0 17.7129 0 17.3668C0 17.0208 0.286999 16.7338 0.633087 16.7338H15.8272C16.1733 16.7338 16.4603 17.0208 16.4603 17.3668C16.4603 17.7129 16.1733 17.9999 15.8272 17.9999Z" fill="#999999" />
                                    </svg> */}
                                </div>
                                <input type='file' style={{ display: 'none' }}
                                    accept="image/*"
                                    ref={thumbnailFile}
                                    onChange={(e) => handleThumbnail(e.target.files)} />
                                <div className="quiz-creator-con pt-3">
                                    <div className="replace-image-con"><img src={thumbnailPreview} className="collection-thumb" /><button onClick={handleThumbnailClick}>Replace image</button></div>
                                </div>
                                <div className='catagory-con'>
                                    <div className='d-flex align-items-center gap-2 course-points-tags'>
                                        <FormControl className="vertical-form-controll">
                                            <label>Select Category</label>
                                            <Select
                                                labelId=""
                                                id="demo-multiple-checkbox"
                                                value={videoCategoryChange.Id || ""}
                                                onChange={(e) => {
                                                    const selectedCategory = props.playlistCategories.find(category => category.Id === e.target.value);
                                                    setVideoCategoryChange({ Id: selectedCategory.Id, Category: selectedCategory.PlaylistCategoryName });
                                                }}
                                                input={<OutlinedInput label="" />}
                                                renderValue={(selected) => {
                                                    const selectedCategory = props.playlistCategories.find(category => category.Id === selected);
                                                    return selectedCategory ? selectedCategory.PlaylistCategoryName : "Select Category";
                                                }}
                                            >
                                                {props.playlistCategories.map((category) => (
                                                    <MenuItem key={category.Id} value={category.Id}>
                                                        {category.PlaylistCategoryName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        <div>
                                            <label>Points for video</label>
                                            <input
                                                className='course-points'
                                                placeholder='Enter Points'
                                                type='number'
                                                value={videoPointsChange}
                                                onWheel={event => event.currentTarget.blur()}
                                                onChange={(e) => setVideoPointsChange(e.target.value)} />
                                        </div>
                                        <div>
                                            <label>Add Tags</label>
                                            <input
                                                className='course-points'
                                                placeholder='Enter Tags'
                                                type='text'
                                                value={videoTagsChange}
                                                onChange={(e) => setVideoTagsChange(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <DragDropContext onDragEnd={handleDrop}>
                                        <List className="new-quiz-list">

                                            <Droppable droppableId="list-container">
                                                {(provided) => (
                                                    <div
                                                        className="list-container"
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {videoListMock.map((e, index) => (
                                                            <Draggable key={index} draggableId={`draggable ${index}`} index={index}>
                                                                {(provided) => (
                                                                    <div
                                                                        className="item-container"
                                                                        ref={provided.innerRef}
                                                                        {...provided.dragHandleProps}
                                                                        {...provided.draggableProps}
                                                                    >
                                                                        <VideoListItem data={e} index={index} />

                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </List>
                                    </DragDropContext>
                                </div> */}
                                <div className='flex-column video-text edit-video-text-area'>
                                    <div className='course-edit-text-area video-text-area'>
                                        <h4>{Description_Label}</h4>
                                        <textarea
                                            rows="10"
                                            cols="5"
                                            placeholder={Add_Description}
                                            name="description"
                                            value={videoDescriptionChange || videoData.Description}
                                            onChange={(e) => setVideoDescriptionChange(e.target.value)}
                                        ></textarea>
                                        <h4 className='mt-4'>Transcript</h4>
                                        <textarea
                                            rows="10"
                                            cols="5"
                                            placeholder='Add Transcript'
                                            name="transcript"
                                            value={videoTranscriptChange || videoData.Transcript}
                                            onChange={(e) => setVideoTranscriptChange(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className='course-edit-drag'>
                                        <h4>{Documents_Label}</h4>
                                        <div className='video-edit-documents-list'>
                                            {videoDocumnetChange.length > 0 && (
                                                <div id="lms-tab-2" className="lms-tabcontent video-upload-docs lms-flex m-0">
                                                    {videoDocumnetChange.map((doc, index) =>
                                                        extension.some(ext => ext.exstension.includes(doc.Extension?.trim())) && (
                                                            <DocumentCheck
                                                                key={index}
                                                                handleDocumentRemove={()=> handleDocumentRemove(index , doc.Id)}
                                                                Extension={doc.Extension}
                                                                Filename={doc.Title}
                                                                CreatedDate={doc.CreatedDate}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            )}
                                            <Dropzone onDrop={onDrop}>
                                                {({ getRootProps, getInputProps }) => (
                                                    <div {...getRootProps({ className: "dropfile" })}>
                                                        <input {...getInputProps()} />
                                                        <svg className='drag-n-drop-svg' xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="none" fillRule="evenodd">
                                                                <rect className="svg-for-menu" rx="8.448" />
                                                                <path fill="#FFF" d="M8.912 7.363h12.4v16.275h-12.4z" />
                                                                <path
                                                                    d="M7.825 6.2h15.35c.894 0 1.625.73 1.625 1.625v15.35a1.63 1.63 0 0 1-1.625 1.625H7.825A1.63 1.63 0 0 1 6.2 23.175V7.825c0-.894.73-1.625 1.625-1.625zm12.07 6.713-1.348 1.334-2.093-2.068v10.254h-1.908V12.178l-2.093 2.069-1.35-1.334L15.5 8.567l4.396 4.346z"
                                                                    className="svg-for-menu"
                                                                />
                                                            </g>
                                                        </svg>
                                                        <p className="drop-text">{Drag_And_Drop}</p>
                                                        <p>{Or}</p>
                                                        <label htmlFor="drag-file" className="pri-button">{Chose_File}</label>
                                                    </div>
                                                )}
                                            </Dropzone>
                                        </div>
                                        {documentLength && <h6 style={{ color: "red",marginTop:"1vw" }}>Documents should be less then 5</h6>}
                                        {documentError.length > 0 && <h6 style={{ color: "red", marginTop:"1vw" }}>{documentError}</h6>}
                                    </div>
                                </div>
                                <div className='d-flex justify-content-end video-upload-btn'>
                                    <button className='primary-button sec-btn mt-5 ad-share-btn video-update-btn' onClick={handleVideoUpdate} disabled={videoUpdateLoader}>
                                        {videoUpdateLoader ? <svg className='spinner-video-update' width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M4.38798 12.616C3.36313 12.2306 2.46328 11.5721 1.78592 10.7118C1.10856 9.85153 0.679515 8.82231 0.545268 7.73564C0.411022 6.64897 0.576691 5.54628 1.02433 4.54704C1.47197 3.54779 2.1845 2.69009 3.08475 2.06684C3.98499 1.4436 5.03862 1.07858 6.13148 1.01133C7.22435 0.944078 8.31478 1.17716 9.28464 1.68533C10.2545 2.19349 11.0668 2.95736 11.6336 3.89419C12.2004 4.83101 12.5 5.90507 12.5 7"
                                                stroke="white"
                                            />
                                        </svg> : Update_Button}
                                    </button>
                                </div>
                                {/* {suggestionsActive && <Suggestions />} */}
                            </>
                            :
                            <Spinner />
                        }
                    </Box>
                </Modal>
            </>
        )
    }

    const quizEditScreen = () => {
        return (
            <Modal
                open={isEditQuizOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='popup-breadcrumd'>
                    <Box sx={EditModelStyle}>
                        {
                            !localLoader ?
                                <>
                                    <div className=' quiz-creator-con'>
                                        <Breadcrumbs aria-label="breadcrumb"
                                            separator={<NavigateNextIcon fontSize="medium" />}>
                                            <h2 className=''>Edit Quiz</h2>
                                            <h2 className='word-break' style={{ opacity: "0.4" }}>{quizTitle}</h2>
                                        </Breadcrumbs>
                                        <div>
                                            <img src="../assets/close-black.png" alt="lms-logo" class="close-icon" onClick={handleClose} />
                                        </div>
                                    </div>
                                    <div className="quiz-text-input">
                                        <label>Add Title</label>
                                        <input type="text" className={quizTitleError && `error`} placeholder="Type your quiz title here" value={quizTitle}
                                            onChange={(e) => {
                                                setQuizTitle(e.target.value)
                                                setQuizTitleError(false)
                                            }}
                                        />
                                        {quizTitleError && <label className="question-title error-label"><p>Please enter quiz title</p></label>}
                                    </div>

                                    <div className="quiz-selection-con d-flex align-items-center justify-content-between">
                                        <div className="quiz-select">
                                            <label for="retake">Retake</label>
                                            <FormControl id="points">
                                                <TextField
                                                    id="outlined-number"
                                                    label=""
                                                    type="number"
                                                    className="mui_select_con"
                                                    onChange={(e) => setRetake(parseInt(e.target.value))}
                                                    value={retake}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        {quizPointError && <label className="question-title error-label"><p>Passing Percentage should be between 1-100 %</p></label>}
                                    </div>
                                    {
                                        props.questionLevels && props.questionLevels.map((item, idx) => {
                                            return (
                                                <div className="quiz-selection-con d-flex align-items-center justify-content-between" key={idx}>
                                                    <div className="quiz-select select-four-layout">
                                                        <label for="difficulty-level" className="select-four-label">Difficulty level</label>
                                                        <h3 id="difficulty-level">{item.Name}</h3>
                                                    </div>
                                                    <div className="quiz-select select-four-layout">
                                                        <label for="points">No. of Questions</label>
                                                        <FormControl id="points">
                                                            <TextField
                                                                id="outlined-number"
                                                                label=""
                                                                type="number"
                                                                className="mui_select_con"
                                                                name={item.Id}
                                                                onChange={handleNumberOfQuestion}
                                                                placeholder={isNaN(questionNumber[item.Id]) && 0}
                                                                value={questionNumber[item.Id]}
                                                            />
                                                        </FormControl>
                                                    </div>
                                                    <div className="quiz-select select-four-layout">
                                                        <label for="points">Passing %</label>
                                                        <FormControl id="points">
                                                            <TextField
                                                                id="outlined-number"
                                                                label=""
                                                                type="number"
                                                                className="mui_select_con"
                                                                name={item.Id}
                                                                // defaultValue={item.PassingPercentage}
                                                                disabled={questionNumber[item.Id] === 0 || isNaN(questionNumber[item.Id])}
                                                                onChange={handlePercentage}
                                                                placeholder={isNaN(levelPercentage[item.Id]) && 0}
                                                                value={levelPercentage[item.Id]} />
                                                        </FormControl>
                                                    </div>
                                                    <div className="quiz-select select-four-layout">
                                                        <label for="retake">Points for 1 question</label>
                                                        <FormControl id="points">
                                                            <TextField
                                                                id="outlined-number"
                                                                label=""
                                                                type="number"
                                                                className="mui_select_con"
                                                                name={item.Id}
                                                                disabled={questionNumber[item.Id] === 0 || isNaN(questionNumber[item.Id])}
                                                                onChange={handleQuestionPoints}
                                                                placeholder={isNaN(questionPoint[item.Id]) && 0}
                                                                value={questionPoint[item.Id]} />
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className="quiz-selection-con m-0">
                                        {quizLevelEmptyError && <label className="question-title error-label"><p>Please add questions per levels</p></label>}
                                        {quizLevelConfigError && <label className="question-title error-label"><p>Please complete the difficulty level values</p></label>}
                                        {levelPercentError && <label className="question-title error-label"><p>Passing Percentge should be between 1 - 100 %</p></label>}
                                    </div>

                                    <div className="quiz-selection-con d-flex align-items-center justify-content-between grey-box-input">
                                        <div className="quiz-select">
                                            <label for="points">Total Questions</label>
                                            <input type="text" disabled value={totalQuestions} />
                                        </div>
                                        <div className="quiz-select">
                                            <label for="retake">Maximum Points</label>
                                            <input type="text" disabled value={totalScores} />
                                        </div>
                                    </div>
                                    <div className="grey-box-input quiz-selection-con d-flex align-items-center justify-content-between">
                                        <div className="quiz-select">
                                            <label for="retake">Passing %</label>
                                            <input type="text" disabled value={passingPercentage} />
                                        </div>
                                    </div>
                                    <div className="quiz-selection-con m-0">
                                        {quizTotalQuestionError && <label className="question-title error-label"><p>Question number cannot be less than 0</p></label>}
                                    </div>
                                    <div className=" w-100 model-padding d-flex justify-content-end">
                                        <button className="filter-clear-all mx-4" onClick={handleClose}>Cancel</button>
                                        <button className="filter-clear-search" onClick={() => handleEditQuiz()}>Update</button>
                                    </div>
                                </> : <Spinner />
                        }
                    </Box>
                </div>
            </Modal>
        )
    }

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={alert.active}
                onClick={handleBackDropClose}
            >
                <Alert severity={alert.severity} style={{ position: "absolute", top: 20 }}>
                    {alert.message}
                </Alert>
            </Backdrop>
            {IsBreadcrumbsActive ?
                <div className='main-quiz-container'>
                    <div className='d-flex align-items-center justify-content-between'>
                        <Breadcrumbs aria-label="breadcrumb"
                            separator={<NavigateNextIcon fontSize="medium" />}>
                            <Link underline="hover" color="inherit" style={{ opacity: "0.4", cursor: 'pointer' }} onClick={() => setBreadcrumbsActive(false)}>
                                <h2>Course</h2>
                            </Link>
                            <h2 className='word-break'>{courseDetails.Course.CourseName}</h2>

                            {/* Tags will come here */}

                            {/* <div className='webinar-tags-part'>
                                <div className="d-flex  align-items-center total-scrore" data-mui-internal-clone-element="true">
                                    {
                                        courseDetails.Course.Tags.split(',').map(tag => {
                                            return (
                                                <>
                                                    <h6>{tag}</h6>
                                                    <span></span>
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div> */}
                        </Breadcrumbs>
                        <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 42 39" fill="none" onClick={() =>
                            handleEdit(courseId)}>
                            <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8" />
                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg" />
                        </svg>
                    </div>
                    <Modal
                        open={editOpen}
                        onClose={handleClose}
                        className="edit-modal-pop"
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={EditModelStyle}>
                            {!modalLoader ?
                                <>
                                    <div className="quiz-creator-con">
                                        <h3>Edit {courseDetails.Course.CourseType === 2 ? 'Online Course' : 'Offline Course'}</h3>
                                        <div><img src="../assets/close-black.png" alt="lms-logo" className="close-icon" onClick={handleClose} /></div>
                                    </div>
                                    <Divider className="mui-devider" />
                                    <div className='edit-inp-con'>

                                        <FormControl sx={{ width: "100% !important" }} className="vertical-form-controll">
                                            <TextField
                                                id="fullWidth"
                                                label="Add Course heading"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end"
                                                            sx={{ pointerEvents: "none", position: "absolute", right: 0 }}>
                                                            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M2.77707 15.2732C2.26216 15.2732 1.78101 15.096 1.43492 14.7668C0.995984 14.3532 0.784955 13.7285 0.860925 13.0532L1.17325 10.3183C1.23234 9.80337 1.54466 9.11964 1.90763 8.74823L8.83782 1.41286C10.5683 -0.418868 12.3747 -0.469514 14.2064 1.26092C16.0381 2.99136 16.0888 4.79777 14.3583 6.6295L7.42815 13.9649C7.07362 14.3447 6.41521 14.6992 5.9003 14.7837L3.18224 15.2479C3.03874 15.2564 2.91213 15.2732 2.77707 15.2732ZM11.5474 1.25248C10.8975 1.25248 10.3319 1.65766 9.75791 2.26542L2.82771 9.60923C2.65889 9.78649 2.46475 10.2085 2.43098 10.4533L2.11866 13.1883C2.08489 13.4668 2.15242 13.6947 2.30436 13.8382C2.4563 13.9817 2.68422 14.0324 2.96277 13.9902L5.68083 13.5259C5.92562 13.4837 6.33079 13.2642 6.49962 13.087L13.4298 5.75162C14.4765 4.63739 14.8564 3.60756 13.3285 2.17257C12.6532 1.5226 12.0708 1.25248 11.5474 1.25248Z" fill="#999999" />
                                                                <path d="M12.7377 8.03852H12.6786C11.3994 7.91305 10.1982 7.3655 9.26441 6.48215C8.33063 5.5988 7.7173 4.42986 7.52107 3.15953C7.47042 2.81344 7.70678 2.49268 8.05286 2.43359C8.39895 2.38294 8.71972 2.61929 8.7788 2.96538C8.93336 3.95861 9.41354 4.87233 10.1439 5.56296C10.8742 6.25358 11.8134 6.68196 12.8137 6.78078C13.1598 6.81455 13.413 7.12687 13.3792 7.47296C13.337 7.79372 13.0585 8.03852 12.7377 8.03852ZM15.8272 17.9999H0.633087C0.286999 17.9999 0 17.7129 0 17.3668C0 17.0208 0.286999 16.7338 0.633087 16.7338H15.8272C16.1733 16.7338 16.4603 17.0208 16.4603 17.3668C16.4603 17.7129 16.1733 17.9999 15.8272 17.9999Z" fill="#999999" />
                                                            </svg>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                value={courseName}
                                                error={courseNameError}
                                                variant="standard"
                                                onChange={(e) => handleCourseName(e.target.value)}
                                            />
                                            {
                                                courseNameError &&
                                                <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                                                    Course Heading should be between 1-50 characters
                                                </FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    <input type='file' style={{ display: 'none' }}
                                        accept="image/*"
                                        ref={thumbnailFile}
                                        onChange={(e) => handleThumbnail(e.target.files)} />
                                    <div className="quiz-creator-con pt-3">
                                        <div className="replace-image-con mt-1"><img src={thumbnailPreview} className="collection-thumb" /><button onClick={handleThumbnailClick}>Replace image</button></div>
                                    </div>
                                    <div className='catagory-con'>
                                        <div className='d-flex align-items-center gap-2 course-points-tags'>
                                            <FormControl className="vertical-form-controll">
                                                <label>Select Category</label>
                                                <Select
                                                    labelId=""
                                                    id="demo-multiple-checkbox"
                                                    value={selectedCategory.Category || "Select Category"}
                                                    onChange={handleCategorySelectChange}
                                                    input={<OutlinedInput label="Select Category" />}
                                                    renderValue={(selected) => selected}
                                                    error={courseCategoryError}
                                                >
                                                    {
                                                        props.playlistCategories.map((category) => (
                                                            <MenuItem value={category}>{category.PlaylistCategoryName}</MenuItem>
                                                        ))}
                                                </Select>
                                                {
                                                    courseCategoryError &&
                                                    <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                                                        Please Course Category
                                                    </FormHelperText>
                                                }
                                            </FormControl>
                                            {courseDetails.Course.CourseType === 1 &&
                                                <>
                                                    <div>
                                                        <label>Points for course</label>
                                                        <input
                                                            className='course-points'
                                                            placeholder='Enter Points'
                                                            type='number'
                                                            value={coursePoints}
                                                            onWheel={event => event.currentTarget.blur()}
                                                            onChange={(e) => setCoursePoints(e.target.value)} />
                                                    </div>
                                                    <div>
                                                        <label>Add Tags</label>
                                                        <input
                                                            className='course-points'
                                                            placeholder='Enter Tags'
                                                            type='text'
                                                            value={courseTags === 'null' || courseTags === null ? '' : courseTags}
                                                            onChange={(e) => setCourseTags(e.target.value)} />
                                                    </div></>
                                            }
                                        </div>
                                        <h4 className='mt-5'>About This Course</h4>
                                        <FormControl sx={{ width: "100% !important" }} className="vertical-form-controll mb-4">
                                            <textarea
                                                ref={descriptionRef}
                                                className={`courseEditDesc ${courseDescriptionError ? 'error' : 'normal'}`}
                                                id="video-descripation"
                                                rows="12"
                                                cols="50"
                                                form="usrform"
                                                placeholder="Say About This Course"
                                                name="description"
                                                value={courseDescription}
                                                onChange={(e) => handleCourseDescription(e.target.value)}
                                            ></textarea>
                                            {
                                                courseDescriptionError &&
                                                <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                                                    Course Description should be less 1000 characters
                                                </FormHelperText>
                                            }
                                        </FormControl>
                                    </div>

                                </>
                                :
                                <Spinner />
                            }
                            <div className="d-flex justify-content-end lms-flex-end  mb-2 quiz-creator-con">
                                <div className="upload-btn-width d-flex justify-content-end gap-2">
                                    <button className="filter-clear-all" onClick={handleClose}>Cancel</button>
                                    <button className="filter-clear-search" onClick={courseDetails.Course.CourseType === 2 ? handleEditLiveCourse : handleEditOfflineCourse}>Update</button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                    <Box>
                        <div className='d-flex align-items-center justify-content-between mt-3'>
                            <Tabs value={value} onChange={handleTabChange} aria-label="basic tabs example" className='admin-analytics-tabs assesment-quiz-tab'>
                                <Tab className={tabClassName} label={`${courseDetails.CourseType === 2 ? 'Sessions' : 'Content'}`} {...a11yProps(0)} />
                                <Tab className={tabClassName} label="About this Course" {...a11yProps(1)} />
                                <Tab className={tabClassName} label="Documents" {...a11yProps(2)} />
                            </Tabs>
                            {
                                courseDetails.Sessions.length > 0 &&
                                <div className='d-flex align-items-center'>
                                    <button type="button" className="create-new-btn wd-add-video mx-4" style={{ width: "9.01vw" }} onClick={handleAddSession}><p>Add Session</p></button>
                                </div>
                            }
                        </div>
                    </Box>
                    {
                        !localLoader ?
                            <>
                                <CustomTabPanel className="mt-3 webinar-tab-pannel" value={value} index={0}>
                                    {
                                        courseDetails.Course.CourseType === 1 ?
                                            (
                                                <div className='w-100 py-3'>
                                                    <DragDropContext onDragEnd={handleDrop}>
                                                        <List className="new-quiz-list">

                                                            <Droppable droppableId="list-container">
                                                                {(provided) => (
                                                                    <div
                                                                        className="list-container"
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        {videoQuizMockList.map((i, index) => (
                                                                            <Draggable key={index} draggableId={`draggable ${index}`} index={index}>
                                                                                {(provided) => (
                                                                                    <div
                                                                                        className="item-container m-0"
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.dragHandleProps}
                                                                                        {...provided.draggableProps}
                                                                                    >
                                                                                        <ListItem disablePadding className="newQuizLinkItem justify-content-between">
                                                                                            <>
                                                                                                <div className="lms-flex-align-center ">
                                                                                                    <div className="new-quiz-ver-bars"><img src="../assets/vertical-bars.png" alt="lms-logo" /></div>
                                                                                                    <div className="new-quiz-thumb"><img src={i.ThumbnailUrl ? i.ThumbnailUrl : "assets/quiz-img.jpg"} /></div>
                                                                                                    <div className="name-the-course"><h2>{i.Title}</h2></div>
                                                                                                </div>
                                                                                                <div className="lms-flex-align-center">
                                                                                                    <div className="new-quiz-edit-btn">
                                                                                                        <svg onClick={() => handleVideoEdit(i)} id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
                                                                                                            <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9" />
                                                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" className="edit-icon-svg" />
                                                                                                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" className="edit-icon-svg" />
                                                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg" />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <svg onClick={(event) => { handleRemoveFromPlaylist(event, i) }} className='quiz-del-svg' width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                            <path d="M17 4.76043C14.04 4.4671 11.0622 4.31599 8.09333 4.31599C6.33333 4.31599 4.57333 4.40488 2.81333 4.58266L1 4.76043M5.88889 3.86266L6.08444 2.69821C6.22667 1.85377 6.33333 1.22266 7.83556 1.22266H10.1644C11.6667 1.22266 11.7822 1.88932 11.9156 2.7071L12.1111 3.86266M15.0889 7.56932L14.5111 16.5204C14.4133 17.916 14.3333 19.0004 11.8533 19.0004H6.14667C3.66667 19.0004 3.58667 17.916 3.48889 16.5204L2.91111 7.56932M7.51556 14.1115H10.4756M6.77778 10.556H11.2222" className="del-stroke" />
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>
                                                                                        </ListItem>

                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </List>
                                                    </DragDropContext>
                                                </div>
                                            )
                                            :
                                            (courseDetails.Sessions.length <= 0 ? <div className='centered-add-session'>
                                                <p className='mb-3'>{courseDetails.Course.CourseType === 1 ? "Start adding new sessions for Online course" : "Start adding new quiz for offline course"}</p>
                                                {courseDetails.Course.CourseType === 1 ? <button type="button" className="create-new-btn" onClick={handleAddSession}><p>Add Sessions</p></button> :
                                                    <button type="button" className="create-new-btn" onClick={() => {handleOpen(courseId)}}><p>Link Quizes</p></button>}
                                            </div> :

                                                <div className='w-100 p-3'>
                                                    <List component="nav" aria-labelledby="nested-list-subheader">

                                                        {/* Upcoming Session */}
                                                        <div className='quiz-edit-white-bg'>
                                                            <ListItemButton onClick={() => handleCollapseClick(0)} className='justify-content-between'>
                                                                <div className='list-item-text p-3'>
                                                                    Upcoming
                                                                </div>
                                                                {openIndex === 0 ? <ExpandLess className='asses-expand-icon' /> : <ExpandMore className='asses-expand-icon' />}
                                                            </ListItemButton>
                                                            <Collapse in={openIndex === 0} timeout="auto" unmountOnExit>
                                                                <div className={`collapse-inside add-session-inside`}>
                                                                    <ul>
                                                                        {
                                                                            courseDetails.Sessions.filter(a => a.Status == sessionStatus.NotStarted).map((session, idx) => {
                                                                                return (
                                                                                    <li className='course-session-list'>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='course-sess-img collapse-img'>
                                                                                                    <img src={session.ThumbnailUrl} className="collection-thumb" />
                                                                                                </div>
                                                                                                <div className='course-sess-content'>
                                                                                                    <h4>{session.Title}</h4>
                                                                                                    <div className='d-flex align-items-center'>
                                                                                                        <p>{moment(session.Date).format("Do MMM, YYYY")}</p>
                                                                                                        <p>{LocalTimeFormat(session.StartTime) + ` - ` + LocalTimeFormat(session.EndTime)}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center gap-1'>
                                                                                                <svg onClick={() => handleEditSession(session)} id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none" >
                                                                                                    <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9"></rect>
                                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8"></path>
                                                                                                    <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8"></path>
                                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg"></path></svg>
                                                                                                <svg onClick={() => {
                                                                                                    setDialog({
                                                                                                        Open: true,
                                                                                                        Header: 'Remove Session',
                                                                                                        Body: 'Do you really want to Remove the selected session, You will lost all the session info and cannot conduct the session.',
                                                                                                        ButtoneName: 'Delete',
                                                                                                        modelType: dialogType.sessionCancel
                                                                                                    })
                                                                                                    setSessionId(session.Id)
                                                                                                }}
                                                                                                    id="del-icon" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" ><rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" className="del-stroke" stroke-width="0.9"></rect>
                                                                                                    <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white"></path>
                                                                                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                                                                    <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" className="del-svg "></path>
                                                                                                    <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white"></path><path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white" ></path>
                                                                                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                                                                </svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </Collapse>
                                                        </div>

                                                        {/* Completed Session */}
                                                        <div className='quiz-edit-white-bg mt-4'>
                                                            <ListItemButton onClick={() => handleCollapseClick(1)} className='justify-content-between'>
                                                                <div className='list-item-text p-3'>
                                                                    Completed
                                                                </div>
                                                                {openIndex === 1 ? <ExpandLess className='asses-expand-icon' /> : <ExpandMore className='asses-expand-icon' />}
                                                            </ListItemButton>
                                                            <Collapse in={openIndex === 1} timeout="auto" unmountOnExit>
                                                                <div className={`collapse-inside add-session-inside`}>
                                                                    <ul>
                                                                        {
                                                                            courseDetails.Sessions.filter(a => a.Status == sessionStatus.Completed).map((session, idx) => {
                                                                                return (
                                                                                    <li className='course-session-list'>
                                                                                        <div className='d-flex justify-content-between'>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='course-sess-img collapse-img'>
                                                                                                    <img src={session.ThumbnailUrl} className="collection-thumb" />
                                                                                                </div>
                                                                                                <div className='course-sess-content'>
                                                                                                    <h4>{session.Title}</h4>
                                                                                                    <div className='d-flex align-items-center'>
                                                                                                        <p>{moment(session.Date).format("Do MMM, YYYY")}</p>
                                                                                                        <p>{`${moment(session.StartTime).format("h:mm a")} - ${moment(session.EndTime).format("h:mm a")}`}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center gap-1'>
                                                                                                <svg onClick={() => handleEditSession(session)} id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none" >
                                                                                                    <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9"></rect>
                                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8"></path>
                                                                                                    <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8"></path>
                                                                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg"></path></svg>
                                                                                                <svg onClick={() => {
                                                                                                    setDialog({
                                                                                                        Open: true,
                                                                                                        Header: 'Remove Session',
                                                                                                        Body: 'Do you really want to Remove the selected session, You will lost all the session info and cannot conduct the session.',
                                                                                                        ButtoneName: 'Delete',
                                                                                                        modelType: dialogType.sessionCancel
                                                                                                    })
                                                                                                    setSessionId(session.Id)
                                                                                                }} id="del-icon" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" ><rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" className="del-stroke" stroke-width="0.9"></rect>
                                                                                                    <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white"></path>
                                                                                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                                                                    <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" className="del-svg "></path>
                                                                                                    <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white"></path><path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white" ></path>
                                                                                                    <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                                                                </svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                )
                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    </List>
                                                    <Modal
                                                        open={isEditSession}
                                                        onClose={handleClose}
                                                        className="modal-pop"
                                                        aria-labelledby="modal-modal-title"
                                                        aria-describedby="modal-modal-description"
                                                    >
                                                        <div className='add-session-modal'>
                                                            <Box sx={quizStyle} >
                                                                <div className="quiz-creator-con"><h3>Edit Session</h3><div><img src="../assets/close-black.png" alt="lms-logo" className="close-icon" onClick={handleClose} /></div></div>
                                                                {!modalLoader ?
                                                                    <>
                                                                        <div className="quiz-text-input">
                                                                            <label>Session Title*</label>
                                                                            <input className={sessionNameError && 'error'} type="text" placeholder="Type your quiz title here" value={editSessionName} onChange={(e) => {
                                                                                setEditSessionName(e.target.value)
                                                                                setSessionNameError(false)
                                                                            }} />
                                                                            {sessionNameError && <label className="question-title error-label"><p>Session title should be 1-50 characters</p></label>}

                                                                        </div>
                                                                        <input type='file' style={{ display: 'none' }}
                                                                            accept="image/*"
                                                                            ref={sessionThumbnailRef}
                                                                            onChange={(e) => {
                                                                                handleEditSessionThumbnail(e.target.files)
                                                                                setSessionThumbnailError(false)
                                                                            }} />
                                                                        <div className="quiz-creator-con">
                                                                            <div className="replace-image-con m-0"><img src={editSessionThumbnailPreview} className="collection-thumb" /><button onClick={handleSessionThumbnailClick}>Replace image</button></div>
                                                                        </div>
                                                                        {sessionThumnbnailError && <label className="question-title error-label"><p>Session thumbnail is required</p></label>}
                                                                        {
                                                                            editSessionType === sessionStatus.Completed &&
                                                                            <div className="quiz-text-input">
                                                                                <label>Session Video</label>

                                                                                <input type='file' style={{ display: 'none' }} ref={sessionVideoUploadRef}
                                                                                    accept={`video/*`}
                                                                                    onChange={(e) => handleVideoUploadClick(e.target.files)} />
                                                                                <div className="video-upload thumb-upload webinar-upload-part webinar-upload-input  m-0">
                                                                                    <div className='d-flex justify-content-center align-items-center' onClick={handleVideoUploadOpen}>
                                                                                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" className="svg-for-menu" xmlns="http://www.w3.org/2000/svg">
                                                                                            <rect width="31" height="31" rx="8.448" className="svg-for-menu" />
                                                                                            <rect x="8.91248" y="7.36133" width="12.4" height="16.275" fill="white" />
                                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.82481 6.19922C12.9415 6.19922 18.0585 6.19922 23.1751 6.19922C24.069 6.19922 24.8 6.93017 24.8 7.82408C24.8 12.9407 24.8 18.0578 24.8 23.1744C24.8 24.0683 24.069 24.7992 23.1751 24.7992C18.0585 24.7992 12.9414 24.7992 7.82481 24.7992C6.9309 24.7992 6.19995 24.0683 6.19995 23.1744C6.19995 18.0577 6.19995 12.9407 6.19995 7.82408C6.19995 6.93017 6.9309 6.19922 7.82481 6.19922ZM19.8958 12.912C19.4461 13.3565 18.9963 13.8015 18.5465 14.246C17.8492 13.5566 17.1514 12.8672 16.454 12.1778C16.454 15.5957 16.454 19.014 16.454 22.4319C15.8177 22.4319 15.1818 22.4319 14.5455 22.4319C14.5455 19.0136 14.5455 15.5957 14.5455 12.1774C13.8481 12.8668 13.1503 13.5566 12.4529 14.246C12.0032 13.8015 11.5534 13.3565 11.1037 12.912C12.5692 11.4633 14.0343 10.0149 15.4997 8.56624C16.9652 10.015 18.4303 11.4633 19.8958 12.912H19.8958Z" className="svg-for-menu" />
                                                                                        </svg>
                                                                                        <h4 className='m-0 mx-3'>Upload video from the device</h4>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    sessionVideoUpload.length > 0 &&
                                                                                    <div className="video-upload thumb-upload webinar-upload-part webinar-upload-input webinar-uploaded-list  m-0">
                                                                                        <div className='d-flex justify-content-between mt-4'>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='course-sess-img'>
                                                                                                    <img src={sessionVideoUploadData.Thumbnail} className="collection-thumb" />
                                                                                                </div>
                                                                                                <div className='course-sess-content'>
                                                                                                    <h4>{sessionVideoUploadData.Name}</h4>
                                                                                                    <div className='d-flex align-items-center'>
                                                                                                        <p>{sessionVideoUploadData.Size}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                        <div className="quiz-creator-con d-block">
                                                                            <h4>Description</h4>
                                                                            <textarea id="video-descripation" rows="10" cols="50" form="usrform" onChange={(e) => {
                                                                                setEditSessionDescription(e.target.value)
                                                                                setSessionDescriptionError(false)
                                                                            }}
                                                                                placeholder="Start writing about the session here....." value={editSessionDescription} name="description"></textarea>
                                                                            {sessionDescriptionError && <label className="question-title error-label"><p>Session title should be less than 1000 characters</p></label>}
                                                                        </div>
                                                                        <input type='file' style={{ display: 'none' }} ref={documentRef}
                                                                            accept={`application/*`}
                                                                            multiple
                                                                            onChange={(e) => handleEditDocumentUpload(e.target.files)} />
                                                                        <div className="quiz-creator-con d-block">
                                                                            <h4>Documents</h4>
                                                                            <div className='d-flex gap-1 documents-list'>
                                                                                {editSessionDocumnet && editSessionDocumnet.length > 0 && editSessionDocumnet.map((doc, index) => (
                                                                                    <div key={doc.Id} id = {`${doc.name.trim().length > 25 && "documentError"}`} className="dropfile">
                                                                                        <div className='d-flex align-items-center justify-content-end' onClick={() => handleDocumentDelete(doc.name, doc.Id)}><CloseIcon /></div>
                                                                                        <div className='documents'>
                                                                                            <div className="lms-tabcardspace">
                                                                                                <img
                                                                                                    style={{ width: '30%' }}
                                                                                                    src={extension.find(a => a.exstension.includes(doc.name.split('.').pop().trim()))?.src}
                                                                                                    alt=""
                                                                                                />
                                                                                            </div>
                                                                                            <div className="card-heading card-heading-m">
                                                                                                {doc.name.split('.')[0].trim()}
                                                                                            </div>
                                                                                            <div className="lms-flex-between lms-datespace">
                                                                                                <div className="card-date lms-carddate">
                                                                                                    {moment(doc.CreatedDate).format("DD/MM/YYYY")}
                                                                                                </div>
                                                                                                <button className="doc-button">
                                                                                                    {extension.find(a => a.exstension.includes(doc.name.split('.').pop().trim()))?.exstension || 'Unknown'}
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                                <div className="dropfile">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className="svg-for-menu">
                                                                                        <rect width="31" height="31" rx="8.448" className="svg-for-menu" />
                                                                                        <rect x="8.91211" y="7.36243" width="12.4" height="16.275" fill="white" />
                                                                                        <path className="svg-for-menu" fillRule="evenodd" clipRule="evenodd" d="M7.82408 6.19995C12.9407 6.19995 18.0578 6.19995 23.1744 6.19995C24.0683 6.19995 24.7992 6.9309 24.7992 7.82481C24.7992 12.9415 24.7992 18.0585 24.7992 23.1751C24.7992 24.069 24.0683 24.8 23.1744 24.8C18.0577 24.8 12.9407 24.8 7.82408 24.8C6.93017 24.8 6.19922 24.069 6.19922 23.1751C6.19922 18.0585 6.19922 12.9414 6.19922 7.82481C6.19922 6.9309 6.93017 6.19995 7.82408 6.19995ZM19.8951 12.9127C19.4453 13.3573 18.9956 13.8022 18.5458 14.2468C17.8484 13.5574 17.1506 12.868 16.4532 12.1786C16.4532 15.5964 16.4532 19.0147 16.4532 22.4326C15.817 22.4326 15.1811 22.4326 14.5448 22.4326C14.5448 19.0143 14.5448 15.5964 14.5448 12.1782C13.8474 12.8676 13.1496 13.5574 12.4522 14.2468C12.0024 13.8022 11.5527 13.3573 11.1029 12.9127C12.5684 11.464 14.0335 10.0157 15.499 8.56697C16.9645 10.0157 18.4296 11.464 19.895 12.9127H19.8951Z" />
                                                                                    </svg>
                                                                                    <p className="drop-text"></p>
                                                                                    <p className="drop-text">Upload a document</p>
                                                                                    <label htmlFor="drag-file" className="pri-button" onClick={handleDocumnetOpen}>Choose a file</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {sessionDocumnetError &&
                                                                            <div className='d-flex align-items-center gap-0_5'>
                                                                                <span><InfoOutlinedIcon /></span>
                                                                                <label className="question-title info-label"><p> Document Name should be less than 25 characters</p></label>
                                                                        </div>}
                                                                        <div className="fixed-btns model-padding d-flex justify-content-end">
                                                                            <button className="filter-clear-all mx-4" onClick={handleClose}>Cancel</button>
                                                                            <button className="filter-clear-search" disabled = {longDocsTitleDocs && longDocsTitleDocs.length > 0} onClick={() => handleEditUpcomingSession()}>Save</button>
                                                                        </div>
                                                                    </> : <Spinner />}
                                                            </Box>
                                                        </div>
                                                    </Modal>
                                                </div>)


                                    }

                                </CustomTabPanel>
                                <CustomTabPanel className="mt-3" value={value} index={1}>
                                    <div className='quiz-edit-white-bg webinar-about-us w-100 p-3'>
                                        <p>{courseDetails.Course.Description}</p>
                                    </div>
                                </CustomTabPanel>
                                <CustomTabPanel className="mt-3" value={value} index={2}>
                                    {documentsList && documentsList.length > 0 ? (
                                        <div id="lms-tab-2" className="lms-tabcontent document-close-icon">
                                            <ul className="video-de-docs course-docs-tab"
                                            >
                                                {documentsList && documentsList?.map((document, idx) => {
                                                    return (
                                                        extension.filter(a => a.exstension.includes(document?.Extension?.trim())).length !== 0 &&
                                                        <li
                                                            key={idx}>
                                                            <div onClick={() => { download(document.CdnUrl, document.Title + '.' + document.CdnUrl.split('.').pop()) }}>
                                                                <DocumentCheck Extension={document.Extension} Filename={document.Title ? document.Title : ""} CreatedDate={document.CreatedDate} />
                                                            </div>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    ) : (
                                        <div>No data available</div>
                                    )}
                                </CustomTabPanel>

                                <Dialog open={dialog.Open} onClose={resetDialog}>
                                    <DialogTitle>{dialog.Header}</DialogTitle>
                                    <Divider className="confirmation-popup-divider" />
                                    <DialogContent>
                                        <DialogContentText>
                                            {dialog.Body}
                                        </DialogContentText>
                                    </DialogContent>
                                    <Divider className="confirmation-popup-divider" />
                                    <DialogActions>
                                        <button
                                            className="primary-button ter-btn"
                                            onClick={resetDialog}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="primary-button ter-btn ad-share-btn"
                                            style={{ backgroundColor: '#043677', color: 'white' }}
                                            onClick={() => dialog.modelType === dialogType.modelClose ? handleClose() : handleRemoveOrCancelSession()}
                                        >
                                            {dialog.ButtoneName}
                                        </button>
                                    </DialogActions>
                                </Dialog>
                            </>
                            :
                            <Spinner />
                    }

                    {videoEditScreen()}
                    {quizEditScreen()}

                    {/* Add Session Model */}
                    <Modal
                        open={isAddSessions}
                        onClose={() => setDialog({
                            Open: true,
                            Header: 'Close',
                            Body: 'Do you really want to close creating the sessions tab now. You will lost all the session details you have made till now until you save.',
                            ButtoneName: 'Close',
                            modelType: dialogType.modelClose
                        })}
                        className="modal-pop"
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <div className='add-session-modal'>
                            <Box sx={quizStyle} >
                                <div className="quiz-creator-con">
                                    <div className='d-flex align-items-center'>
                                        <h3 style={{ fontWeight: isSesionsListActive ? 500 : '600' }} className='m-0'> Add Sessions</h3>
                                    </div>
                                    <div><img src="../assets/close-black.png" alt="lms-logo" className="close-icon" onClick={() => setDialog({
                                        Open: true,
                                        Header: 'Close',
                                        Body: 'Do you really want to close creating the sessions tab now. You will lost all the session details you have made till now until you save.',
                                        ButtoneName: 'Close',
                                        modelType: dialogType.modelClose

                                    })} /></div>
                                </div>
                                <hr />
                                {!isSesionsListActive && <>
                                    <div className="quiz-text-input">
                                        <label for="retake">Session Title*</label>
                                        <input type="text" className={sessionNameError && 'error'} placeholder="Type your session title here" value={sessionName} onChange={(e) => {
                                            setSessionName(e.target.value)
                                            setSessionNameError(false)
                                        }} />
                                        {sessionNameError && <label className="question-title error-label"><p>Session title should be 1-50 characters</p></label>}
                                    </div>
                                    <input type='file' style={{ display: 'none' }}
                                        accept="image/*"
                                        ref={sessionThumbnailRef}
                                        onChange={(e) => {
                                            handleSessionThumbnail(e.target.files)
                                            setSessionThumbnailError(false)
                                        }} />
                                    {/* <FormControl sx={{ width: "100% !important" }} className="vertical-form-controll mb-4"> */}
                                    <div className="quiz-text-input">
                                        {sessionThumbnailPreview.length === 0 &&
                                            <div className="video-upload thumb-upload webinar-upload-part" onClick={handleSessionThumbnailClick}>
                                                <svg width="36" height="36" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="64" height="64" rx="17.6" className="svg-for-menu" />
                                                    <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z" className="svg-for-menu" />
                                                </svg>
                                                <h4>Upload thumbnail image</h4>
                                            </div>
                                        }
                                        {sessionThumnbnailError && <label className="question-title error-label"><p>Session thumbnail is required</p></label>}
                                    </div>
                                    {
                                        sessionThumbnailPreview.length > 0 &&
                                        <div className="replace-image-con quiz-text-input">
                                            <img src={sessionThumbnailPreview} className="collection-thumb" />
                                            <button onClick={handleSessionThumbnailClick}>Replace image</button>
                                        </div>
                                    }
                                    <div className="quiz-text-input date-picker">
                                        <label>Date</label>
                                        <div>
                                            <input type='date' min={new Date().toLocaleDateString('en-CA')} value={sessionDate.length > 0 ? new Date(sessionDate).toLocaleDateString('en-CA') : new Date().toLocaleDateString('en-CA')} onChange={(e) => setSessionDate(e.target.value)} onClick={(event) => { event.target.showPicker() }} />
                                        </div>
                                    </div>
                                    <div className="quiz-text-input d-flex w-100 gap-2 date-picker">
                                        <div className='w-50'>
                                            <label >Start Time</label>
                                            <div>
                                                <input type='time' value={sessionStartTime} onChange={(e) => {
                                                    setSessionStartTime(e.target.value)
                                                    setSessionDateError(false)
                                                    setSessionTimeError(false)
                                                }} onClick={(event) => { event.target.showPicker() }} />
                                            </div>
                                        </div>
                                        <div className='w-50'>
                                            <label >End Time</label>
                                            <div>
                                                <input type='time' value={sessionEndTime} onChange={(e) => {
                                                    setSessionEndTime(e.target.value)
                                                    setSessionDateError(false)
                                                    setSessionTimeError(false)
                                                }} onClick={(event) => { event.target.showPicker() }} />
                                            </div>
                                        </div>
                                    </div>
                                    {sessionDateError && <label className="question-title error-label"><p>Start Time cannot be after End time</p></label>}
                                    {sessionTimeError && <label className="question-title error-label"><p>Selected Time cannot be less than current time</p></label>}
                                    <div className="quiz-creator-con d-block">
                                        <h4>Description</h4>
                                        <textarea id="video-descripation" rows="10" cols="50" form="usrform" value={sessionDescription} placeholder="Start writing about the session here....." name="description" onChange={(e) => {
                                            setSessionDescription(e.target.value)
                                            setSessionDescriptionError(false)
                                        }} ></textarea>
                                        {sessionDescriptionError && <label className="question-title error-label"><p>Session title should be less than 1000 characters</p></label>}
                                    </div>
                                    <input type='file' style={{ display: 'none' }} ref={documentRef}
                                        accept={`application/*`}
                                        multiple
                                        onChange={(e) => handleDocumentUpload(e.target.files)} />
                                    <div className="quiz-creator-con d-block">
                                        <h4>Documents</h4>
                                        <div className='d-flex gap-1 documents-list'>
                                            {sessionDocumnet && sessionDocumnet.length > 0 &&
                                                sessionDocumnet.map((doc, index) => (
                                                    <div
                                                        key={doc.Id || index}
                                                        id = {`${doc.name.trim().length > 25 && "documentError"}`}
                                                        className='dropfile'
                                                    >
                                                        <div className='d-flex align-items-center justify-content-end' onClick={() => handleDocumentDelete(doc.name, doc.Id)}><CloseIcon /></div>
                                                        <div className="documents">
                                                            <div className="lms-tabcardspace">
                                                                <img
                                                                    style={{ width: "30%" }}
                                                                    src={extension.find(a => a.exstension.includes(doc.name.split('.').pop().trim()))?.src || "default-image.png"}
                                                                    alt="Document Icon"
                                                                />
                                                            </div>
                                                            <div className="card-heading card-heading-m">
                                                                {doc.name.split('.').slice(0, -1).join('.').trim()}
                                                            </div>
                                                            <div className="lms-flex-between lms-datespace">
                                                                <div className="card-date lms-carddate">
                                                                    {moment(doc.CreatedDate).format("DD/MM/YYYY")}
                                                                </div>
                                                                <button className="doc-button">
                                                                    {extension.find(a => a.exstension.includes(doc.name.split('.').pop().trim()))?.exstension || "Unknown"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            <div className="dropfile">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none" className="svg-for-menu">
                                                    <rect width="31" height="31" rx="8.448" className="svg-for-menu" />
                                                    <rect x="8.91211" y="7.36243" width="12.4" height="16.275" fill="white" />
                                                    <path
                                                        className="svg-for-menu"
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M7.82408 6.19995C12.9407 6.19995 18.0578 6.19995 23.1744 6.19995C24.0683 6.19995 24.7992 6.9309 24.7992 7.82481C24.7992 12.9415 24.7992 18.0585 24.7992 23.1751C24.7992 24.069 24.0683 24.8 23.1744 24.8C18.0577 24.8 12.9407 24.8 7.82408 24.8C6.93017 24.8 6.19922 24.069 6.19922 23.1751C6.19922 18.0585 6.19922 12.9414 6.19922 7.82481C6.19922 6.9309 6.93017 6.19995 7.82408 6.19995ZM19.8951 12.9127C19.4453 13.3573 18.9956 13.8022 18.5458 14.2468C17.8484 13.5574 17.1506 12.868 16.4532 12.1786C16.4532 15.5964 16.4532 19.0147 16.4532 22.4326C15.817 22.4326 15.1811 22.4326 14.5448 22.4326C14.5448 19.0143 14.5448 15.5964 14.5448 12.1782C13.8474 12.8676 13.1496 13.5574 12.4522 14.2468C12.0024 13.8022 11.5527 13.3573 11.1029 12.9127C12.5684 11.464 14.0335 10.0157 15.499 8.56697C16.9645 10.0157 18.4296 11.464 19.895 12.9127H19.8951Z"
                                                    />
                                                </svg>
                                                <p className="drop-text"></p>
                                                <p className="drop-text">Upload a document</p>
                                                <label htmlFor="drag-file" className="pri-button" onClick={handleDocumnetOpen}>
                                                    Choose a file
                                                </label>
                                            </div>
                                        </div>
                                        {sessionDocumnetError &&
                                            <div className='d-flex align-items-center gap-0_5'>
                                                <span><InfoOutlinedIcon /></span>
                                                <label className="question-title info-label"><p> Document Name should be less than 25 characters</p></label>
                                            </div>}


                                        {/* <div className='document-after-select'>
                                          
                                            <h4> {sessionDocumnet.length > 0 ? sessionDocumnet[0].name : "Upload Document"}</h4>
                                            <div className='d-flex align-items-center'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleDocumnetOpen}>
                                                    <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M7 10L12 15L17 10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M12 15V3" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleDocumentRemove}>
                                                    <path d="M18 6L6 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                    <path d="M6 6L18 18" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="fixed-btns model-padding d-flex justify-content-end">
                                        <button className="filter-clear-all mx-4" onClick={() => setDialog({
                                            Open: true,
                                            Header: 'Close',
                                            Body: 'Do you really want to close creating the sessions tab now. You will lost all the session details you have made till now until you save.',
                                            ButtoneName: 'Close',
                                            modelType: dialogType.modelClose
                                        })}>Cancel</button>
                                        <button className="filter-clear-search" disabled={longTitleDocs && longTitleDocs.length > 0} onClick={() => { !editSessionOnList ? handleAddSessionToList() : handleEditSessionList() }}>Save &  Next</button>
                                    </div>
                                </>}
                                {isSesionsListActive &&
                                    (!modalLoader ? <>
                                        <div className='list-items-padd'>
                                            <ul>
                                                {
                                                    sessions.map((session, idx) => {
                                                        return (
                                                            <li className='course-session-list' key={idx}>
                                                                <div className='d-flex justify-content-between'>
                                                                    <div className='d-flex align-items-center'>
                                                                        <div className='course-sess-img'>
                                                                            <img src={session.ThumbnailPreview} className="collection-thumb" />
                                                                        </div>
                                                                        <div className='course-sess-content'>
                                                                            <h4>{session.Title}</h4>
                                                                            <div className='d-flex align-items-center'>
                                                                                <p>{moment(session.Date).format('Do MMM , YYYY')}</p>
                                                                                <p>{`${moment(session.StartTime).format('hh:mm a')} - ${moment(session.EndTime).format('hh:mm a')}`}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex align-items-center gap-1'>
                                                                        <svg id="edit-icon" xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none" onClick={() => handleEditSessionOnList(session, idx)}>
                                                                            <rect className="edit-icon-stroke" x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" stroke-width="0.9"></rect>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.5466 10.6165C24.1197 10.1897 23.4567 10.1717 23.0542 10.5742L12.9044 20.7241L12.0771 25.1637C11.9847 25.6596 12.4325 26.1443 12.9687 25.9989L16.9488 24.9202L17.2025 24.7738L27.2281 14.7481C27.6233 14.353 27.6071 13.677 27.1858 13.2558L24.5466 10.6165ZM16.4302 23.7886L13.4261 24.6028L14.0314 21.3544L22.2483 13.1376L23.8225 11.5634L26.239 13.9799L24.6903 15.5285L16.4302 23.7886Z" fill="#B8B8B8"></path>
                                                                            <path d="M13.2749 28.9999H21.6055C22.0567 28.9999 22.4224 28.6922 22.4224 28.3127C22.4224 27.9332 22.0567 27.6255 21.6055 27.6255H13.2749C12.8237 27.6255 12.458 27.9332 12.458 28.3127C12.458 28.6922 12.8237 28.9999 13.2749 28.9999Z" fill="#B8B8B8"></path>
                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7206 20.7187L12.9834 24.757L17.0856 24.0342L26.772 14.0481L23.7765 11.2349L13.7206 20.7187Z" className="edit-icon-svg"></path></svg>
                                                                        <svg id="del-icon" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemoveSessionOnList(idx)}><rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" className="del-stroke" stroke-width="0.9"></rect>
                                                                            <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white"></path>
                                                                            <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                                            <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" className="del-svg "></path>
                                                                            <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white"></path><path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white" ></path>
                                                                            <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            <div className="add-session-btn"><button className="primary-button sec-btn" onClick={() => { setSetionsList(false), handleReset() }}>+ Add New Session</button></div>
                                        </div>
                                        <div className="fixed-btns model-padding d-flex justify-content-end">
                                            <button className="filter-clear-all mx-4" onClick={() => setDialog({
                                                Open: true,
                                                Header: 'Close',
                                                Body: 'Do you really want to close creating the sessions tab now. You will lost all the session details you have made till now until you save.',
                                                ButtoneName: 'Close',
                                                modelType: dialogType.modelClose
                                            })}>Cancel</button>
                                            <button className="filter-clear-search" onClick={handleCreateSessions}>Save</button>
                                        </div>
                                    </>
                                        : <Spinner />)
                                }
                            </Box>
                        </div>
                    </Modal >



                </div >
                : <TableContainer>
                    {
                        selectedCollection.length > 0 ?
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead >
                                    <TableRow>
                                        <TableCell className='table-text-align table_cell_design' style={{ width: 10 }}><Checkbox className='checkbox table-collection-checkbox' onChange={(e) => {
                                            let checked = e.target.checked;
                                            setSelectedCollection(
                                                selectedCollection.map(d => {
                                                    d.select = checked;
                                                    return d;
                                                })
                                            );
                                            props.selection(selectedCollection.filter(i => i.select))
                                        }} /></TableCell>
                                        {
                                            props.columns.map((i, index) => { return <TableCell align={i.align} className='table_cell_colour table_cell_design' style={{ width: i.width }} key={index} >{i.headerName}</TableCell> }
                                            )
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {selectedCollection.map((i, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            className='course-table-row'
                                        >
                                            <TableCell className='table-text-align table_cell_design'><Checkbox className='checkbox table-collection-checkbox' value={i.Id} checked={i.select} onChange={(e) => {
                                                let checked = e.target.checked;
                                                setSelectedCollection(
                                                    selectedCollection.map(data => {
                                                        if (i.Id === data.Id) {
                                                            data.select = checked;
                                                        }
                                                        return data;
                                                    })
                                                );
                                                props.selection(selectedCollection.filter(i => i.select))
                                            }} /></TableCell>
                                            <TableCell className='table-text-align table_cell_design table_cell_colour' >
                                                <div
                                                    className=" d-flex align-items-center"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {
                                                        !i.CdnVideoUrl ?
                                                            <div className='thumb-line-svg'>
                                                                <svg
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    className='tumb-svg'
                                                                >
                                                                    <path
                                                                        d="M23.1429 0H0.857143C0.383756 0 0 0.383756 0 0.857143V23.1429C0 23.6162 0.383756 24 0.857143 24H23.1429C23.6162 24 24 23.6162 24 23.1429V0.857143C24 0.383756 23.6162 0 23.1429 0Z"
                                                                        fill="#B8B8B8"
                                                                    />
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        clipRule="evenodd"
                                                                        d="M9.9974 7.3419C9.9974 7.15271 9.8441 7 9.65419 7H7.34321C7.1533 7 7 7.15271 7 7.3419V9.64515C7 9.83433 7.1533 9.98705 7.34321 9.98705H9.65533C9.84524 9.98705 9.99854 9.83433 9.99854 9.64515V7.3419H9.9974ZM11.3439 9.98705H15.3447H16.1549H17.6568C17.8467 9.98705 18 9.83433 18 9.64515V7.3419H17.9989C17.9989 7.15271 17.8456 7 17.6556 7H16.1549H15.3447H11.3439C11.154 7 11.0007 7.15271 11.0007 7.3419V9.64515C11.0007 9.83433 11.154 9.98705 11.3439 9.98705ZM9.9974 11.3489C9.9974 11.1598 9.8441 11.007 9.65419 11.007H7.34321C7.1533 11.007 7 11.1598 7 11.3489V13.6511C7 13.8402 7.1533 13.993 7.34321 13.993H9.65533C9.84524 13.993 9.99854 13.8402 9.99854 13.6511V11.3489H9.9974ZM15.3447 13.993H11.3439C11.154 13.993 11.0007 13.8402 11.0007 13.6511V11.3489C11.0007 11.1598 11.154 11.007 11.3439 11.007H15.3447H16.1549H17.6556C17.8456 11.007 17.9989 11.1598 17.9989 11.3489H18V13.6511C18 13.8402 17.8467 13.993 17.6568 13.993H16.1549H15.3447ZM7.34321 15.013H9.65419C9.8441 15.013 9.9974 15.1668 9.99854 15.3548V17.6581C9.99854 17.8473 9.84524 18 9.65533 18H7.34321C7.1533 18 7 17.8473 7 17.6581V15.3548C7 15.1657 7.1533 15.013 7.34321 15.013ZM15.3447 15.013H11.3439C11.154 15.013 11.0007 15.1657 11.0007 15.3548V17.6581C11.0007 17.8473 11.154 18 11.3439 18H15.3447H16.6549H17.6568C17.8467 18 18 17.8473 18 17.6581V15.3548C17.9989 15.1668 17.8456 15.013 17.6556 15.013H16.6549H15.3447Z"
                                                                        fill="white"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            :
                                                            <div className='thumb-line-svg'>
                                                                <svg className="MuiTab-iconWrapper tumb-svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" ><g id="Visual-Design-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="Group-8-Copy"><path d="M23.1428571,0 L0.857142857,0 C0.383755929,0 0,0.383755929 0,0.857142857 L0,23.1428571 C0,23.6162441 0.383755929,24 0.857142857,24 L23.1428571,24 C23.6162441,24 24,23.6162441 24,23.1428571 L24,0.857142857 C24,0.383755929 23.6162441,0 23.1428571,0 Z M16.4228571,12.7457143 L9,17.0314286 C8.73358013,17.1799236 8.40927701,17.1799236 8.14285714,17.0314286 C7.87659926,16.8777077 7.71306514,16.5931584 7.71427893,16.2857143 L7.71427893,7.71428571 C7.71306514,7.40684164 7.87659926,7.12229226 8.14285714,6.96857143 C8.40998709,6.82283454 8.73287005,6.82283454 9,6.96857143 L16.4228571,11.2542857 C16.6881748,11.408932 16.851344,11.6929024 16.851344,12 C16.851344,12.3070976 16.6881748,12.591068 16.4228571,12.7457143 L16.4228571,12.7457143 Z" id="Shape" fill="#B8B8B8" fill-rule="nonzero"></path><g id="Group" transform="translate(7.200000, 5.600000)"></g></g></g></svg>
                                                            </div>

                                                    }

                                                    <div className="thumbnail-img">
                                                        <div className='collection-tumb col-10'><img src={i.ThumbnailUrl} className="collection-thumb" /></div>
                                                    </div>
                                                    <span className='w-50' onClick={() => {
                                                        if (window.location.pathname.split('/').pop() === courseCollectionPath) {
                                                            handleBreadcomClick(i.Id)
                                                        }
                                                        else if (window.location.pathname.split('/').pop() === videoCollectionPath) {
                                                            navigate("/videos/" + i.Id)
                                                        }
                                                    }}>{window.location.pathname.split('/').pop() === courseCollectionPath? i.Name : i.Title}</span>
                                                </div>
                                            </TableCell>
                                            {
                                                window.location.pathname.split('/').pop() === courseCollectionPath &&
                                                <>
                                                    <TableCell align='center' className='table_cell_design table_cell_colour'>
                                                        <div
                                                            className="align-items-center"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <button className={`live-course-box ${CourseTypes[i.CourseType - 1]?.replace(/\s+/g, '') + "Bg"}`}>{CourseTypes[i.CourseType - 1]}</button>
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align='center' className='table_cell_design table_cell_colour'>
                                                        <div
                                                            className="align-items-center"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {i.ContentCount}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align='center' className='table_cell_design table_cell_colour'>
                                                        <div
                                                            className="align-items-center"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            {i.QuizCount}
                                                        </div>
                                                    </TableCell>
                                                </>

                                            }
                                            <TableCell align='center' className='table_cell_design table_cell_colour'>
                                                <div
                                                    className="align-items-center"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {i.CategoryName}
                                                </div>
                                            </TableCell>
                                            <TableCell align='center' className='table_cell_design table_cell_colour'>{moment(i.CreatedDate).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell className='table_cell_design table_cell_colour'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='d-flex collection-profile'>
                                                        {i.ProfileImageUrl === '' || i.ProfileImageUrl === null ?
                                                            <img src={GenerateProfilePic(i.CreatedByName)} className='profile-avatar' /> :
                                                            <img src={i.ProfileImageUrl} className='profile-avatar' />}
                                                    </div>
                                                    <p>{i.CreatedByName}</p>
                                                </div>
                                            </TableCell>
                                            <Modal
                                                open={open}
                                                onClose={handleClose}
                                                className="modal-pop"
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <div>
                                                    <Box sx={quizStyle}>
                                                        {!modalLoader ?
                                                            <>
                                                                <div className="new-quiz-con">
                                                                    <h3>{Link_Quiz}</h3>
                                                                    <div><img src="../assets/close-black.png" alt="lms-logo" className="close-icon" onClick={handleClose} /></div>
                                                                </div>
                                                                <DragDropContext onDragEnd={handleDrop}>
                                                                    <List className="new-quiz-list">

                                                                        <Droppable droppableId="list-container">
                                                                            {(provided) => (
                                                                                <div
                                                                                    className="list-container"
                                                                                    {...provided.droppableProps}
                                                                                    ref={provided.innerRef}
                                                                                >
                                                                                    {videoListMock.map((e, index) => (
                                                                                        <Draggable key={index} draggableId={`draggable ${index}`} index={index}>
                                                                                            {(provided) => (
                                                                                                <div
                                                                                                    className="item-container"
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    {...provided.draggableProps}
                                                                                                >
                                                                                                    <NewQuizLinkItem data={e} />

                                                                                                </div>
                                                                                            )}
                                                                                        </Draggable>
                                                                                    ))}
                                                                                    {provided.placeholder}
                                                                                </div>
                                                                            )}
                                                                        </Droppable>
                                                                    </List>
                                                                </DragDropContext>
                                                                <div className="new-quiz-con py-0">
                                                                    <p>Assign Quiz</p>
                                                                </div>
                                                                <div className="new-quiz-con pt-2">
                                                                    <div className="parent-link-quiz-search  lms-flex-align-center">
                                                                        <input
                                                                            id="link-quiz-search"
                                                                            placeholder="Search"
                                                                            type="text"
                                                                            value={quizSearch}
                                                                            onChange={(e) => setQuizSearch(e.target.value)}
                                                                        />
                                                                        <img src="../assets/search.png" alt="lms-logo" />
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    {isQuizLoader ? (
                                                                        <Spinner />
                                                                    ) : (
                                                                        getAllQuiz.length > 0 ? (
                                                                            <ul className='quiz-list-ul'>
                                                                                {getAllQuiz.map((quizItem, index) => {
                                                                                    const isSelected = selectedQuizList.some(i => i.Id === quizItem.Id);
                                                                                    return (
                                                                                        <li className='quiz-list-mb item-container d-flex gap-1 align-items-center' key={index}>
                                                                                            {isSelected && <img
                                                                                                src='../assets/success-icon.png'
                                                                                                alt='Success-Icon'
                                                                                                onClick={() => handleToggleQuiz(quizItem)}
                                                                                            />}
                                                                                            <div className={`m-0 w-100 newQuizLinkItem lms-flex-align-center justify-content-between ${isSelected && 'selectedQuiz'}`}>
                                                                                                <p>{quizItem.Title}</p>
                                                                                                <img
                                                                                                    src={isSelected ? "../assets/unshare-icon.png" : "../assets/share-icon.png"}
                                                                                                    alt={isSelected ? "Unshare icon" : "Share icon"}
                                                                                                    onClick={() => handleToggleQuiz(quizItem)}
                                                                                                />
                                                                                            </div>
                                                                                        </li>
                                                                                    );
                                                                                })}
                                                                            </ul>
                                                                        ) : (
                                                                            <div className='new-quiz-con no-quiz py-0'>
                                                                                <h2 className='text-center'>No Quizzes Found</h2>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                                <div className="fixed-btns d-flex justify-content-end gap-2 model-padding">
                                                                    <button className="filter-clear-all" onClick={handleClose}>Cancel</button>
                                                                    <button className="filter-clear-search" disabled = {selectedQuizList && selectedQuizList.length === 0} onClick={addQuizToCourse}>Update</button>
                                                                </div>
                                                            </>
                                                            :
                                                            <Spinner />
                                                        }
                                                    </Box>


                                                </div>
                                            </Modal>

                                            {/* Video Edit Modal*/}
                                            {window.location.pathname.split('/').pop() === videoCollectionPath &&
                                                videoEditScreen()
                                            }

                                            {/* Course Edit Modal*/}
                                            {
                                                window.location.pathname.split('/').pop() === courseCollectionPath &&
                                                <Modal
                                                    open={editOpen}
                                                    onClose={handleClose}
                                                    className="modal-pop"
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description"
                                                >
                                                    <Box sx={quizStyle}>
                                                        {!modalLoader ?
                                                            courseEditScreen()
                                                            :
                                                            <Spinner />
                                                        }
                                                        <div className="fixed-btns d-flex justify-content-end gap-2 model-padding">
                                                            <button className="filter-clear-all" onClick={handleClose}>Cancel</button>
                                                            <button className="filter-clear-search" onClick={() => courseType === CourseType.Online ? handleEditLiveCourse() : handleEditOfflineCourse()}>Update</button>
                                                        </div>
                                                    </Box>
                                                </Modal>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            :
                            <NoCourseAndVideos />
                    }
                </TableContainer>
            }
        </>
    )
}

const mapStateToProps = (state, ownProps) => {
    return {
        questionLevels: state.quiz.QuestionLevels,
        playlistCategories: state.category.playlistCategories,
    };
};

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(
        {
            fetchAdminVideoAction: fetchAdminVideo,
            fetchAdminCourseAction: fetchAdminCourse,
            fetctQuestionLevel: fetchQuestionLevel,
            fetchQuizAction: fetchQuiz,
            postTutorialDocumentAction: postTutorialDocument,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTableLayout);
