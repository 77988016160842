import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { fetchQuiz } from "../store/actions/admin";
import { bindActionCreators } from "redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Tooltip from '@mui/material/Tooltip';
import { CreateCourseContext } from "../Context/CreateCourse";

const AttachQuiz = (props) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedQuiz, setSelectedQuiz] = useState(null);
    const [droppedQuizzes, setDroppedQuizzes] = useState([]);
    const [videoDetails, setVideoDetails] = useState([])

    const { selectedQuizes, addQuiz, deleteQuiz } = useContext(CreateCourseContext);

    useEffect(() => {
        props.fetchQuizAction({
            PageNumber: 1,
            PageSize: 20
        });
    }, [props.fetchQuizAction]);


    const quizzes = props.questionLevels?.Quiz?.Quiz || [];

    const filteredQuizzes = quizzes.filter((quiz) =>
        quiz.Title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleQuizClick = (quiz) => {
        setSelectedQuiz(quiz);
        setDroppedQuizzes((prev) => {
            if (!prev.some((droppedQuiz) => droppedQuiz.Id === quiz.Id)) {
                return [...prev, { ...quiz, type: "quiz" }];
            }
            return prev;
        });
        addQuiz(quiz);
    };


    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId === "quizzes" && destination.droppableId === "dropped") {
            const quiz = filteredQuizzes[source.index];
            if (!droppedQuizzes.some((droppedQuiz) => droppedQuiz.Id === quiz.Id)) {
                setDroppedQuizzes((prev) => [...prev, quiz]);
            }
        }
    };

    const handleDeleteQuiz = (quizId) => {
        setDroppedQuizzes(droppedQuizzes.filter((quiz) => quiz.Id !== quizId));
        deleteQuiz(quizId);
    };

    useEffect(() => {
        const newVideoDetails = props.videoDataSets.map((i) => ({
            Title: i.videoTitle,
            Id: i.id,
            Thumbnail: i.thumbnailPreview,
            Type: "video"
        }));

        setVideoDetails(newVideoDetails);
        setDroppedQuizzes((prev) => [...prev, ...newVideoDetails]);
    }, [props.videoDataSets, props.thumbnailPreview]);

    // useEffect(() => {
    //     console.log(props.videoDataSets)
    //     if (props.videoDataSets.length > 0) {
    //         setVideoDetails(props.videoDataSets.map(d => {
    //             return {
    //                 Title: d.videoTitle,
    //                 Id: d.id,
    //                 Thumbnail: d.thumbnailPreview,
    //                 Type: "video"
    //             }
    //         }))
    //     }
    // }, [props.videoDataSets])


    useEffect(() => {
        props.setQuizCourseList(droppedQuizzes);
    }, [droppedQuizzes, props.setQuizCourseList]);



    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <div className="d-flex gap-5 mt-3 dragable-Quiz-con">
                <div className="w-50 px-5 py-4 upload-white-bg quiz-list-cont">
                    <h4 className="mb-5 studio-title">Quizzes</h4>
                    <input
                        className="search-image mb-3"
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Search quizzes..."
                    />

                    <Droppable droppableId="quizzes">
                        {(provided) => (
                            <ul ref={provided.innerRef} {...provided.droppableProps}>
                                {filteredQuizzes.length > 0 ? filteredQuizzes.map((quiz, index) => {
                                    const isDisabled = quiz.QuizLevels.every(level => level.AssignedQuestions === 0);

                                    return (
                                        <Draggable key={quiz.Id} draggableId={quiz.Id.toString()} index={index} isDragDisabled={isDisabled}>
                                            {(provided) => (
                                                <Tooltip title={isDisabled ? "No questions on this quiz" : ''}>
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={`quiz-list dragable-Quiz-list ${selectedQuiz?.Id === quiz.Id ? 'selected' : ''} ${isDisabled ? 'disabled' : ''}`}
                                                        onClick={() => !isDisabled && handleQuizClick(quiz)}
                                                        style={{
                                                            ...provided.draggableProps.style,
                                                            backgroundColor: selectedQuiz?.Id === quiz.Id ? '#f0f0f0' : 'transparent',
                                                            opacity: isDisabled ? 0.5 : 1,
                                                            cursor: isDisabled && "not-allowed"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                pointerEvents: isDisabled ? 'none' : 'auto'
                                                            }}
                                                        >
                                                            <h3>{quiz.Title}</h3>
                                                        </div>
                                                    </li>
                                                </Tooltip>
                                            )}
                                        </Draggable>
                                    );
                                }) : (
                                    <li><h3>No quizzes found</h3></li>
                                )}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </div>
                <div className="w-50 px-5 py-4 upload-white-bg">
                    <h4 className="mb-5 studio-title">Videos</h4>
                    <Droppable droppableId="dropped">
                        {(provided) => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={{
                                    minHeight: '500px'
                                }}
                            >
                                <ul>
                                    {props.selectedQuiz && props.selectedQuiz.map((quiz, index) => (
                                        <li key={quiz.Id} className="quiz-list">
                                            <div className="d-flex gap-2 align-items-center">
                                                <svg width="20" height="18" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0.482788V2.46123H14.9541V0.482788H0ZM0 6.01078V7.98922H14.9541V6.01078H0ZM0 11.5388V13.5172H14.9541V11.5388H0Z" fill="black" />
                                                </svg>
                                                <img src={`${quiz.Thumbnail ? quiz.Thumbnail : "assets/quiz-img.jpg"}`} alt="Quiz Img" />
                                                <h3 className="quiz-video-title">{quiz.Title}</h3>
                                            </div>
                                            <svg onClick={() => handleDeleteQuiz(quiz.Id)} id="del-icon" width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.45" y="0.45" width="38.1" height="38.1" rx="6.75" class="del-stroke" stroke-width="0.9"></rect>
                                                <path d="M16.8939 24.8859C16.9169 24.8859 16.9169 24.8859 16.9399 24.8859C17.3313 24.8859 17.6306 24.5779 17.6306 24.1751V17.8008C17.6306 17.398 17.3313 17.0899 16.9399 17.0899C16.5716 17.0662 16.2723 17.3743 16.2493 17.7534C16.2493 17.7771 16.2493 17.7771 16.2493 17.8008V24.1751C16.2263 24.5542 16.5256 24.8622 16.8939 24.8859Z" fill="white"></path>
                                                <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                                <path d="M26.3324 13.5355H22.718V12.8957C22.718 11.8531 21.8892 11 20.8763 11H17.1468C16.1338 11 15.305 11.8531 15.305 12.8957V13.5355H11.6906C11.3223 13.5592 11.023 13.8672 11 14.2464C11 14.6492 11.2993 14.9573 11.6906 14.9573H12.8187V26.6869C12.8187 27.7295 13.6475 28.5826 14.6604 28.5826H23.3396C24.3525 28.5826 25.1813 27.7295 25.1813 26.6869V14.9573H26.3094C26.7007 14.9573 27 14.6492 27 14.2464C27 13.8672 26.7007 13.5592 26.3324 13.5355Z" class="del-svg"></path>
                                                <path d="M16.6855 12.8958C16.6855 12.6351 16.8927 12.4219 17.146 12.4219H20.8755C21.1287 12.4219 21.3359 12.6351 21.3359 12.8958V13.5356H16.6855V12.8958Z" fill="white"></path>
                                                <path d="M16.8939 24.8862C16.9169 24.8862 16.9169 24.8862 16.9399 24.8862C17.3313 24.8862 17.6306 24.5781 17.6306 24.1753V17.801C17.6306 17.3982 17.3313 17.0901 16.9399 17.0901C16.5716 17.0665 16.2723 17.3745 16.2493 17.7536C16.2493 17.7773 16.2493 17.7773 16.2493 17.801V24.1753C16.2263 24.5544 16.5256 24.8625 16.8939 24.8862Z" fill="white"></path>
                                                <path d="M21.0832 24.8859C21.4516 24.9096 21.7509 24.6015 21.7739 24.2224C21.7739 24.1987 21.7739 24.1987 21.7739 24.175V17.8007C21.7969 17.4216 21.4976 17.1135 21.1293 17.0898C21.1062 17.0898 21.1062 17.0898 21.0832 17.0898C20.6919 17.0898 20.3926 17.3979 20.3926 17.8007V24.175C20.3926 24.5778 20.6919 24.8859 21.0832 24.8859Z" fill="white"></path>
                                            </svg>
                                        </li>
                                    ))}
                                    {provided.placeholder}
                                </ul>
                            </div>
                        )}
                    </Droppable>
                </div>
            </div>
        </DragDropContext>
    );
}

const mapStateToProps = (state) => {
    return {
        questionLevels: state.quiz,
        playlistCategories: state.category.playlistCategories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            fetchQuizAction: fetchQuiz
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachQuiz);
