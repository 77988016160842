import { createContext, useState } from "react";

export const CreateCourseContext = createContext();

export const CreateCourseProvider = ({ children }) => {
    const [selectedQuizes, setSelectedQuizes] = useState([]);

    const addQuiz = quiz => {
        const q = selectedQuizes.find(d => d.Id == quiz.Id);
        if (!q) {
            setSelectedQuizes(prev => [...prev, {...quiz}]);
        }
    }

    const deleteQuiz = Id => {
        const quizes = selectedQuizes.filter(d => d.Id != Id);
        setSelectedQuizes([...quizes]);
    }

    const resetSelectedQuizes = () => {
        setSelectedQuizes([]);
    }

    return <CreateCourseContext.Provider value={{selectedQuizes, addQuiz, deleteQuiz, resetSelectedQuizes }}>
        {children}
    </CreateCourseContext.Provider>
}
