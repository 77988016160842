import React, { useEffect, useState, useRef, useContext } from "react";
import BreadcrumbsNav from "../Common/BreadcrumbsNav";
import HorizontalStepper from "../../../components/Common/HorizontalStepper";
import UploadedVideosEdit from "../Common/UploadedVideosEdit";
import UploadCourse from "./UploadCourse";
import { Backdrop, Alert } from '@mui/material';
import LoadingScreen from "../../../components/Common/LoadingScreen";
import AttachQuiz from "../Common/AttachQuiz";
import CoursePreviews from "../Common/CoursePreviews";
import CourseCreation from "../Common/CourseCreation";
import { documentPath, CourseType, tempVideoPath, thumbnailPath, uploadedVideoStateUploadedToTemp, hlsVideoExtension } from "../Common/Constants";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postTutorialDocument, updateDocumentVideo, updateTutorialVideo } from "../store/actions/admin";
import API from "../utils/API";
import { default as UserAPI } from "../../../utils/API";
import { useNavigate } from "react-router-dom";
import Uploadwithsas from "../Common/Uploadwithsas";
import Spinner from "../../../components/Common/Spinner";
import { CreateCourseContext } from "../Context/CreateCourse";
import UploadVideowithsas from "../Common/UploadVideoWithSas";

const CreateOfflineCourse = (props) => {

    const [activeStep, setActiveStep] = useState(0);
    const [selectedVideo, setSelectedVideo] = useState({});
    const [modalLoader, setModalLoader] = useState(false);
    const [successResposnse, setSuccessResposnse] = useState('');
    const [alert, setAlert] = useState({
        active: false,
        severity: '',
        message: '',
        loading: false
    })
    const [getFiles, setGetFiles] = useState([]);
    const [editedVideoData, setEditedData] = useState([])
    const [getQuizCourseList, setQuizCourseList] = useState([]);
    const [getThumbnail, setGetThumbnail] = React.useState('')
    const [mockFiles, setMockfiles] = useState([])
    const [thumbnailPreview, setPrivewThumbnail] = useState('');
    const [selectedQuiz, setSelectedQuiz] = useState([]);
    const [videolength, setVideoLength] = React.useState(0);
    const [videoPoints, setVideoPoints] = useState(null);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const [isFormFilled, setFormFilled] = useState(false);
    const [isVideoUploaded, setVideoUploaded] = useState(false)
    const childRef = useRef();
    const navigate = useNavigate();
    const [EditedTitle, setEditedVideoTitle] = useState('')
    const [EditedDescription, setEditedDescription] = useState('')
    const [EditedTranscript, setEditedTranscript] = useState('')
    const [EditedVideoPoints, setEditedVideoPoints] = useState('')
    const [document, setDocument] = useState({})
    const [documentFile, setDocumentFile] = useState({})
    const [documentResponce, setDocumentResponce] = useState({})
    const [videoThumbnail, setVideoThumbnail] = useState(null)
    const [videoThumPreview, setVideoThumPreview] = useState('')
    const [selectedVideoId, setSelectedId] = useState(null);
    const [formTrue, setFormTrue] = useState(false)
    const [videoDataSets, setVideoDataSets] = useState([]);
    const [courseDetails, setCourseDetails] = useState({
        courseName: '',
        courseDescription: '',
        thumbnail: null,
        thumbnailPreview: '',
        selectedCategory: null,
        tags: '',
    });

    const updateCourseDetails = (key, value) => {
        setCourseDetails((prevDetails) => ({
            ...prevDetails,
            [key]: value,
        }));
    };

    const { selectedQuizes, resetSelectedQuizes } = useContext(CreateCourseContext);

    useEffect(() => resetSelectedQuizes(), []);

    useEffect(() => {
        if (getQuizCourseList.length == 1) {
            setQuizCourseList([...getQuizCourseList, ...selectedQuizes]);
        }
    }, [getQuizCourseList]);

    const handleSuceessResponse = (e) => {
        setSuccessResposnse(e)
    }

    const handleBack = () => {
        setActiveStep((prevStep) => {
            if (prevStep === 1) {
                setFormFilled(false);
            }
            return prevStep - 1;
        });
    }


    useEffect(() => {
        if (activeStep === 1 && mockFiles.length > 0) {
            setVideoUploaded(true);
        } else {
            setVideoUploaded(false);
        }
    }, [activeStep, mockFiles]);


    const handleNext = () => {
        switch (activeStep) {
            case 3: {
                handleCreatePlaylist();
                break;
            }
            default: {
                setActiveStep(prevStep => prevStep + 1);
                break;
            }
        }
    };

    const handleThumbnailPreview = (setPreview) => {
        setPrivewThumbnail(setPreview)
    }

    const handleFormFilled = (data) => {
        setFormFilled(data)
    }

    const handleBackDropClose = () => {
        setAlert({
            active: false,
            severity: '',
            message: '',
            loading: false
        })
    };

    useEffect(() => {
        const dataMockArray = mockFiles.flat();
        const dataArray = dataMockArray.map((i) => ({
            SasToken:i.SasToken,
            Name:i.name,
            fileId: i.FileId,
            id: i.VideoId,
            videoTitle: "",
            videoDescription: "",
            videoTranscript: "",
            videoCategoryId: courseDetails?.selectedCategory?.Id,
            thumbnailPreview: "",
            videoDocuments: "",
            documentResponse:"",
            videoPoints: "",
            thumbnailFile: ""
        }));
 
        setVideoDataSets(dataArray);
 
    }, [mockFiles]);


    useEffect(() => {
        setTimeout(() => {
            handleBackDropClose();
        }, 4000);
    }, [alert.active]);

    const addFileToMockFiles = (newFile) => {
        setMockfiles((prevFiles) => [...prevFiles, ...newFile]);
    };

    const handleCreatePlaylist = async (e) => {
        setModalLoader(true);

        const formData = new FormData();
        formData.append('Title', courseDetails?.courseName.trim() || '');
        formData.append('Description', courseDetails?.courseDescription.trim() || '');
        formData.append('PlaylistCategoryId', courseDetails?.selectedCategory.Id || '');
        formData.append('Tags', courseDetails?.tags || '');
        formData.append('filecontent', courseDetails?.thumbnail);
        formData.append('CourseType', CourseType.Offline);

        const courseResponse = await API.createLiveCourse(formData);
        if (courseResponse.status === 200) {
            const courseId = courseResponse.data.CourseId

            const quizIds = getQuizCourseList
                .filter(item => item.Type === 0)
                .map(quiz => quiz.Id);

            const videoIds = getQuizCourseList
                .filter(item => item.Type === "video")
                .map(video => video.Id);

            const VideoapiCalls = {
                PlaylistId: courseId,
                Videos: videoDataSets.map((video, idx) => {
                    return {
                        VideoId: video.id,
                        Order: idx
                    }
                })
            };
 
            const QuizApiCalls = quizIds.map((QuizId, index) =>
                API.assignQuiztoPlaylist({
                    quizId: QuizId,
                    playlistId: courseId,
                    indexSequence: index + 1
                })
            );
 
 
            var updatePayload = videoDataSets.map(videoData => {
                return {
                    ThumbnailUpload: {
                        File: videoData.thumbnailFile,
                        Sas: videoData.SasToken,
                        FileId: videoData.fileId,
                        ThumbnailPath: thumbnailPath,
                    },
                    VideoUpdateData: {
                        Id: videoData.id,
                        Name: videoData.Name,
                        Title: videoData.videoTitle,
                        FileId: videoData.fileId,
                        CategoryId: parseInt(courseDetails?.selectedCategory.Id),
                        IsActive: true,
                        FileName: videoData.Name,
                        Extension: hlsVideoExtension,
                        Description: videoData.videoDescription,
                        Points:videoData.videoPoints,
                        Transcript: videoData.videoTranscript,
                        VideoLength: videolength,
                        ThumbnailGeneratedFileName: videoData.thumbnailFile ? `${videoData.fileId}.${videoData.thumbnailFile.name.split('.').pop()}` : null
                    }
                }
            })

            var updateVideo = API.updateTutorial({
                UpdateVideos: updatePayload.map(a => a.VideoUpdateData)
            })

            var uploadVideoSAS = getFiles.forEach((file, index) => {
                const video = videoDataSets[index];
                return UploadVideowithsas(file, video.id, video.fileId, video.SasToken, (progress) => {
                    console.log(`Upload Progress : ${progress.toFixed(2)}%`);
                });
            });

            var uploadThumbnailSAS = updatePayload.forEach(thumbnail => {
                return Uploadwithsas(thumbnail.ThumbnailUpload.File, thumbnail.ThumbnailUpload.FileId, thumbnail.ThumbnailUpload.Sas, thumbnail.ThumbnailUpload.ThumbnailPath)
            })

            const updateVideoDataPayload = videoDataSets.map((file, i) => ({
                Id: file.id,
                State: uploadedVideoStateUploadedToTemp
            }))

            var updateVideoStatus = API.updateVideoData({ updateVideoData: updateVideoDataPayload });

            API.addVideoToPlaylist(VideoapiCalls)

            const updateDocumentsPromises = videoDataSets.map(async (videoData) => {
                const videoDocuments = videoData.videoDocuments;
                const docsRep = videoData.documentResponse;
                if (videoDocuments && videoDocuments.length > 0) {
                    const uploadedDocuments = await Promise.all(videoDocuments.map(async (doc,index) => {
                        await Uploadwithsas(doc.File, docsRep[index].FileId, docsRep[index].DocumentSasToken, documentPath);
                        return {
                            VideoId: videoData.id,
                            FileId: docsRep[index].FileId,
                            DocumentGeneratedFileName: `${docsRep[index].FileId}.${doc.File.name.split('.').pop()}`,
                            FileName: doc.File.name,
                            Id: docsRep[index].DocumentId || null,
                            Title: doc.Title || doc.File.name.split('.')[0], 
                            Tags: doc.Tags || "", 
                            Extension: doc.File.name.split('.').pop()
                        };
                    }));

                    await API.updateDocument(uploadedDocuments);
                }
            });
            
            await Promise.all(updateDocumentsPromises);

            Promise.all([updateVideo, uploadVideoSAS, uploadThumbnailSAS, updateVideoStatus]).then(responses => {
                const flattenedResponses = responses
                const allSuccess = flattenedResponses[0].status === 200
                if (allSuccess) {
                    return UserAPI.fetchCoursesDetail(courseId)
                        .then(res => {
                            if (res.status === 200) {
                                setAlert({
                                    active: true,
                                    severity: 'success',
                                    message: 'Offline course created successfully',
                                });
                            }
                        });
                } else {
                    setAlert({
                        active: true,
                        severity: 'error',
                        message: 'Offline course created failed',
                    });
                }
            }).finally(() => {
                setModalLoader(false);
                setTimeout(() => {
                    navigate('/collection/coursecollection');
                }, 4000);
            });
        }};
 
 
    const handleRemoveVideo = (id) => {
        API.deleteVideo(id)
        setMockfiles(mockFiles.filter((video) => (video.VideoId !== id)))
    }

    const handleSelectedVideo = (file) => {
        setSelectedVideo(file)
        setSelectedId(file.VideoId)
    }

    const handleVideoDataSets = (videoSets) => {
        setVideoDataSets(videoSets);
    }

    const handleValidation = (validation) => {
        setFormFilled(validation)
    }

    const handleGetFiles = (files) => {
        setGetFiles((prevFiles) => [...prevFiles, ...files]);
    };
 
 
    const renderStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <div className="p-5 mt-5 bg-white">
                        <CourseCreation
                            ref={childRef}
                            setAlert={setAlert}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            formFilled={handleFormFilled}
                            setFormFilled={setFormFilled}
                            courseDetails={courseDetails}
                            updateCourseDetails={updateCourseDetails} />
                    </div>
                );
            case 1:
                return (
                    <div>
                        {mockFiles.length === 0 ? (
                            <>
                                <UploadCourse
                                    setPreviewThumb={handleThumbnailPreview}
                                    updateMockFiles={addFileToMockFiles}
                                    setSelectedVideo={setSelectedVideo}
                                    getThumbnail={(e) => { setGetThumbnail(e) }}
                                    successResponse={successResposnse}
                                    getFiles={(e) => setGetFiles(e)}
                                    setAlert={setAlert}
                                    videolength={(e) => { setVideoLength(e) }}
                                />
                                {alert.loading && <LoadingScreen />}
                            </>
                        ) : (
                            <UploadedVideosEdit
                                selectVideo={handleSelectedVideo}
                                selectedVideoId={selectedVideoId}
                                updateMockFiles={addFileToMockFiles}
                                setDocument={setDocument}
                                setDocumentFile={setDocumentFile}
                                setDocumentResponce={setDocumentResponce}
                                mockFiles={mockFiles}
                                selectedVideo={selectedVideo}
                                editedData={setVideoDataSets}
                                editedVideoData={videoDataSets}
                                selectedPlaylist={getQuizCourseList}
                                thumbnailPreview={thumbnailPreview}
                                removeVideo={handleRemoveVideo}
                                selectedCategoryId={selectedCategoryId}
                                setEditedVideoTitle={setEditedVideoTitle}
                                EditedTitle={EditedTitle}
                                setEditedDescription={setEditedDescription}
                                EditedDescription={EditedDescription}
                                setEditedTranscript={setEditedTranscript}
                                EditedTranscript={EditedTranscript}
                                setEditedVideoPoints={setEditedVideoPoints}
                                EditedVideoPoints={EditedVideoPoints}
                                setVideoThumbnail={setVideoThumbnail}
                                videoThumbnail={videoThumbnail}
                                setVideoThumPreview={setVideoThumPreview}
                                videoThumPreview={videoThumPreview}
                                formFilled={handleFormFilled}
                                setFormFilled={setFormFilled}
                                courseDetails={courseDetails}
                                videoDataSets={handleVideoDataSets}
                                validation={handleValidation}
                                getFiles={handleGetFiles}
                            />
                        )}
                    </div>
 
                );
            case 2:
                return (
                    <div>
                        <AttachQuiz
                            setQuizCourseList={setQuizCourseList}
                            editedData={videoDataSets}
                            selectedQuiz={getQuizCourseList}
                            setSelectedQuiz={setSelectedQuiz}
                            thumbnailPreview={videoThumPreview}
                            videoDataSets={videoDataSets}
                        />
                    </div>
                );
            case 3:
                return (
                    <div className="mt-3">
                        <CoursePreviews
                            thumbnailPreview={thumbnailPreview}
                            getQuizCourseList={getQuizCourseList}
                            mockFiles={getQuizCourseList}
                            courseDetails={courseDetails}
                            editedVideoData={videoDataSets} />
                    </div>
                );
            default:
                return (
                    <div>
                        <h1>Unknown Step</h1>
                    </div>
                );
        }
    };

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={alert.active}
                onClick={handleBackDropClose}
            >
                <Alert severity={alert.severity} style={{ position: "absolute", top: 20 }}>
                    {alert.message}
                </Alert>
            </Backdrop>
            <div className="video-tab-con">
                <BreadcrumbsNav
                    activeTtile="Create Offline Course"
                    setStudioLandingPage={props.setStudioLandingPage}
                />
                <HorizontalStepper className="mt-3" steps={['Course Details', 'Upload Video', 'Quiz', 'Preview']} activeStep={activeStep} />
                <div className='d-flex justify-content-center studio-course-creation'>
                    <div className='upload-con-width'>
                        {!modalLoader ? renderStepContent(activeStep) :
                            <div className="spinner-wrap">
                                <Spinner animation="border" variant="dark" />
                            </div>}
                        <div className="fixed-btns d-flex justify-content-between">
                            <button onClick={handleBack} disabled={activeStep === 0} className="filter-clear-all m-0">Back</button>
                            <button onClick={handleNext} disabled={activeStep === 0 && !isFormFilled || activeStep === 1 && !isVideoUploaded} className="filter-clear-search">Next</button>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

const mapDispatchToProps = (dispatch) => {

    return bindActionCreators(
        {
            updateTutorialVideoAction: updateTutorialVideo,
            updateDocumentVideoAction: updateDocumentVideo,
            postTutorialDocumentAction: postTutorialDocument,
        },
        dispatch
    );
};

const mapStateToProps = (state) => ({
    VideoData: state.tutorials.VideoData,
    DocumentData: state.tutorials.DocumentData,
    VideoName: state.tutorials.VideoName,
    VideoExtension: state.tutorials.VideoExtension,
    DocumentExtension: state.tutorials.DocumentExtension,
    DocumentName: state.tutorials.DocumentName,
    isVideoUploaded: state.tutorials.isVideoUploaded,
    courses: state.courses.courses.Playlist,
    playlistCategories: state.category.playlistCategories,
});
  
export default connect(mapStateToProps, mapDispatchToProps)(CreateOfflineCourse);