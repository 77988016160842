import React from 'react';
import EditProfile from "./EditProfile"
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

function EditUserProfile(props) {
  const navigate = useNavigate()
  const handleEditBtn = () => {
    navigate(-1)
  }
  let proPic = props.props.profile.ProfileImageUrl != null ? props.props.profile.ProfileImageUrl != "https://lmsstoragepoc.blob.core.windows.net/cdn" ? props.props.profile.ProfileImageUrl : sessionStorage.getItem('profilePic') : sessionStorage.getItem('profilePic');
  return (
    <div>
      <div className="edit-user-profile-con">
        <div className="edit-user-profile">
          <div className="edit-user-details">
            <div className="">
              <EditProfile handleEditBtn = {handleEditBtn} props={props}></EditProfile>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUserProfile;