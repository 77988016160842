import React, { useState, useRef, useEffect } from "react";
import { hlsVideoExtension, uploadedVideoStateInProgress, validationLimits } from "../Common/Constants";
import { useLocation } from "react-router-dom";
import { FormControl, FormHelperText } from '@mui/material';
import UploadFilesLabel from "../../../components/TranslatedComponents/UploadFilesLabel";
import DragDropLabel from "../../../components/TranslatedComponents/DrgDropLabel";
import API from "../utils/API";
import { postTutorialDocument } from "../store/actions/admin";
import Dropzone from "react-dropzone";
import DocumentCheck from "../Common/DocumentCheck";
import * as constants from "../Common/Constants";
import Spinner from "../../../components/Common/Spinner";
import { Opacity } from "@mui/icons-material";

const UploadedVideosEdit = (props) => {
    const [thumbnail, setThumbnail] = useState(props.videoThumbnail && props.videoThumbnail.length > 0 ? props.videoThumbnail : '');
    const [thumbnailPreview, setThumbnailPreview] = useState('');
    const [thumbnailError, setThumbnailError] = useState(false);
    const thumbnailFile = useRef(null);
    const [videoDocument, setVideoDocument] = useState(sessionStorage.getItem('videoDocument') ? JSON.parse(sessionStorage.getItem('videoDocument')) : {});
    const [inputDisable, setInputDisable] = useState(true)
    const [titleError, setTitleError] = useState('');
    const [descriptionError, setDescriptionError] = useState('');
    const [videoDocumentError, setVideoDocumentError] = useState({});
    const [mockVideoObject, setMockVideoObject] = useState({});
    const [formData, setFormData] = useState({});
    const [selectedVideo, setSelectedVideo] = useState(props.selectedVideo?.VideoId);
    const [selectedFileId, setSelectedFileId] = useState(props.selectedVideo?.FileId)
    const [formDataList, setFormDataList] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [updatedDocumentData, setUpdatedDocumentData] = useState([]);
    const [documentMaxLengthError, setDocumentMaxLengthError] = useState(false);
    const [documentResponse, setDocumentResponse] = useState([]);
    const [file, setFile] = useState([]);
    const [documentSpinner, setDocumentSpinner] = useState(false)

    useEffect(() => {
        if (documents.length < 5) {
            setDocumentMaxLengthError(false);
        } else {
            setDocumentMaxLengthError(true);
        }
        setDocumentMaxLengthError(documentMaxLengthError);
    }, [documents, documentMaxLengthError, props]);

    const onDocsDrop = async (files,id) => {
        setDocumentSpinner(true)
        const VideoId = selectedVideo
        const FileId = selectedFileId
        setFile(files);

        if (!constants.acceptedMime.includes(files[0].type)) {
            handleDocumentError('Document format incorrect');
            return;
        } else {
            handleDocumentError('');
        }

        if (documents.length + files.length > 5) {
            setDocumentMaxLengthError(true);
            return;
        }

        setDocumentMaxLengthError(false);

        const newFiles = files.filter(file => !documents.some(doc => doc.FileName === file.name));

        if (newFiles.length === 0) {
            handleDocumentError('Duplicate files are not allowed');
            return;
        } else {
            handleDocumentError('');
        }

        const newDocuments = newFiles.map(file => ({
            FileId,
            VideoId,
            FileName: file.name,
            Title: file.name,
            CreatedDate: file.lastModifiedDate,
            Extension: file.name.split('.').pop(),
            File: file
        }));

        const updatedDocuments = [...documents, ...newDocuments];
        setDocuments(updatedDocuments);

        try {
            const documentArray = newFiles.map(file => ({ FileName: file.name }));
            const res = await API.postTutorialDocument({
                VideoId: VideoId,
                Documents: documentArray
            });

            if (res.status === 200) {
                const newUpdatedData = res.data.map((response, index) => {
                    const doc = newFiles[index];
                    return {
                        VideoId,
                        FileId: response.FileId,
                        DocumentGeneratedFileName: response.FileId,
                        FileName: doc.name,
                        Id: response.DocumentId,
                        Title: doc.name,
                        Extension: doc.name?.split('.').pop()
                    };
                });

                const documentsWithIds = updatedDocuments.map(doc => {
                    const updatedDoc = newUpdatedData.find(data => data.FileName === doc.FileName);
                    return updatedDoc ? { ...doc, DocumentId: updatedDoc.Id } : doc;
                });

                setDocuments(documentsWithIds);
                setUpdatedDocumentData(prev => [...prev, ...newUpdatedData]);
                setDocumentResponse(prev => [...prev, ...res.data]);
                const updatedFiles = props.editedVideoData.map(video =>
                    video.id === id
                        ? { ...video, videoDocuments: [...documents, ...newDocuments],documentResponse:[...documentResponse, ...res.data] }
                        : video
                );
                
                props.editedData(updatedFiles);
                setDocumentSpinner(false)
                
            }
        } catch (error) {
            console.error('Error posting documents:', error);
            handleDocumentError('An error occurred while uploading the document');
        }
    };

    const handleDocumentRemove = (title, id , videoId) => {
        const updatedDocumentsData = updatedDocumentData.filter(doc => doc.Title !== title);
        const updatedDocs = documents.filter(doc => doc.Title !== title);
        const updatedDocumentRes = documentResponse.filter(doc => doc.DocumentId !== id);
    
        setUpdatedDocumentData(updatedDocumentsData);
        setDocuments(updatedDocs);
        setDocumentResponse(updatedDocumentRes);
        setDocumentMaxLengthError(updatedDocs.length >= 5);
    
        const updatedFiles = props.editedVideoData.map(video =>
            video.id === videoId
                ? {
                      ...video,
                      videoDocuments: updatedDocs,
                      documentResponse: updatedDocumentRes,
                  }
                : video
        );
        props.editedData(updatedFiles);
    
        if (id) {
            API.deleteDocument(id)
                .then(res => {
                    if (res.status === 200) {
                        console.log('Document successfully deleted');
                    }
                })
                .catch(() => {
                    handleDocumentError('Error deleting document');
                });
        }
    };
    
    


    useEffect(() => {
        props.setVideoThumbnail(thumbnail)
        if (props.selectedVideoId) {
            setInputDisable(false)

            const newObject = {};

            props.mockFiles.forEach((item, index) => {
                newObject[item.VideoId] = {
                    id: item.VideoId,
                    videoTitle: "",
                    videoDescription: "",
                    videoTranscript: "",
                    videoCategoryId: props.courseDetails?.selectedCategory.Id || '',
                    thumbnailPreview: thumbnail,
                    videoDocument: videoDocument,
                    videoPoints: props.EditedVideoPoints
                };
            });
            setMockVideoObject(newObject)
        }
        else {
            setInputDisable(true)
        }
    }, [props.selectedVideoId]);

    useEffect(() => {

    }, [props.EditedTitle, props.EditedDescription, props.EditedTranscript, props.courseDetails, thumbnailPreview, videoDocument, props.EditedVideoPoints])

    const handleDocumentError = (e) => {
        setVideoDocumentError(e)
    }

    const onDrop = (files) => {
        props.getFiles(files)
        const data = []
        var fileData = files.map(file => {

            return {
                FileName: file.name.substring(0, file.name.lastIndexOf('.')) || file.name,
                IsVrEnabled: false,
                VideoLength: 0,
                State: uploadedVideoStateInProgress,
                ThumbnailGenerated: ""
            }
        })
        API.postTutorialCourse(fileData)
            .then(res => {
                if (res.status === 200) {
                    res.data.filter(a => a.Messege.includes('Success')).map((video, idx) => {
                        data.push({
                            FileId: video.FileId,
                            VideoId: video.VideoId,
                            ThumbNail: video.ThumbnailSasToken,
                            SasToken: video.VideoSasToken,
                            name: fileData[idx].FileName,
                            VideoExtension: hlsVideoExtension,
                            PreviewGeneratedThumbnail: fileData[idx].ThumbnailGenerated,
                            Response: res.status,
                            uploaded: false
                        })
                    })
                }
                props.updateMockFiles(data);
            })
    }

    useEffect(() => {
        const formValid =
            props.EditedTitle.trim().length > 0 && props.EditedTitle.trim().length <= validationLimits.courseNameMax &&
            props.EditedDescription.trim().length > 0 && props.EditedDescription.trim().length <= validationLimits.sessionDescriptionMax &&
            thumbnail.length > 0
        props.formFilled(formValid);
        if (props.setFormFilled) {
            props.setFormFilled(formValid);
        }
        else {
            props.setFormFilled(!formValid);
        }
    }, [props.EditedTitle, props.EditedDescription, thumbnailPreview]);

    const handleThumbnailClick = () => {
        thumbnailFile.current.click();
    };

    const handleThumbnail = (files, id) => {
        if (files && files[0]) {
            const file = files[0];

            const reader = new FileReader();
            reader.onload = (e) => {
                const updatedFiles = props.editedVideoData.map(video =>
                    video.id === id
                        ? { ...video, thumbnailFile: file, thumbnailPreview: e.target.result }
                        : video
                );

                props.editedData(updatedFiles);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleVideoSelect = (file) => {
        setSelectedVideo(file.VideoId);
        setSelectedFileId(file.fileId);
        setDocuments([]);
        setUpdatedDocumentData([]);
        setDocumentMaxLengthError(false);
        setDocumentResponse([])
        setFormData({ videoId: file.VideoId });
        thumbnailFile.current.value = '';
    };

    useEffect(() => {
        props.mockFiles.forEach(file => {
            if (formDataList.find(a => a.videoId === file.VideoId) === undefined) {
                setFormDataList(formDataList =>
                    [...formDataList, {
                        videoId: file.VideoId,
                        fileId: file.FileId,
                        SasToken: file.SasToken,
                        ThumbNail: file.ThumbNail,
                        name: file.name,
                        VideoExtension: hlsVideoExtension,
                        category: props.courseDetails?.selectedCategory?.PlaylistCategoryName,
                        stepCompleted: 0,
                        title: "",
                        thumbnail: null,
                        description: "",
                        points: "",
                    }])
            }
        })
    }, [props.mockFiles])


    const requiredCondition = ['title', 'thumbnail', 'description', 'points', 'category']

    const handleInputChange = (field, e, id) => {
        const updatedFiles = props.editedVideoData.map(file =>
            file.id === id
                ? { ...file, [field]: e.target.value }
                : file
        );

        props.editedData(updatedFiles);
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        var video = formDataList.find(a => a.videoId === selectedVideo)
        video[name] = value
        const getNestedValue = (obj, path) => {
            return path.split('.').reduce((acc, part) => acc && acc[part], obj);
        };

        const areRequiredFieldsFilled = (obj, fields) => {
            return fields.every(field => {
                const value = getNestedValue(obj, field);
                return value;
            });
        };

        const allUsersValid = formDataList.every(video => areRequiredFieldsFilled(video, requiredCondition));

        props.validation(allUsersValid)

        if (allUsersValid) {
            props.videoDataSets(formDataList)
        }
    };


    return (
        <div className="d-flex gap-5 uploaded-file-edit">
            <div className="w-50 px-5 py-4 upload-white-bg">
                <h4 className="mb-5">Uploading 1 of 10 videos</h4>
                <ul>
                    {props.mockFiles.map((file, index) => (
                        <li className="mb-4" key={index}>
                            <div className="upload-ststus-details lms-flex-start m-0">
                                <div>
                                    <input
                                        type="radio"
                                        checked={file.VideoId === selectedVideo}
                                        onChange={() => handleVideoSelect(file)}
                                    />
                                </div>
                                <div className="mx-4 ">
                                    {
                                        file?.PreviewGeneratedThumbnail ?
                                            <img
                                                className="video-thumb"
                                                src={file?.PreviewGeneratedThumbnail}
                                                alt="video-thumbnail"
                                            />
                                            :
                                            <svg
                                                width="30px"
                                                height="30px"
                                                viewBox="0 0 30 30"
                                                version="1.1"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g
                                                    id="Visual-Design-2"
                                                    stroke="none"
                                                    strokeWidth="1" 
                                                    fill="none"
                                                    fillRule="evenodd"
                                                ><g id="video-active-svg" className="svg-icons-color" style={{ opacity: file.VideoId === selectedVideo ? 1 : 0.3 }}  fillRule="nonzero">
                                                        <path
                                                            d="M28.9285714,0 C29.5203051,0 30,0.479694911 30,1.07142857 L30,1.07142857 L30,28.9285714 C30,29.5203051 29.5203051,30 28.9285714,30 L28.9285714,30 L1.07142857,30 C0.479694911,30 0,29.5203051 0,28.9285714 L0,28.9285714 L0,1.07142857 C0,0.479694911 0.479694911,0 1.07142857,0 L1.07142857,0 Z M11.25,8.71071429 C10.9160876,8.52854318 10.5124839,8.52854318 10.1785714,8.71071429 C9.84574908,8.90286532 9.64133142,9.25855204 9.64284866,9.64285714 L9.64284866,9.64285714 L9.64284866,20.3571429 C9.64133142,20.741448 9.84574908,21.0971347 10.1785714,21.2892857 C10.5115963,21.4749044 10.9169752,21.4749044 11.25,21.2892857 L11.25,21.2892857 L20.5285714,15.9321429 C20.8602185,15.7388351 21.0641801,15.383872 21.0641801,15 C21.0641801,14.616128 20.8602185,14.2611649 20.5285714,14.0678571 L20.5285714,14.0678571 Z"
                                                            id="Shape"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </svg>
                                    }
                                </div>
                                <div className="video-details">
                                    <p className="p-0">{file.name}</p>
                                </div>
                                <img
                                    className="delete-img"
                                    src="assets/delete-img.jpg"
                                    alt="delete-icon"
                                    onClick={() => props.removeVideo(file.VideoId)}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
                <Dropzone onDrop={onDrop} accept={{
                    "video/mp4": [".mp4"],
                    "video/mpeg": [".mpeg"],
                    "video/webm": [".webm"],
                }}
                    multiple >
                    {({ getRootProps, getInputProps }) => (
                        <section className="container video-upload-con mt-5">
                            <div className="video-upload single-video-up">
                                <div {...getRootProps()} className="d-flex gap-1 align-items-center">
                                    <input {...getInputProps()} accept="video/*" />
                                    <svg width="44" height="44" viewBox="0 0 64 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="64" height="64" rx="17.6" className="svg-for-menu" />
                                        <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white" />
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z" className="svg-for-menu" />
                                    </svg>
                                    <div>
                                        <p className="upload-file text-start">
                                            <UploadFilesLabel />
                                        </p>
                                        <p>
                                            <DragDropLabel />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
            {props.editedVideoData.flat().map((file, index) => {
                if (file.id === selectedVideo) {
                    const conditions = [
                        file.videoTitle && file.videoTitle.length > 0,
                        file.videoPoints && file.videoPoints.length > 0,
                        file.thumbnailPreview && file.thumbnailPreview.length > 0,
                        file.videoDescription && file.videoDescription.length > 0,
                        file.videoDocuments.file && Object.keys(file.videoDocuments.file).length > 0,
                        file.videoTranscript && file.videoTranscript.length > 0,
                    ];
                    const completedSteps = conditions.filter(Boolean).length;
                    return <div className="w-50 p-0 upload-white-bg">
                        <div className="fixed-progress-bar px-5 py-4">
                            {conditions.map((_, idx) => (
                                <div
                                    key={idx}
                                    className={`progress-box ${idx < completedSteps ? "completed" : ""}`}
                                ></div>
                            ))}
                        </div>
                        <div className="px-5 video-edit-margin">
                            <div className="video-desc mt-5 mb-3 lms-flex-between">
                                <h4>Video Title</h4>
                            </div>
                            <div className="mb-4">
                                <input
                                    type="text"
                                    name="title"
                                    maxLength={validationLimits.videoTitleMax}
                                    value={file.videoTitle || ""}
                                    onChange={(e) => handleInputChange("videoTitle", e, file.id)}
                                />
                            </div>
                            <h6 style={{ color: "red" }}>{titleError}</h6>
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                accept="image/*"
                                ref={thumbnailFile}
                                onChange={(e) => handleThumbnail(e.target.files, file.id)}
                            />
                            {file.thumbnailPreview === "" ? (
                                <FormControl sx={{ width: '100%' }} className="mb-1 mt-3">
                                    <div className="webinar-upload-part-parent m-0">
                                        <div className="video-upload thumb-upload webinar-upload-part" onClick={handleThumbnailClick}>
                                            <svg width="36" height="36" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="64" height="64" rx="17.6" className="svg-for-menu"></rect>
                                                <rect x="18.3994" y="15.2001" width="25.6" height="33.6" fill="white"></rect>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M16.1544 12.8C26.7177 12.8 37.2819 12.8 47.8453 12.8C49.6908 12.8 51.1998 14.3091 51.1998 16.1546C51.1998 26.718 51.1998 37.2822 51.1998 47.8455C51.1998 49.691 49.6908 51.2001 47.8453 51.2001C37.2819 51.2001 26.7177 51.2001 16.1544 51.2001C14.3089 51.2001 12.7998 49.691 12.7998 47.8455C12.7998 37.2821 12.7998 26.7179 12.7998 16.1546C12.7998 14.3091 14.3089 12.8 16.1544 12.8ZM41.0751 26.6586C40.1466 27.5764 39.2181 28.495 38.2896 29.4128C36.8498 27.9896 35.4092 26.5663 33.9694 25.143C33.9694 32.1992 33.9694 39.2563 33.9694 46.3126C32.6558 46.3126 31.343 46.3126 30.0294 46.3126C30.0294 39.2555 30.0294 32.1992 30.0294 25.1421C28.5896 26.5654 27.149 27.9896 25.7092 29.4128C24.7807 28.495 23.8521 27.5764 22.9236 26.6586C25.9491 23.6677 28.9738 20.6777 31.9993 17.6868C35.0249 20.6777 38.0496 23.6678 41.0751 26.6586H41.0751Z" className="svg-for-menu"></path>
                                            </svg>
                                            <h4>Upload thumbnail image (Only PNG/JPEG)</h4>
                                        </div>
                                        {thumbnailError && (
                                            <FormHelperText sx={{ fontSize: 12, color: 'red' }}>
                                                Upload a thumbnail
                                            </FormHelperText>
                                        )}
                                    </div>
                                </FormControl>
                            ) : (
                                <div className="replace-image-con">
                                    <img src={file.thumbnailPreview} className="thumbnail" alt="Thumbnail preview" />
                                    <button onClick={handleThumbnailClick}>Replace image</button>
                                </div>
                            )}
                            <div className="d-flex align-items-center gap-1">
                                <div className="disabled-cat">
                                    <h2>{props.courseDetails?.selectedCategory?.PlaylistCategoryName}</h2>
                                </div>
                                <input
                                    type="number"
                                    placeholder="Points for the Video"
                                    className="select-cat-upload points-input px-4"
                                    value={file.videoPoints || ""}
                                    onWheel={event => event.currentTarget.blur()}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value >= 0 || value === "") {
                                            handleInputChange('videoPoints', e, file.id)
                                        }
                                    }}
                                />
                            </div>
                            <h4 className="mt-4">Description</h4>
                            <textarea
                                id="video-descripation"
                                rows="12"
                                cols="50"
                                form="usrform"
                                maxLength={validationLimits.videoDescriptionMax}
                                placeholder="Add Description"
                                name="description"
                                value={file.videoDescription || ""}
                                onChange={(e) => handleInputChange("videoDescription", e, file.id)}
                            ></textarea>
                            <h6 style={{ color: "red" }}>{descriptionError}</h6>
                            <h4 className="m-30">Documents</h4>
                            <div className="video-upload-docs">
                                {
                                    file.videoDocuments?.length > 0 &&
                                    file.videoDocuments?.map((document, index) => {
                                        return <DocumentCheck
                                            key={index}
                                            handleDocumentRemove={() => handleDocumentRemove(document.Title, documentResponse[index].DocumentId,file.id)}
                                            Extension={document.FileName.split('.').pop()}
                                            Filename={document.FileName.split('.')}
                                            CreatedDate={document.File.lastModifiedDate}
                                        />
                                    })
                                }
                                <Dropzone onDrop={(files) => onDocsDrop(files, file.id)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps({ className: "dropfile" })}>
                                            {
                                                documentSpinner ?
                                                    <Spinner /> :
                                                    <>
                                                        <input {...getInputProps()} />
                                                        <svg width="31" height="31" xmlns="http://www.w3.org/2000/svg">
                                                            <g fill="none" fillRule="evenodd">
                                                                <rect className="svg-for-menu" width="31" height="31" rx="8.448" />
                                                                <path fill="#FFF" d="M8.912 7.363h12.4v16.275h-12.4z" />
                                                                <path
                                                                    d="M7.825 6.2h15.35c.894 0 1.625.73 1.625 1.625v15.35a1.63 1.63 0 0 1-1.625 1.625H7.825A1.63 1.63 0 0 1 6.2 23.175V7.825c0-.894.73-1.625 1.625-1.625zm12.07 6.713-1.348 1.334-2.093-2.068v10.254h-1.908V12.178l-2.093 2.069-1.35-1.334L15.5 8.567l4.396 4.346z"
                                                                    className="svg-for-menu"
                                                                />
                                                            </g>
                                                        </svg>
                                                        <p className="drop-text">Drag and drop files</p>
                                                        <p> or</p>
                                                        <label for="drag-file" className="pri-button">Choose a file</label></>
                                            }

                                        </div>
                                    )}
                                </Dropzone>
                            </div>
                            {videoDocumentError.length > 0 && <h6 style={{ color: "red" }}>{videoDocumentError}</h6>}
                            {documentMaxLengthError && <h6 style={{ color: "red", marginTop: "0.6vw" }}>Documents should be less then 5</h6>}
                            <h4 className="mt-3">Transcript</h4>
                            <textarea
                                id="video-descripation"
                                rows="12"
                                cols="50"
                                form="usrform"
                                placeholder="Add Transcript"
                                name="transcript"
                                value={file.videoTranscript || ""}
                                onChange={(e) => handleInputChange("videoTranscript", e, file.id)}
                            ></textarea>
                        </div>
                    </div>
                }
            })}
        </div>
    )
}

export default UploadedVideosEdit;