import { useFusionAuth } from '@fusionauth/react-sdk';
import React, { useState } from 'react'

export default function Registration() {
    const [authenticated, setAuthenticated] = useState()
    const {isAuthenticated, register} = useFusionAuth();

    if(!isAuthenticated && !authenticated)
    {
        return register()
    }
}
