import Cookies from "js-cookie";
import { postData, putData, deleteData, getData } from "../axios/axiosbase";

const fetchTutorials = () => {
    let pagination = {
        PageNumber: 1
    };
    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/getallvideos`,
        pagination
    );
};

const fetchSavedTutorials = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/Video/getallusersavedvideos?userId=${payload.Id}`,
        payload.body
    );
};

const unSaveTutorials = payload => {
    return putData(
        process.env.REACT_APP_BASE_URL +
        `/Video/unsavevideo?videoId=${payload.videoId}&userId=${payload.userId}`
    );
};
const saveTutorials = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/Video/createusersavevideo?videoId=${payload.videoId}&userId=${payload.userId}`,
        null
    );
};
const fetchTutorialsLoadMore = payload => {
    console.log(payload);
    let pagination = {
        PageNumber: payload,
        PageSize: 20
    };
    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/getallvideos`,
        pagination
    );
};

const fetchTutorialsDetail = id => {
    return getData(process.env.REACT_APP_BASE_URL + `/Video/details/${id}`);
};

const filterTutorials = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Video/getallvideos?includeAllPage=true&filterField=CategoryName&filterText=${payload}`
    );
};

const searchTutorials = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Video/getallvideos?includeAllPage=true&filterField=Name&filterText=${payload}`
    );
};

const fetchTutorialsFilter = payload => {
    let body = {
        filterField: payload.filterField,
        filterText: payload.filterText,
        SortField: payload.sortField,
        SortType: payload.sortType
    };
    return postData(process.env.REACT_APP_BASE_URL + `/Video/getallvideos`, body);
};

const fetchTutorialsSort = payload => {
    let body = {
        sortField: payload.SortField,
        sortType: payload.SortType
    };
    return postData(process.env.REACT_APP_BASE_URL + `/Video/getallvideos`, body);
};

const sortTutorials = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Video/getallvideos?pageSize=20&sortField=createdDate&sortType=${payload}`
        // `/Video?includeAllPage=true&sortField=CreatedDate&sortType=${payload}`
    );
};

const fetchCourses = () => {
    const pagination = {
        PageNumber: 1,
        PageSize: 6
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Course/getallusercourses`,
        {}
    );
};

const fetchCoursesLoadMore = payload => {
    const pagination = {
        PageNumber: 2
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Course/getallusercourses`,
        pagination
    );
};

const fetchCoursesFilter = payload => {
    const filter = {
        SortField: payload.SortField,
        SortType: payload.SortType,
        FilterField: payload.FilterField,
        FilterText: payload.FilterText
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Course/getallusercourses`,
        filter
    );
};

const fetchSavedCourses = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/Course/getallsavedcourses?userId=${payload.Id}`,
        payload.body
    );
};

const fetchCoursesDetail = id => {
    return getData(
        process.env.REACT_APP_BASE_URL + `/Course/getuserplaylist?playListId=${id}`
    );
};

const saveCourses = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/Course/savecourse?playlistId=${payload.courseId}&userId=${payload.userId}`
    );
};

const unSavedCourses = payload => {
    return putData(
        process.env.REACT_APP_BASE_URL +
        `/Course/unsavecourse?courseId=${payload.courseId}&userId=${payload.userId}`
    );
};

const unShareCourseAPI = payload => {
    return putData(
        process.env.REACT_APP_BASE_URL +
        `/Course/unsharecourse?courseId=${payload.courseId}&shareBy=${payload.shareBy}&shareWith=${payload.shareWith}`
    );
};

const shareCourseAPI = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/Course/createsharecourse?playlistId=${payload.courseId}&sharedBy=${payload.shareBy}&sharedWith=${payload.shareWith}`
    );
};

const unShareVideoAPI = payload => {
    return putData(
        process.env.REACT_APP_BASE_URL +
        `/Video/unsharevideo?videoId=${payload.videoId}&shareby=${payload.shareBy}&sharedwith=${payload.shareWith}`
    );
};

const shareVideoAPI = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/video/sharevideo?videoId=${payload.videoId}&sharedBy=${payload.shareBy}&sharedWith=${payload.shareWith}`
    );
};

const fetchShareCourseById = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Course/getsharecoursebyid?userId=${payload.userId}&id=${payload.playlistId}`
    );
};

const fetchShareVideoById = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Video/getusersharevideo?sharedBy=${payload.userId}&videoId=${payload.videoId}`
    );
};

const fetchUsers = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL + `/UserProfileManagement/getusers`,
        payload
    );
};

const filterCourses = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Course/getallcourse?pageSize=20&filterField=PlaylistCategoryName&filterText=${payload}`
    );
};

const sortCourses = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/Course/getallusercourses?pageSize=20&sortField=CreatedDate&sortType=${payload}`
    );
};

const profileData = payload => {
    console.log("profile data payload", payload);
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/UserProfileManagement/details?userId=${payload}`
    );
};

const saveNotes = (userId, videoId, Notes) => {
    console.log(userId, videoId, Notes);
    let payload = {
        UserId: userId,
        VideoId: videoId,
        Notes: Notes
    };
    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/updateusernotes`,
        payload
    );
};

const getInprogressCourses = () => {
    const pagination = {
        PageNumber: 1,
        PageSize: 20
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Course/getInprogresCourses`,
        pagination
    );
};
const getCompletedCourses = () => {
    const pagination = {
        PageNumber: 1,
        PageSize: 20
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Course/getCompletedCourses`,
        pagination
    );
};
const getUserCertificates = () => {
    const pagination = {
        PageNumber: 1,
        PageSize: 20
    };

    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserCertificatesAndBadges/getallusercertificates`,
        pagination
    );
};
const getUserBadges = () => {
    const pagination = {
        PageNumber: 1,
        PageSize: 20
    };

    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserCertificatesAndBadges/getalluserbadges`,
        pagination
    );
};
const getSharedByMeVideos = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/getallsharedbymevideos`,
        payload
    );
};
const getSharedWithMeVideos = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/getallsharedwithmevideos`,
        payload
    );
};
const getSharedWithMeCourses = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL + `/Course/getallsharewithmecourse`,
        payload
    );
};
const getSharedByMeCourses = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/Course/getallsharebymecourse`,
        payload
    );
};

const getSharedByMeVideosFilter = payload => {
    const pagination = {
        FilterField: payload.filterField,
        FilterText: payload.filterText,
        SortField: payload.sortField,
        SortType: payload.sortType
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/getallsharedbymevideos`,
        pagination
    );
};
const getSharedWithMeVideosFilter = payload => {
    const pagination = {
        FilterField: payload.filterField,
        FilterText: payload.filterText,
        SortField: payload.sortField,
        SortType: payload.sortType
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/getallsharedwithmevideos`,
        pagination
    );
};
const getSharedWithMeCoursesFilter = payload => {
    const pagination = {
        FilterField: payload.filterField,
        FilterText: payload.filterText,
        SortField: payload.sortField,
        SortType: payload.sortType
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Course/getallsharewithmecourse`,
        pagination
    );
};
const getSharedByMeCoursesFilter = payload => {
    const pagination = {
        FilterField: payload.filterField,
        FilterText: payload.filterText,
        SortField: payload.sortField,
        SortType: payload.sortType
    };

    return postData(
        process.env.REACT_APP_BASE_URL + `/Course/getallsharebymecourse`,
        pagination
    );
};

const updateSaveVideoWatchDurationStatus = payload => {
    const body = {
        Id: payload.Id,
        VideoLength: payload.VideoLength,
        WatchedDuration: payload.WatchedDuration
    };

    return putData(
        process.env.REACT_APP_BASE_URL + `/Video/usersavedwatchedvideo`,
        body
    );
};

const updateSharedVideoWatchDurationStatus = payload => {
    const body = {
        Id: payload.Id,
        VideoLength: payload.VideoLength,
        WatchedDuration: payload.WatchedDuration
    };
    return putData(
        process.env.REACT_APP_BASE_URL + `/Video/usersharewatchedvideo`,
        body
    );
};

const updateSaveCourseWatchDurationStatus = payload => {
    const body = {
        CourseId: payload.CourseId,
        VideoId: payload.VideoId,
        VideoLength: payload.VideoLength,
        WatchedDuration: payload.WatchedDuration
    };

    return putData(
        process.env.REACT_APP_BASE_URL + `/Course/usersavedwatchedcourse`,
        body
    );
};

const updateSharedCourseWatchDurationStatus = payload => {
    const body = {
        CourseId: payload.CourseId,
        VideoId: payload.VideoId,
        VideoLength: payload.VideoLength,
        WatchedDuration: payload.WatchedDuration
    };

    return putData(
        process.env.REACT_APP_BASE_URL + `/Course/usersharedwatchedcourse`,
        body
    );
};

const getUserDetails = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/usersharewatchedvideo`,
        payload
    ).catch(err => console.log(err));
};

const updateUserProfile = payload => {
    return putData(
        process.env.REACT_APP_BASE_URL + `/UserProfileManagement/updateuserprofile`,
        payload
    ).catch(err => console.log(err));
};

const updateProfilePicture = payload => {
    const formData = new FormData();

    formData.append("filecontent", payload.file);

    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserProfileManagement/uploadprofilepicture?userId=${payload.userId}`,
        formData
    );
};

const deleteProfilePicture = payload => {
    return deleteData(
        process.env.REACT_APP_BASE_URL +
        `/UserProfileManagement/deleteprofilepicture?userId=${payload.userId}`
    );
};

const deleteUserProfile = payload => {
    return deleteData(
        process.env.REACT_APP_BASE_URL +
        `/UserProfileManagement/deleteuserprofile/${payload}`
    );
};

const fetchCategories = () => {
    return getData(process.env.REACT_APP_BASE_URL + `/Category/getallcategories`);
};

const fetchPlaylistCategories = () => {
    return getData(
        process.env.REACT_APP_BASE_URL + `/Category/getallplaylistcategories`
    );
};

const postVideoSeekTime = payload => {
    const body = {
        UserId: payload.userId,
        VideoId: payload.videoId,
        WatchedDuration: payload.seekTime,
        VideoLength: payload.videoLength
    };
    return postData(
        process.env.REACT_APP_BASE_URL + `/Video/createuservideohistory`,
        body
    );
};

const postCourseHistory = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/Course/createusercoursehistory?userId=${payload.userId}&courseId=${payload.courseId}`
    );
};

const getVideoHistoryByUserId = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Video/getalluservideoshistories?userId=${payload}`
    );
};

const getCourseChartPercentage = () => {
    return getData(
        process.env.REACT_APP_BASE_URL + `/Course/getcoursechartpercentage`
    );
};

const checkSavedCourse = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Course/checksavecourse?userId=${payload.userId}&playListId=${payload.playListId}`
    );
};

const getLastAccessedSavedCourse = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Course/getlastaccessedsavedcourse?userId=${payload}`
    );
};

const getCourseDetails = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Course/getplaylistdetails?playListId=${payload}`
    );
};

const getRelatedCourse = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/Course/getrelatedplaylist?playListCategoryId=${payload}`
    );
};

const getQuizById = payload => {
    console.log(payload);
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/getquizbyid?userId=${payload.userId}&quizId=${payload.quizId}&playListId=${payload.playListId}`
    );
};

const createQuizMetaData = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL + `/UserQuizAndAssesment/createquizmetadata`,
        payload
    );
};

const updateQuizMetaData = payload => {
    console.log(payload);
    return putData(
        process.env.REACT_APP_BASE_URL + `/UserQuizAndAssesment/updatequizmetadata`,
        payload
    );
};

const getQuizMetadata = payload => {
    console.log(payload);
    return postData(
        process.env.REACT_APP_BASE_URL + `/UserQuizAndAssesment/getquizmetadata`,
        payload
    );
};

const userTakeAssessment = payload => {
    console.log(payload);
    return postData(
        process.env.REACT_APP_BASE_URL + `/UserQuizAndAssesment/usertakeassessment`,
        payload
    );
};

const getQuizScore = payload => {
    console.log(payload);
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/usergetquizscore?userId=${payload.userId}&quizId=${payload.quizId}&playListId=${payload.playListId}&isUpdate=${payload.isUpdate}`
    );
};

const createScoreCard = payload => {
    console.log(payload);
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/createscorecard?userId=${payload.userId}&quizId=${payload.quizId}&playListId=${payload.playListId}`
    );
};

const getQuizStatus = payload => {
    console.log(payload);
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/quizstatus?userId=${payload.userId}&playListId=${payload.playListId}`
    );
};

const getNotes = payload => {
    console.log(payload);
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/video/getNotes?userId=${payload.userId}&videoId=${payload.videoId}`
    );
};

const getDocument = payload => {
    console.log(payload);
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/video/getdocument?userId=${payload.userId}&videoId=${payload.videoId}`
    );
};

const getWebinarDocument = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/sessionsuser/getuserwebinardocumentbyid?sessionId=${payload}`
    );
};



const certificateCompletedCount = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/course/getcertificatecompletedcount?userId=${payload}`
    );
};

const shareMyCertificate = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserCertificatesAndBadges/sharemycertificate?sharedBy=${payload.sharedBy}&sharedWith=${payload.sharedWith}&certificateId=${payload.certificateId}`
    );
};

const createQuestionSet = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/createquestionset?quizId=${payload.quizId}&userId=${payload.userId}`
    );
};

const getQuestionSet = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/getquestionset?quizId=${payload.quizId}&userId=${payload.userId}`
    );
};

const startQuiz = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/startquiz?quizId=${payload.quizId}&userId=${payload.userId}&playlistId=${payload.playlistId}`
    );
};

const retakeQuiz = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/retakequiz?quizId=${payload.quizId}&userId=${payload.userId}&playlistId=${payload.playlistId}`
    );
};

const getLastAttemptScore = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/getlastattemptscore?quizId=${payload.quizId}&userId=${payload.userId}&playlistId=${payload.playlistId}`
    );
};

const updateScoreOnAnswer = payload => {
    return postData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/updatescoreonanswer`,
        payload
    );
};

const getUserScore = payload => {
    return getData(
        process.env.REACT_APP_BASE_URL +
        `/UserQuizAndAssesment/getuserscore?quizId=${payload.quizId}&userId=${payload.userId}&playlistId=${payload.playlistId}`
    );
};

// FOR ADMIN

//  const updateTutorial = (payload)=>{
//   console.log('this is the api calling with payload' ,payload)
//    return 'something'
//   const{ VideoData,
//   DocumentData,
//   VideoName,
//   VideoExtension}  = payload;

//   const {VideoId,FileId} = VideoData

//   // axios.post(`https://inovarlmsadminapi.azurewebsites.net/update?Id=166&Name=Glass.Website&Title=React&VideoGeneratedFileName=4331158dc21140479820e06487309748.mp4&FileId=4331158dc21140479820e06487309748&ThumbnailGeneratedFileName=4331158dc21140479820e06487309748.jpg&CategoryId=103&IsActive=true&FileName=Glass.Website&Extension=mp4&Description=Something&Transcript=Some)

//     const url  =  `https://inovarlmsadminapi.azurewebsites.net/update?Id=${VideoId}&Name=${VideoName}&Title=React&VideoGeneratedFileName=4331158dc21140479820e06487309748.mp4&FileId=4331158dc21140479820e06487309748&ThumbnailGeneratedFileName=4331158dc21140479820e06487309748.jpg&CategoryId=103&IsActive=true&FileName=Glass.Website&Extension=mp4&Description=Something&Transcript=Some`

// }

export default {
    shareMyCertificate: shareMyCertificate,
    certificateCompletedCount: certificateCompletedCount,
    getDocument: getDocument,
    getNotes: getNotes,
    fetchTutorials: fetchTutorials,
    fetchTutorialsDetail: fetchTutorialsDetail,
    fetchTutorialsLoadMore: fetchTutorialsLoadMore,
    filterTutorials: filterTutorials,
    sortTutorials: sortTutorials,
    searchTutorials: searchTutorials,
    fetchCourses: fetchCourses,
    fetchCoursesDetail: fetchCoursesDetail,
    filterCourses: filterCourses,
    sortCourses: sortCourses,
    profileData: profileData,
    saveNotes: saveNotes,
    fetchSavedTutorials: fetchSavedTutorials,
    fetchSavedCourses: fetchSavedCourses,
    unSavedCourses: unSavedCourses,
    unSaveTutorials: unSaveTutorials,
    saveTutorials: saveTutorials,
    fetchShareCourseById: fetchShareCourseById,
    fetchUsers: fetchUsers,
    unShareCourseAPI: unShareCourseAPI,
    shareCourseAPI: shareCourseAPI,
    unShareVideoAPI: unShareVideoAPI,
    shareVideoAPI: shareVideoAPI,
    fetchShareVideoById: fetchShareVideoById,
    getInprogressCourses: getInprogressCourses,
    getCompletedCourses: getCompletedCourses,
    getUserCertificates: getUserCertificates,
    getUserBadges: getUserBadges,
    getSharedByMeCourses: getSharedByMeCourses,
    getSharedWithMeCourses: getSharedWithMeCourses,
    getSharedWithMeVideos: getSharedWithMeVideos,
    getSharedByMeVideos: getSharedByMeVideos,
    updateSaveVideoWatchDurationStatus: updateSaveVideoWatchDurationStatus,
    updateSharedVideoWatchDurationStatus: updateSharedVideoWatchDurationStatus,
    getUserDetails: getUserDetails,
    updateUserProfile: updateUserProfile,
    updateProfilePicture: updateProfilePicture,
    deleteProfilePicture: deleteProfilePicture,
    saveCourses: saveCourses,
    deleteUserProfile: deleteUserProfile,
    fetchCoursesFilter: fetchCoursesFilter,
    fetchTutorialsFilter: fetchTutorialsFilter,
    fetchTutorialsSort: fetchTutorialsSort,
    fetchCoursesLoadMore: fetchCoursesLoadMore,
    getSharedByMeVideosFilter: getSharedByMeVideosFilter,
    getSharedByMeCoursesFilter: getSharedByMeVideosFilter,
    getSharedWithMeCoursesFilter: getSharedWithMeCoursesFilter,
    getSharedWithMeVideosFilter: getSharedWithMeVideosFilter,
    fetchCategories: fetchCategories,
    fetchPlaylistCategories: fetchPlaylistCategories,
    postVideoSeekTime: postVideoSeekTime,
    getVideoHistoryByUserId: getVideoHistoryByUserId,
    postCourseHistory: postCourseHistory,
    updateSaveCourseWatchDurationStatus: updateSaveCourseWatchDurationStatus,
    updateSharedCourseWatchDurationStatus: updateSharedCourseWatchDurationStatus,
    getCourseChartPercentage: getCourseChartPercentage,
    getLastAccessedSavedCourse: getLastAccessedSavedCourse,
    getCourseDetails: getCourseDetails,
    getRelatedCourse: getRelatedCourse,
    getQuizById: getQuizById,
    userTakeAssessment: userTakeAssessment,
    getQuizMetadata: getQuizMetadata,
    createQuizMetaData: createQuizMetaData,
    updateQuizMetaData: updateQuizMetaData,
    getQuizScore: getQuizScore,
    createScoreCard: createScoreCard,
    getQuizStatus: getQuizStatus,
    createQuestionSet: createQuestionSet,
    getQuestionSet: getQuestionSet,
    updateScoreOnAnswer: updateScoreOnAnswer,
    startQuiz: startQuiz,
    retakeQuiz: retakeQuiz,
    checkSavedCourse: checkSavedCourse,
    getLastAttemptScore: getLastAttemptScore,
    getUserScore: getUserScore,
    getWebinarDocument: getWebinarDocument
};
