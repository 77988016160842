import { saveAs } from "file-saver";
import React from "react";
import TranscriptLabel from "../TranslatedComponents/TranscriptLabel"
import DocumentsLabel from "../TranslatedComponents/DocumentsLabel"
import DescriptionLabel from "../TranslatedComponents/DescriptionLabel"
import NotesLabel from "../TranslatedComponents/NotesLabel"
import { connect, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import DocumentCheck from "../../modules/Admin/Common/DocumentCheck";
import { fetchTutorialsDetail } from "../../store/actions/tutorials";
import store from "../../store/configureStore";
import API from "../../utils/API";
import AdminApi from "../../modules/Admin/utils/API"
import RichTextEditorNotes from "../Common/RichTextEditorNotes"
import download from "../Common/DownloadFile";
import { height } from "@mui/system";
import { useTranslation } from "react-i18next";
import { extension } from "../../modules/Admin/Common/Constants";
import { LocalTimeFormat, dateDayPrint, sessionStatus, toTime } from "../Common/Constants";
import moment from "moment";


function SessionsPlayList(props) {

  const tabsMargin = `tabsStyling ${document.body.dir === "ltr" ? "tabs-r-margin" : "tabs-l-margin"}`
  const params = useParams()

  return (
    <div>
      <Tabs>
        <TabList>
          <Tab className={tabsMargin}>Upcoming</Tab>
          <Tab className={tabsMargin}>Completed</Tab>
        </TabList>

        <TabPanel>
          {
            props.sessionContents.filter(a => a.Status === sessionStatus.NotStarted).length > 0 ?
              <div class="sessions-video-list">
                {
                  props.sessionContents.map((a, idx) => {
                    return (
                      (a.Status == sessionStatus.NotStarted) ?
                      // <Link to={"/course/" + props.id + "/" + idx}>
                      <div onClick={() => props.onSessionChange(idx)} style={{cursor: 'pointer'}}>
                        <div className={props.videoId == idx && `playlist_videos   ${document.body.dir == "ltr" ? "left-border-play-list" : "right-border-play-list"}`}>
                          <li class="playlistvideo" >
                            <div class="lms-flex-align-center playlist-flex">
                              <div class="lms-playlist-video"><div class="lms-video-image">
                                <img src={a.ThumbnailUrl} alt="playlist-video-img" />
                              </div>
                              </div>
                              <div class="lms-playlist-content">
                                <div class="lms-playlistvideo-content">
                                  <div class="playlist-heading">{a.Title}</div>
                                  <div className="lms-flex-align-center">

                                    <p class="playlist-para">Date: <b style={{ color: "#000" }}>{dateDayPrint(a.Date)}</b></p>
                                    <p class="playlist-para">Time: <b style={{ color: "#000" }}>{LocalTimeFormat(a.StartTime) + ' - ' + LocalTimeFormat(a.EndTime)}</b></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div> : ""
                    )
                  })
                }
              </div>
              :
              <div className="is-session-empty">
                <p>There are no videos to show in this section</p>
              </div>
          }
        </TabPanel>

        <TabPanel>
          {
            props.sessionContents.filter(a => a.Status === sessionStatus.Completed).length > 0 ?
              <div class="sessions-video-list">
                {
                  props.sessionContents.map((a, idx) => {
                    return (
                      (a.Status == sessionStatus.Completed) ?
                      // <Link to={"/course/" + props.id + "/" + idx}>
                      <div onClick={() => props.onSessionChange(idx)} style={{cursor: 'pointer'}}>
                        <div className={props.videoId == idx && `playlist_videos   ${document.body.dir == "ltr" ? "left-border-play-list" : "right-border-play-list"}`}>
                          <li class="playlistvideo">
                            <div class="lms-flex-align-center playlist-flex">
                              <div class="lms-playlist-video">
                                <div class="lms-video-image">
                                  <img src={a.ThumbnailUrl} alt="playlist-video-img" />
                                </div>
                              </div>
                              <div class="lms-playlist-content">
                                <div class="lms-playlistvideo-content">
                                  <div class="playlist-heading">{a.Title}</div>
                                </div>
                              </div>
                              <div class="lms-playicon">
                                <div class="lms-play">
                                  {
                                    a.CdnVideoUrl === null || a.CdnVideoUrl === '' ?
                                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="15.744" stroke="#043677" stroke-width="0.512" />
                                        <path d="M22.6666 11L13.5 20.1667L9.33331 16" stroke="#043677" stroke-width="3" stroke-linecap="square" stroke-linejoin="round" />
                                      </svg>
                                      :
                                      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="15.744" class="svg-stroke" stroke-width="0.512"></circle>
                                        <rect x="12" y="10" width="3" height="12" class="svg-for-menu"></rect>
                                        <rect x="17" y="10" width="3" height="12" class="svg-for-menu"></rect>
                                      </svg>
                                  }
                                </div>
                              </div>
                            </div>
                          </li>
                        </div>
                      </div> : ""
                    )
                  })
                }
              </div>
              :
              <div className="is-session-empty">
                <p>There are no videos to show in this section</p>
              </div>
          }
        </TabPanel>
      </Tabs>
    </div >
  );
}

export default (SessionsPlayList);

